.card-breadcrumbs {
  .card-content {
    padding: 0;

    .breadcrumb {
      margin: 24px 0 0;
      padding: 0;
      font-size: 12px;
      background-color: transparent;
      border-radius: 0;

      > li + li::before {
        color: $color-border;
        font-weight: lighter;
        font-size: 8px;
        font-family: mighty-roberto, serif;
        content: "\e803";
      }

      .breadcrumbs-home {
        display: none;
      }

      li {
        display: inline;

        &.active {
          a {
            @include flat-anchor($color-text, darken($color-sky, 10%));
          }
        }

        a {
          @include flat-anchor($color-sky);

          font-size: 12px;

          i {
            font-weight: lighter;
            font-size: 14px;
          }
        }
      }
    }
  }
}

.breadcrumb > .active {
  color: #4A4A4A;
}
