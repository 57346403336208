.coupon--newsletter,
.widget-newsletter {
  position: relative;
  margin-bottom: 10px;
  padding: 20px 15px 6px;
  overflow: hidden;
  text-align: center;
  background-color: $newsletter-background-color;

  @media #{$min-sm} {
    min-height: inherit;
    padding: 22px 29px 24px;
    text-align: left;
  }

  p {
    color: $color-midnight-grey;
    font-weight: 700;
    font-size: 16px;

    strong {
      font-weight: 600;
      text-transform: uppercase;
    }
  }

  img {
    border: 1px solid $color-white;
  }

  .btn {
    min-width: 185px;
    color: $color-white;
    font-weight: 700;
    font-size: 16px;
    text-transform: uppercase;
    background-color: $color-midnight-grey;

    @media #{$sm} {
      width: 100%;
      margin-left: 0;
      font-size: 16px;
    }
  }

  .form-inline {
    @media #{$sm} {
      padding: 0 20px;
    }
  }

  #privacy_policy {
    grid-area: privacy-checkbox;

    @media #{$sm} {
      margin-top: 0;
    }
  }

  .privacy_policy {
    display: grid;
    grid-template-areas:
      "privacy-text  privacy-text"
      "show-newsletter show-newsletter";
    grid-template-rows: min-content;
    min-width: 100%;
  }

  .privacy-note {
    position: relative;
    display: inline-block;
    margin-top: 12px;
    margin-bottom: 0;

    label {
      font-weight: 100;
      font-size: 12px;
    }

    a {
      text-decoration: underline;
    }

    input[type="checkbox"] {
      width: 14px;
      height: 14px;
      margin-top: -3px;
    }

    &__text {
      a {
        color: $color-midnight-grey;
      }
    }
  }

  .form-group {
    display: inline-block;
    margin-bottom: 12px;

    @media #{$sm} {
      display: block;
    }

    @media #{$lg} {
      min-width: 64%;
    }

    input {
      width: 444px;
      height: 35px;
      padding: 10px 12px 9px 20px;
      color: $color-text;
      font-weight: 400;
      font-size: 16px;
      border-color: $color-white;
      border-radius: 0;
      box-shadow: none;

      @media #{$lg} {
        width: 100%;
      }

      @media #{$sm} {
        min-width: 100%;
        text-align: center;
      }

      &:focus {
        outline: none;
        box-shadow: none;
      }
    }
  }
}

.coupon--newsletter {
  @media #{$min-sm} {
    min-height: 120px;
    padding: 22px 30px;
  }

  .form-group {
    @media #{$min-sm} {
      margin-right: 9px;
      margin-bottom: 0;
    }

    input {
      min-width: 238px;
    }
  }

  p {
    @media #{$min-sm} {
      margin-bottom: 20px;
      padding-right: 0;
      color: $color-midnight-grey;
      font-weight: 700;
      font-size: 18px;
      line-height: 20px;
    }
  }

  &__label {
    display: none;
  }
}

.widget-newsletter {
  .form-group {
    @media #{$min-sm} {
      display: inline-block;
      width: 100%;
      margin-bottom: 0;
    }

    input {
      width: 100%;

      @media #{$min-md} {
        min-width: 0;
        margin-bottom: 5px;
      }
    }
  }

  p {
    padding-right: 0;

    @media #{$min-md} {
      margin-right: 112px;
    }
  }

  .btn-cloud {
    position: relative;
    top: -1px;
    padding: 9px 16px;

    @media #{$min-sm} {
      width: 100%;
    }

    &:hover {
      color: $color-white;
      background-color: $color-midnight-grey;
    }
  }
}

.pannacotta {
  .btn-small {
    padding: 5px 16px;
  }

  .btn-cloud {
    &:hover {
      color: $color-white;
      background-color: $color-midnight-grey;
    }
  }
}

.privacy-note {
  margin: 12px 0;
  text-align: left;

  .privacy_policy {
    &--small {
      display: -webkit-box;
      max-height: 20px;
      overflow: hidden;
      font-weight: 400;
      font-size: 14px;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }

    .text-full {
      a {
        color: $color-title;
      }
    }
  }

  .show-newsletter-details {
    grid-area: show-newsletter;
    margin: 8px 0 6px;
    color: $color-midnight-grey;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    cursor: pointer;
    text-decoration-line: underline;
  }

  &__text {
    margin-left: 0;
    color: $color-midnight-grey;
    font-weight: 400;
    font-size: 14px;

    &--small {
      display: -webkit-box;
      max-height: 20px;
      overflow: hidden;
      font-weight: 400;
      font-size: 14px;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }
  }
}
