.card-coupon-filter {
  position: relative;
}

.coupon-filter {
  &__item--extended {
    &.active,
    &.active:hover {
      cursor: pointer;
    }
  }
}

.extended-filter {
  position: absolute;
  top: $extended-filter__top;
  left: $extended-filter__left;
  z-index: 4;
  display: none;
  grid-template: $extended-filter__grid-template;
  max-height: $extended-filter__max-height;
  padding: $extended-filter__padding;
  background-color: $extended-filter__bg-color;
  border: $extended-filter__border;
  border-width: $extended-filter__border-width;
  border-radius: $extended-filter__border-radius;
  box-shadow: $extended-filter__box-shadow;

  @media #{$sm} {
    grid-template: $extended-filter__grid-template--mobile;
    width: $extended-filter__width--mobile;
    max-height: $extended-filter__max-height--mobile;
    padding: $extended-filter__padding--mobile;
    background-color: $extended-filter__bg-color--mobile;
    border-radius: $extended-filter__border-radius--mobile;
  }

  &__title {
    grid-area: title;
    margin: $extended-filter-title__margin;
    color: $extended-filter-title__color;
    font-size: $extended-filter-title__font-size;
    line-height: $extended-filter-title__line-height;

    @media #{$sm} {
      margin: $extended-filter-title__margin--mobile;
    }
  }

  &__list {
    display: $extended-filter-list__display;
    grid-area: filter-list;
    grid-gap: $extended-filter-list__grid-gap;
    grid-template-columns: $extended-filter-list__grid-template-columns;
    padding: $extended-filter-list__padding;
    list-style-type: none;

    @media #{$sm} {
      grid-template-columns: $extended-filter-list__grid-template-columns--mobile;
    }
  }

  &__item {
    display: flex;
    flex-flow: row;

    input {
      position: relative;
      top: 2px;
      width: 15px;
      height: 15px;
      margin: $extended-filter-item-input__margin;
      cursor: pointer;
    }

    label {
      position: relative;
      padding: $extended-filter-item-label__padding;
      color: $extended-filter-item-label__color;
      font-weight: $extended-filter-item-label__font-weight;
      font-size: $extended-filter-item-label__font-size;
      line-height: $extended-filter-item-label__line-height;
      white-space: nowrap;
      cursor: pointer;
      user-select: none;
    }
  }

  &__button {
    grid-area: button;
    margin: $extended-filter-btn__margin;
    padding: $extended-filter-btn__padding;
    color: $extended-filter-btn__color;
    font-size: $extended-filter-btn__font-size;
    line-height: $extended-filter-btn__line-height;
    white-space: nowrap;
    text-align: $extended-filter-btn__text-align;
    background-color: $extended-filter-btn__bg-color;
    border-radius: $extended-filter-btn__border-radius;
    cursor: pointer;

    @media #{$sm} {
      margin: $extended-filter-btn__margin--mobile;
      border-radius: $extended-filter-btn__border-radius--mobile;
    }
  }

  &--opened {
    display: $extended-filter__display--opened;
  }
}
