.card-shop,
.card-static {
  &-header {
    display: grid;
    grid-template-areas: $card-shop-header__grid-template-areas;
    margin-bottom: 20px;
    text-align: center;

    @media #{$min-sm} {
      margin-bottom: 28px;
      text-align: left;
    }

    h1 {
      grid-area: title;
    }

    h2 {
      grid-area: subtitle;
      margin: 20px 0 10px;
      font-weight: bold;
      font-size: 14px;
    }

    .shop-header-logo {
      grid-area: logo;

      @media #{$min-sm} {
        float: left;
        min-height: 112px;
        margin-right: 12px;
      }

      a {
        display: inline-block;
        width: 100px;
        height: 100px;
        margin: 0 auto 12px;
        background-color: $color-white;
        border: 1px solid $color-border;

        img {
          display: inline-block;
          max-width: 100%;
          height: auto;

          @media #{$min-sm} {
            margin: 0;
          }
        }
      }
    }

    @media #{$min-sm} and #{$md} {
      grid-template-areas:
        "logo title"
        "subtitle subtitle"
        "updated updated"
        "description description"
        "climate-banner climate-banner"
        "filters filters";
      grid-template-columns: 112px 1fr;
    }

    &__description {
      grid-area: description;
    }

    &__updated {
      position: relative;
      display: inline-block;
      grid-area: updated;
      margin-bottom: 5px;
      color: lighten($color-text, 15%);
      font-size: 13px;

      &::before {
        display: inline-block;
        width: 13px;
        height: 13px;
        margin: 0 5px -2px 0;
        background-image: url("data:image/svg+xml,%3Csvg width='10' height='10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.214 0a.357.357 0 00-.357.357v1.305A4.634 4.634 0 000 5a.357.357 0 10.714 0 3.929 3.929 0 016.827-2.652l-1.582.528a.358.358 0 10.225.678l2.143-.714a.357.357 0 00.244-.34V.357A.357.357 0 008.214 0zM8.929 4.643A.357.357 0 008.572 5a3.929 3.929 0 01-6.827 2.652l1.582-.528a.358.358 0 00-.225-.678L.959 7.16a.357.357 0 00-.245.34v2.143a.357.357 0 00.715 0V8.338A4.634 4.634 0 009.286 5a.357.357 0 00-.357-.357z' fill='%23333'/%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-size: cover;
        content: "";
      }
    }

    .shop-header-title {
      @media #{$min-sm} {
        display: flex;
        flex-direction: column;
      }
    }

    .shop-description,
    .category-description,
    .card-shop-header,
    .card-static-header {
      margin-top: 20px;
      overflow: hidden;

      @media #{$min-md} {
        margin-bottom: 20px;
      }

      h2 {
        margin: 0 0 10px;
        font-weight: bold;
        font-size: 14px;
      }

      &__text {
        display: -webkit-box;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;

        &--full {
          display: block;
        }

        a {
          color: $color-text;
          text-decoration: underline;
        }
      }
    }
  }
}

.not_found,
.categories.show,
.campaigns.show,
.categories.index,
.coupons.index,
.shops.index,
.searches.index {
  .card-shop-header {
    display: block;
  }
}

.shop-description__updated {
  position: relative;
  display: inline-block;
  margin-bottom: 5px;
  color: lighten($color-text, 15%);
  font-size: 13px;

  &::before {
    display: inline-block;
    width: 13px;
    height: 13px;
    margin: 0 5px -2px 0;
    background-image: url("data:image/svg+xml,%3Csvg width='10' height='10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.214 0a.357.357 0 00-.357.357v1.305A4.634 4.634 0 000 5a.357.357 0 10.714 0 3.929 3.929 0 016.827-2.652l-1.582.528a.358.358 0 10.225.678l2.143-.714a.357.357 0 00.244-.34V.357A.357.357 0 008.214 0zM8.929 4.643A.357.357 0 008.572 5a3.929 3.929 0 01-6.827 2.652l1.582-.528a.358.358 0 00-.225-.678L.959 7.16a.357.357 0 00-.245.34v2.143a.357.357 0 00.715 0V8.338A4.634 4.634 0 009.286 5a.357.357 0 00-.357-.357z' fill='%23333'/%3E%3C/svg%3E");
    background-size: cover;
    content: "";
  }
}

.card-coupon-filter {
  grid-area: filters;
}

.multipurpose-seo {
  grid-area: seo;
}
