.pannacotta {
  &.card-text {
    .card-content {
      img {
        max-width: 100%;
      }
    }
  }
}

.pannacotta {
  &.card-text {
    position: relative;

    .card-header {
      .card-title {
        font-weight: bold;
      }
    }

    .card-content {
      padding: 25px;
      color: $color-text;
      font-weight: lighter;
      font-size: 14px;
      line-height: 30px;

      a {
        @include flat-anchor($color-sky);
      }

      br + br {
        display: none;
      }

      b,
      strong {
        font-weight: bold;
      }

      h1,
      h2,
      .h1,
      .h2 {
        font-weight: bold;
        font-size: 18px;
      }

      h3,
      .h3 {
        margin-top: 30px;
        font-weight: bold;
        font-size: 16px;
      }

      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      .h1,
      .h2,
      .h3,
      .h4,
      .h5,
      .h6 {
        color: $color-headline;
        font-weight: bold;
        line-height: 30px;

        b,
        strong {
          font-weight: bold;
        }
      }

      h4,
      h5,
      h6,
      .h4,
      .h5,
      .h6 {
        font-weight: lighter;
        font-size: 16px;
      }

      h1 + h2,
      h2 + h3,
      h3 + h4,
      h4 + h5,
      h5 + h6,
      .h1 + .h2,
      .h2 + .h3,
      .h3 + .h4,
      .h4 + .h5,
      .h5 + .h6 {
        margin-top: 0;
        color: $color-text;
        font-weight: bold;
      }

      hr {
        border-top-color: $color-cloud;
      }

      li {
        font-weight: lighter;
        font-size: 16px;
        line-height: 30px;

        @media #{$sm} {
          font-size: 14px;
          line-height: 24px;
        }
      }

      p {
        font-weight: lighter;
        font-size: 14px;
        line-height: 30px;
      }

      ul {
        padding-left: 0;
        list-style: none;

        li {
          position: relative;
          padding-left: 12px;

          &::before {
            position: absolute;
            top: 13px;
            left: 0;
            width: 5px;
            height: 5px;
            color: transparent;
            border: 1px solid #E60000;
            border-radius: 50%;
            content: "";

            @media #{$sm} {
              top: 10px;
            }
          }

          a {
            @include flat-anchor($color-sky);
          }
        }
      }
    }
  }
}
