.dropdown-list-coupon-type {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  .h1,
  .h2,
  .h3,
  .h4,
  .h5,
  .h6 {
    color: $color-title;
    font-weight: lighter;
    font-size: 20px;
  }

  .dropdown {
    &.open .btn {
      border-radius: 2px 2px 0 0;
    }

    .btn {
      width: 100%;
      padding: 25px;
      color: $color-title;
      font-weight: lighter;
      font-size: 16px;
      line-height: 25px;
      background-color: #FFF;
      border: 1px solid $color-border;
      border-radius: 2px;

      i {
        font-size: 25px;

        &.roberto-triangle-bottom {
          font-size: 16px;
        }
      }
    }

    .dropdown-menu {
      right: 0;
      display: block;
      margin: 0;
      padding: 0;
      border: 1px solid $color-border;
      border-top: none;
      border-radius: 0 0 2px 2px;
      box-shadow: none;
      transform: scaleY(0);
      transform-origin: top;
      opacity: 0.97;
      filter: alpha(opacity=97);
      transition: transform ease-in-out 0.2s;

      &--opened {
        transform: scaleX(1);
      }

      > li {
        &:first-child a {
          border-top: none;
        }

        &:last-child a {
          border-bottom: none;
        }

        a {
          padding: 25px;
          color: $color-title;
          font-weight: lighter;
          font-size: 16px;
          text-align: center;
          border-top: 1px solid transparent;
          border-bottom: 1px solid transparent;
          cursor: pointer;
          transition: background-color 0.3s ease, border-color 0.3s ease;

          &:active,
          &:focus,
          &:hover {
            background-color: $color-header-bg;
            border-color: $color-border;
            outline: none;
          }
        }
      }
    }
  }
}
