#my-modal {
  &.modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    margin: 0;
    pointer-events: none;

    &--hidden {
      display: none;
    }
  }

  .newsletter-modal {
    display: flex;
    flex-direction: column;
    grid-column: 1/-1;
    align-items: center;
    justify-content: space-around;

    p {
      color: $color-midnight-grey;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;

      span {
        color: $color-midnight-grey;
        font-weight: 700;
      }
    }
  }

  .modal {
    &-body {
      grid-column: 1/7;
      padding: 30px 15px;
    }

    &__dialog {
      position: relative;
      width: 90%;
      max-height: 90%;
      margin: 90px auto;
      color: $color-text;
      font-weight: lighter;
      font-size: 14px;
      background-color: $color-header-bg;
      border-radius: 0;
      box-shadow: none;
      pointer-events: all;

      @media #{$min-sm} {
        width: 650px;
        overflow-y: auto;
      }

      @media #{$sm} {
        overflow-y: scroll;
      }
    }

    &__content {
      display: grid;
      grid-template-areas:
        "disclaimer disclaimer disclaimer disclaimer disclaimer disclaimer"
        ". modal-logo modal-title modal-title modal-title ."
        ". modal-logo modal-expired modal-expired . ."
        ". code code code code ."
        ". message message message message ."
        ". modal-desc modal-desc modal-desc modal-desc ."
        ". modal-toggle modal-toggle . . ."
        "vote vote vote vote vote vote";
      grid-template-columns: repeat(6, 1fr);

      @media #{$sm} {
        grid-template-areas:
          "disclaimer disclaimer"
          "modal-logo modal-logo"
          "modal-expired modal-expired"
          "modal-title modal-title"
          "code code"
          "message message"
          "modal-desc modal-desc"
          "vote vote";
        grid-template-columns: repeat(2, 1fr);
        margin: 0 auto;
        padding: 0 15px;
      }

      .coupon {
        &__description {
          grid-area: modal-desc;
          margin: 10px auto 0;
          padding: 0;
          color: #404040;
          text-align: left;

          @media #{$sm} {
            display: none;
          }

          .key {
            font-weight: 700;
          }

          &--small {
            display: -webkit-box;
            max-height: 36px;
            -webkit-line-clamp: 2;
            overflow: hidden;

            @media #{$sm} {
              display: none;
            }
          }

          ul {
            padding: 0;
          }
        }

        &__toggle {
          grid-area: modal-toggle;
          margin-bottom: 20px;
          color: #404040;
          cursor: pointer;

          @media #{$sm} {
            display: none;
          }

          i,
          span {
            pointer-events: none;
          }

          span {
            text-decoration: none;
          }
        }

        &__title {
          grid-area: modal-title;
          margin: 25px 0 0;
          padding: 0 0 0 10px;
          color: #262626;
          font-weight: 600;
          font-size: 18px;
          line-height: 22px;

          @media #{$sm} {
            grid-area: modal-title;
            margin: 0 auto 30px;
            padding: 0;
          }
        }

        &__table {
          font-size: 12px;

          &--gift-card {
            grid-template-columns: auto;

            ul {
              padding-left: 20px;
              list-style-type: initial;
            }
          }
        }
      }

      &.newsletter-empty-shop {
        .input-lg {
          border-radius: 0;
        }

        .checkbox {
          input {
            top: 0;
          }

          label {
            font-size: 11px;
          }
        }

        input.btn-lg {
          margin-bottom: 11px;
        }

        .text-muted small {
          display: inline-block;
          font-size: 71%;
          line-height: 15px;
        }
      }

      .thumb {
        display: block;
        width: 120px;
        height: 120px;
        margin: -240px auto 0;
        background: #FFF;
        border: 5px solid $color-ashes;
      }

      h5 {
        color: $color-text;
        font-weight: bold;
        font-size: 16px;
        line-height: 20px;
      }

      .list-steps {
        display: flex;
        align-items: center;
        justify-content: space-around;
        margin-bottom: 25px;

        > li {
          display: none;
          margin: 0 5px;
          padding: 5px;
          color: #FFF;
          border-radius: 5px;

          &:not([class*="bg-"]) {
            background: $color-inactive;
          }

          &.bg-freedom {
            background-color: $color-midnight-grey;
          }

          &:first-child {
            display: flex;
            background-color: $color-midnight-grey;
          }

          @media (min-width: 768px) {
            display: flex;
          }

          &.with-icon {
            align-items: center;
            justify-content: center;
            width: 100px;
            height: 100px;
            font-size: 40px;
            line-height: 100px;
            vertical-align: -10px;
            border-radius: 50px;

            i {
              line-height: 0;
            }

            &.with-icon-facebook,
            &.with-icon-twitter,
            &.with-icon-google {
              display: inline-block !important;

              a {
                display: inline-block;
                width: 100%;
                height: 100%;
                color: #FFF;
              }

              i {
                position: relative;
                width: 40px;
                height: 40px;

                svg {
                  width: inherit;
                  height: inherit;
                }
              }
            }

            &.with-icon-facebook {
              background: #3B5998;

              &:hover {
                background: #4273C8;
              }
            }

            &.with-icon-twitter {
              background: #55ACEE;

              &:hover {
                background: #32BBF5;
              }
            }

            &.with-icon-google {
              background: #D34836;

              &:hover {
                background: #F75B44;
              }
            }
          }
        }
      }

      p a {
        @include flat-anchor($color-sky);

        display: inline-block;
        margin: 10px 0 5px;
        font-size: 20px;
      }

      .privacy-note {
        small,
        label {
          margin-top: 12px;
          font-size: 12px;
          cursor: pointer;
        }

        input {
          min-width: 0;
        }

        a {
          font-size: 12px;
        }
      }

      .coupon-info-box {
        width: 65%;
        margin: 0 auto 20px;

        table {
          margin-top: 10px;
        }

        .coupon-description__text {
          &--small {
            table {
              margin-top: 18px;
            }
          }
        }

        .coupon-time-box {
          margin-bottom: 10px;
          font-weight: normal;
          font-size: 11px;
          text-align: left;
        }

        .coupon-header {
          width: 100%;

          img {
            max-width: 150px;
            margin: 0 auto;
          }
        }

        .coupon-title-box {
          width: 100%;
          margin-top: 5px;
          text-align: center;

          .coupon-title {
            margin-top: 0;
            color: #262626;
            font-weight: 600;
            font-size: 18px;
            line-height: 22px;
            text-align: left;
          }
        }
      }

      .coupon-info {
        margin-bottom: 10px;
        margin-left: 0;
        font-size: 12px;
      }

      .pannacotta.card-coupons-list {
        width: 80%;
        margin: 0 auto;

        .card-content {
          > ul > li {
            margin-bottom: 30px;
            padding: 0;
            background-color: transparent;
            border: none;
            box-shadow: none;
          }

          .coupon-logo {
            width: auto;
            height: auto;
            padding: 0;
            border: none;

            img {
              margin: 0 auto;
              background-color: transparent;
            }
          }

          .coupon-title {
            margin-bottom: 0;
          }
        }
      }

      .input-button-wrapper {
        width: 80%;
        margin: 30px auto 10px;
        padding: 10px;
        background-color: #FFF;
        border-width: 2px;

        input {
          color: $color-text;
          font-weight: lighter;
          font-size: 14px;
          border-color: transparent;
          box-shadow: none;
        }

        .btn {
          display: block;
          width: 100%;
          padding: 10px 24px;
        }
      }

      .esdo-form {
        &__wrp {
          width: 70%;
          margin: 0 auto;

          @media #{$xs} {
            width: 90%;
          }
        }

        &__email {
          margin-bottom: 10px;
          color: $esdo-color;
          font-weight: 400;
          font-size: 14px;

          input {
            width: 100%;
            height: 35px;
            padding: 0 10px;
            border: 0.5px solid #323232;
            border-radius: 2px;

            ::placeholder {
              color: $color-bg-hero;
              font-weight: 600;
            }
          }
        }

        &__btn {
          display: block;
          margin-top: 20px;
          color: $color-white;
          font-weight: 500;
          text-transform: uppercase;
          background: $esdo-color;

          &.disabled {
            cursor: default;
            opacity: 0.7;
            pointer-events: none;
          }
        }

        &__message {
          display: block;
          color: $color-cherry;
          font-weight: 400;
          font-size: 14px;
          text-align: center;

          &-incorrect,
          &-empty {
            display: none;

            &.show {
              display: block;
            }
          }
        }

        &__description {
          margin: 10px 0 20px;
          color: $color-text-lighter;
          font-weight: 400;
          font-size: 14px;
          text-align: center;
        }

        &__checkbox {
          position: relative;
          margin-top: 20px;
          color: $color-text-lighter;
          font-weight: 400;
          font-size: 14px;

          &-input {
            position: absolute;
            opacity: 0;

            & + label {
              position: relative;
              display: inline-block;
              padding: 0;
              padding-left: 20px;
              font-weight: 400;
              cursor: pointer;
            }

            & + label::before {
              position: absolute;
              left: 0;
              width: 14px;
              height: 14px;
              margin-top: 3px;
              vertical-align: text-top;
              background: white;
              border: 1px solid #132D56;
              border-radius: 2px;
              content: "";
            }

            &:focus + label::before {
              box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
            }

            &:checked + label::after {
              position: absolute;
              top: 9px;
              left: 2px;
              width: 2px;
              height: 2px;
              background: #132D56;
              box-shadow:
                2px 0 0 #132D56,
                4px 0 0 #132D56,
                4px -2px 0 #132D56,
                4px -4px 0 #132D56,
                4px -6px 0 #132D56,
                4px -8px 0 #132D56;
              transform: rotate(45deg);
              content: "";
            }
          }
        }
      }

      .sent-code-message {
        display: none;
        color: $color-title;
        font-weight: 400;
        font-size: 14px;
        text-align: center;

        &.show {
          display: block;
        }
      }

      .esdo-text-remember {
        margin: 30px 0 10px;
        color: $color-text-lighter;
        font-weight: normal;
        font-size: 12px;
        line-height: 15px;
        letter-spacing: 0.5em;
        text-align: center;
        text-transform: uppercase;
        opacity: 0.4;
      }

      .modal-footer {
        padding: 10px 25px;
        background-color: $color-card-bg;
        border-top: 1px solid $color-border;

        .list-voting {
          margin: 0;

          li:first-of-type {
            display: block;
          }

          .btn {
            padding: 0 6px !important;
            color: $color-border;
            font-size: 30px;
            border: 1px solid $color-border;
            border-radius: 22px;
            transition: background-color 0.3s ease, border-color 0.3s ease;

            &:focus,
            &:hover,
            &.active {
              color: #FFF;
            }

            &.add-positive-vote {
              &:focus,
              &:hover,
              &.active {
                background-color: $color-freedom;
                border-color: $color-freedom;
              }
            }

            &.add-negative-vote {
              &:focus,
              &:hover,
              &.active {
                background-color: $color-ashes;
                border-color: $color-ashes;
              }
            }

            .roberto {
              vertical-align: -4px;
            }
          }
        }
      }

      &__vote-coupon {
        padding: 10px 25px 25px;
        background-color: $color-header-bg;
        border: none;

        .list-voting {
          li:first-of-type {
            margin-bottom: 25px;
            font-weight: 700;
            font-size: 16px;
          }

          .btn {
            padding: 12px 36px !important;
            font-size: 100px;
            border-radius: 50%;

            @media (max-width: 767px) {
              padding: 12px 24px !important;
              font-size: 50px;
            }
          }
        }
      }
    }

    &__close {
      position: absolute;
      right: 20px;
      z-index: 1;

      &-btn {
        width: 28px;
        height: 28px;
        margin-top: 10px;
        padding: 6px;
        color: $color-ashes;
        font-size: 14px;
        line-height: 14px;
        background-color: #FFF;
        border-radius: 13px;
        opacity: 1;
        transition: color 0.3s ease;

        .roberto-remove {
          top: 1px;
        }

        &:focus,
        &:hover {
          color: $color-ready;
        }
      }
    }

    &__expired-disclaimer {
      display: flex;
      grid-area: disclaimer;
      align-items: center;
      justify-content: center;
      height: 52px;
      margin-bottom: 10px;
      padding: 3px;
      font-weight: 600;
      font-size: 16px;
      font-family: $font-family-base;
      line-height: 22px;
      background-color: $disclaimer-bg;
      border-bottom: 1px solid $color-body-bg;

      @media #{$sm} {
        margin: 0 -15px;
      }

      @media #{$xs} {
        height: 70px;
      }

      &::before {
        width: 33px;
        height: 22px;
        margin: 0 13px 0 5px;
        background-image: url("data:image/svg+xml,%3Csvg width='22' height='22' viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11 0.0994873C4.97974 0.0994873 0.0994873 4.97974 0.0994873 11C0.0994873 17.0202 4.97974 21.9005 11 21.9005C17.0202 21.9005 21.9005 17.0202 21.9005 11C21.9005 4.97974 17.0202 0.0994873 11 0.0994873ZM10.0677 4.99999H11.8827V6.93049H10.0677V4.99999ZM13.0715 16.7165H11.075C10.2995 16.7165 9.96874 16.3865 9.96874 15.5945V10.445C9.96874 10.1975 9.83674 10.0827 9.60574 10.0827H8.94574V8.29999H10.943C11.7192 8.29999 12.0485 8.64649 12.0485 9.42199V14.5872C12.0485 14.8197 12.1805 14.9502 12.4115 14.9502H13.0715V16.7165Z' fill='%23FFC12E'/%3E%3C/svg%3E%0A");
        background-repeat: no-repeat;
        content: "";

        @media #{$xs} {
          width: 46px;
          margin: 0 10px 0 5px;
        }
      }
    }

    &__logo {
      grid-area: modal-logo;
      margin: 25px 0;
      border-top-width: 5px;
      border-bottom: none;

      img {
        margin: 0 auto;
      }
    }

    &__expired {
      grid-area: modal-expired;
      margin-bottom: 10px;
      padding-left: 10px;

      @media #{$sm} {
        text-align: center;
      }
    }

    &__code {
      &-wrap {
        display: grid;
        grid-area: code;
        grid-template-columns: repeat(4, 1fr);
        width: 100%;
        padding: 10px 12px;
        background-color: #FFF;
        border-width: 2px;

        @media #{$sm} {
          padding: 17px 12px;
        }

        &--no-code {
          display: block;
          grid-area: code;
          color: $color-text;
          font-weight: bold;
          font-size: 20px;
        }

        .zclip {
          grid-column: 4/5;
          padding: 9px 24px;

          @media #{$sm} {
            grid-column: 1/5;
            margin-top: 6px;
          }
        }
      }

      &-area {
        position: relative;
        grid-column: 1/4;
        margin: auto;
        color: $color-text;
        font-weight: bold;
        font-size: 20px;
        border-width: 2px;
        border-radius: 2px;

        @media #{$sm} {
          grid-column: 1/7;
        }

        [data-coupon-code-blur] {
          filter: blur(3px);

          &::after {
            position: absolute;
            left: 0;
            display: inline-block;
            width: 100%;
            height: 100%;
            content: "";
          }
        }
      }
    }

    &__message,
    &__qr-wrap {
      grid-area: message;

      .btn {
        display: inline-block;
        margin-top: 16px;
        color: $color-white;
        font-size: 16px;
        text-decoration: none;
        border-bottom: 0;

        &:hover {
          text-decoration: none;
          border-bottom: 0;
        }
      }

      &-text {
        display: block;
      }

      .btn {
        display: inline-block;
        margin-top: 16px;
        color: $color-white;
        font-size: 16px;
        text-decoration: none;
        border-bottom: 0;

        &:hover {
          text-decoration: none;
          border-bottom: 0;
        }
      }
    }

    &__vote {
      grid-area: vote;
      margin: 0;
      padding: 10px 25px;
      background-color: $color-card-bg;
      border-top: 1px solid $color-border;

      @media #{$sm} {
        margin: 0 -15px;
      }

      .btn {
        width: 44px;
        height: 44px;
        padding: 0 6px;
        color: $color-border;
        font-size: 0;
        border: 1px solid $color-border;
        border-radius: 22px;
        transition: background-color 0.3s ease, border-color 0.3s ease;

        &:focus,
        &:hover,
        &.active {
          color: #FFF;
        }

        &.add-positive-vote {
          &:focus,
          &:hover,
          &.active {
            background-color: $color-freedom;
            border-color: $color-freedom;
          }
        }

        &.add-negative-vote {
          &:focus,
          &:hover,
          &.active {
            background-color: $color-ashes;
            border-color: $color-ashes;
          }
        }

        .roberto {
          font-size: 30px;
          vertical-align: -8px;
        }
      }
    }

    &-gift {
      &__body {
        display: grid;
        grid-column: 1/-1;
        grid-template-areas:
          ". . logo logo . ."
          ". title title title title ."
          ". desc desc desc desc ."
          ". . expired-text expired-text . ."
          ". form form form form ."
          ". . completed-img completed-img . ."
          ". completed-text completed-text completed-text completed-text ."
          "info-header info-header . . . ."
          "info-box-1 info-box-1 info-box-2 info-box-2 info-box-3 info-box-3"
          "terms terms terms terms terms terms"
          "toggle toggle . . . .";
        grid-template-columns: repeat(6, 1fr);
        padding: 0 30px;

        @media #{$sm} {
          grid-template-areas:
            "logo logo"
            "title title"
            "desc desc"
            "expired-text expired-text"
            "form form"
            "completed-img completed-img"
            "completed-text completed-text"
            "info-header info-header"
            "info-box-1 info-box-1"
            "info-box-2 info-box-2"
            "info-box-3 info-box-3"
            "toggle ."
            "terms terms";
          grid-template-columns: repeat(2, 1fr);
          padding: 0 12px;
        }
      }

      &__logo {
        grid-area: logo;
        margin: 0 auto;

        img {
          max-width: 80px;
        }
      }

      &__title {
        grid-area: title;
        margin-top: 10px;
        font-size: 20px;
        line-height: 24px;
        text-align: center;

        @media #{$sm} {
          font-size: 16px;
          line-height: 19px;
        }
      }

      &__expired-text {
        grid-area: expired-text;
        margin-top: 22px;
        font-weight: 500;
        font-size: 18px;
        line-height: 20px;
        text-align: center;
      }

      &__form {
        display: grid;
        grid-area: form;
        margin-top: 20px;

        &--hidden {
          display: none;
        }

        &-input {
          height: 44px;
          padding: 12px;
          border: 1px solid #D8D8D8;
        }

        &-submit {
          margin: 20px auto 0;
          padding: 20px 80px;
          color: #FFF;
          font-weight: 400;
          font-size: 16px;
          line-height: 19px;
          text-transform: capitalize;
          background-color: #EC1A2E;
        }
      }

      &__completed {
        &-img {
          grid-area: completed-img;
          margin: 30px auto 0;
          background-repeat: no-repeat;

          &--hidden {
            display: none;
          }
        }

        &-text {
          grid-area: completed-text;
          margin-top: 22px;
          font-weight: 500;
          font-size: 18px;
          line-height: 20px;
          text-align: center;

          &--hidden {
            display: none;
          }
        }
      }

      &__privacy-policy {
        margin: 16px 0 0;

        &-text {
          font-size: 12px;
          line-height: 14px;
        }
      }

      &__text-completed {
        &--hidden {
          display: none;
        }
      }

      &__img-completed {
        &--hidden {
          display: none;
        }
      }

      &__info {
        &-header {
          grid-area: info-header;
          margin: 24px 0 0;
          font-weight: 400;
          font-size: 16px;
          line-height: 18px;
        }

        &-title {
          margin-top: 5px;
          font-weight: 700;
          font-size: 14px;
          line-height: 16px;
          text-align: center;
        }

        &-text {
          margin-top: 12px;
          font-weight: 400;
          font-size: 11px;
          line-height: 18px;
          text-align: center;
        }

        &-img {
          margin: 0 auto;
          background-repeat: no-repeat;
        }

        &-box {
          margin-top: 20px;

          &--1 {
            grid-area: info-box-1;
            margin-right: 14px;
          }

          &--2 {
            grid-area: info-box-2;
            margin-right: 14px;
            margin-left: 14px;
          }

          &--3 {
            grid-area: info-box-3;
            margin-left: 14px;
          }
        }
      }

      &__toggle {
        grid-area: toggle;
        cursor: pointer;

        i,
        span {
          pointer-events: none;
        }

        span {
          font-weight: 400;
          font-size: 11px;
          line-height: 14px;
          text-transform: capitalize;
        }
      }

      &__terms {
        display: inline-block;
        grid-area: terms;
        margin-top: 12px;
        margin-bottom: 10px;
        padding: 0 40px;
        overflow: hidden;
        color: #000;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;

        ul {
          padding: 0 0 0 26px;
          list-style-type: disc;
        }

        &--small {
          display: -webkit-box;
          text-overflow: ellipsis;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
        }
      }
    }
  }

  .the-code {
    grid-area: code;
    margin: 10px auto;
  }
}

@keyframes cursor-blink {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
