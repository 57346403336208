.pannacotta {
  &.popular-shops {
    ul {
      margin-bottom: 0;

      li {
        margin-bottom: 0;

        a {
          display: inline-block;
          width: 100%;
          margin-bottom: 5px;
          line-height: 40px;

          span {
            display: inline-block;
            float: left;
            width: 40px;
            height: 40px;
            margin-right: 10px;
            overflow: hidden;
            line-height: 40px;
            background-color: #FFF;
            border-radius: 50px;

            img {
              position: relative;
              top: -1px;
              width: 40px;
              height: 40px;
            }
          }
        }
      }
    }
  }
}
