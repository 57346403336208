// shared variables
$earthmark-shops__leaf: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='48' height='59' fill='none'%3E%3Cpath fill='url(%23a)' d='M.147 56.18C-3.053 2.01 47.107 0 47.107 0s2.93 27.06-8.65 43.58c0 0-9.71 17.21-35.97 14.28 0 0 9.79-14.39 26.06-36.36 0 0-17.94 13.05-28.4 34.68Z'/%3E%3Cdefs%3E%3ClinearGradient id='a' x1='4.193' x2='45.875' y1='59.086' y2='.93' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%2324989D'/%3E%3Cstop offset='1' stop-color='%2318C17D'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E") !default;
$earthmark-shops__leaf--gray: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='48' height='59' fill='none'%3E%3Cpath fill='%23AFCBC0' d='M.147 56.18C-3.053 2.01 47.107 0 47.107 0s2.93 27.06-8.65 43.58c0 0-9.71 17.21-35.97 14.28 0 0 9.79-14.39 26.06-36.36 0 0-17.94 13.05-28.4 34.68Z'/%3E%3C/svg%3E");
$earthmark-shops-active-color: #333 !default;
$earthmark-shops-inactive-color: #D1D1D1 !default;
$earthmark-shops__scrollbar-tracker: $earthmark-shops-active-color !default;
$earthmark-shops__scrollbar-tracker-transition: all 0.4s ease-in-out !default;
$earthmark-shops__scrollbar-indicator: $earthmark-shops-inactive-color !default;
$earthmark-shops__scrollbar-color: $earthmark-shops__scrollbar-tracker $earthmark-shops__scrollbar-indicator !default;
$earthmark-shops__scrollbar-height: 6px !default;
$earthmark-shops__scrollbar-border-radius: 6px !default;

// element variables
$earthmark-shops-content__box-shadow: 4px 4px 12px rgba(0, 0, 0, 0.1) !default;
$earthmark-shops-content__margin--mobile: 0 -15px !default;
$earthmark-shops-shops__padding: 30px !default;
$earthmark-shops-shops__padding--mobile: 20px 17px 0 !default;
$earthmark-shops-shops__background-color: #F8F9FA !default;
$earthmark-shops-list__gap: 25px !default;
$earthmark-shops-list__gap--mobile: 20px !default;
$earthmark-shops-list__height--mobile: 286px !default;
$earthmark-shops-list-arrow__top: 60% !default;
$earthmark-shops-list-arrow__padding: 32px 13px !default;
$earthmark-shops-list-arrow__background-color: #FFF !default;
$earthmark-shops-list-arrow-left__border-radius: 0 12px 12px 0 !default;
$earthmark-shops-list-arrow-left__box-shadow: -2px 2px 4px 0 rgba(0, 0, 0, 0.1) !default;
$earthmark-shops-list-arrow-left__box-shadow--hover: -2px 2px 4px 0 rgba(0, 0, 0, 0.3) !default;
$earthmark-shops-list-arrow-right__border-radius: 12px 0 0 12px !default;
$earthmark-shops-list-arrow-right__box-shadow: -2px 2px 4px rgba(0, 0, 0, 0.1) !default;
$earthmark-shops-list-arrow-right__box-shadow--hover: 2px 2px 4px 0 rgba(0, 0, 0, 0.3) !default;
$earthmark-shops-item__min-width: 145px !default;
$earthmark-shops-item__min-width--mobile: 100px !default;
$earthmark-shops-item__height: 172px !default;
$earthmark-shops-item__height--mobile: 120px !default;
$earthmark-shops-item__background-color: #FFF !default;
$earthmark-shops-item__border-radius: 0 0 6px 6px !default;
$earthmark-shops-item-img__width--mobile: 60px !default;
$earthmark-shops-score__width: 100% !default;
$earthmark-shops-score__height: 25px !default;
$earthmark-shops-score__padding: 4px 0 !default;
$earthmark-shops-score__border-top: 1px solid #E1E1E1 !default;
$earthmark-shops-score__height--mobile: 20px !default;
$earthmark-shops-score__padding--mobile: 3px 0 5px !default;
$earthmark-shops-navigation__padding: 0 15px 30px !default;
$earthmark-shops-navigation__background-color: #F8F9FA !default;
$earthmark-shops-navigation__padding--mobile: 0 15px 10px !default;
$earthmark-shops-navigation-arrow__margin: 0 27px 0 15px !default;
$earthmark-shops-navigation-arrow__margin--mobile: 15px 27px 0 15px !default;
$earthmark-shops-navigation-arrow--left__margin--mobile: 15px 40px 0 auto !default;
$earthmark-shops-navigation-arrow--right__margin--mobile: 15px auto 0 40px !default;
$earthmark-shops-sale__grid-template-areas:
  "title cta"
  "text cta" !default;
$earthmark-shops-sale__grid-template-columns: 1fr 1fr !default;
$earthmark-shops-sale__margin: 0 !default;
$earthmark-shops-sale__padding: 26px 30px 25px !default;
$earthmark-shops-sale__grid-template-areas--mobile:
  "title"
  "text"
  "cta" !default;
$earthmark-shops-sale__grid-template-columns--mobile: 1fr !default;
$earthmark-shops-sale__padding--mobile: 20px 16px 30px !default;
$earthmark-shops-sale-title__margin: 4px 0 20px !default;
$earthmark-shops-sale-title__font-weight: 700 !default;
$earthmark-shops-sale-title__font-size: 22px !default;
$earthmark-shops-sale-title__line-height: 26px !default;
$earthmark-shops-sale-text__font-weight: 400 !default;
$earthmark-shops-sale-text__font-size: 16px !default;
$earthmark-shops-sale-text__line-height: 24px !default;
$earthmark-shops-sale-text__margin--mobile: 0 0 30px !default;
$earthmark-shops-sale-cta__align-self: center !default;
$earthmark-shops-sale-cta__justify-self: flex-end !default;
$earthmark-shops-sale-cta__width: fit-content !default;
$earthmark-shops-sale-cta__height: fit-content !default;
$earthmark-shops-sale-cta__margin: 0 35px 0 0 !default;
$earthmark-shops-sale-cta__padding: 18px 29px 16px !default;
$earthmark-shops-sale-cta__color: #FFF !default;
$earthmark-shops-sale-cta__font-weight: 600 !default;
$earthmark-shops-sale-cta__font-size: 20px !default;
$earthmark-shops-sale-cta__line-height: 24px !default;
$earthmark-shops-sale-cta__background-color: #54AE8B !default;
$earthmark-shops-sale-cta__border-radius: 6px !default;
$earthmark-shops-sale-cta__justify-self--mobile: start !default;
$earthmark-shops-sale-cta__margin--mobile: initial !default;
$earthmark-shops-know-more__grid-template-areas:
  "toggle visual-demo"
  "info visual-demo" !default;
$earthmark-shops-know-more__grid-template-columns: 2fr 1fr !default;
$earthmark-shops-know-more__max-height: 60px !default;
$earthmark-shops-know-more__padding: 14px 30px 12px !default;
$earthmark-shops-know-more__background-color: #F5F7F6 !default;
$earthmark-shops-know-more__transition: max-height 0.3s ease-in-out !default;
$earthmark-shops-know-more__grid-template-areas--mobile:
  "toggle"
  "info"
  "visual-demo" !default;
$earthmark-shops-know-more__grid-template-columns--mobile: 1fr !default;
$earthmark-shops-know-more__padding--mobile: 17px 12px 12px 16px !default;
$earthmark-shops-know-more--opened__max-height: 190px !default;
$earthmark-shops-know-more--opened__max-height--mobile: 410px !default;
$earthmark-shops-toggle__height: fit-content !default;
$earthmark-shops-toggle__margin: 4px 0 0 !default;
$earthmark-shops-toggle__font-weight: 600 !default;
$earthmark-shops-toggle__font-size: 18px !default;
$earthmark-shops-toggle__line-height: 24px !default;
$earthmark-shops-toggle__margin--mobile: 4px 0 20px !default;
$earthmark-shops-info__font-weight: 400 !default;
$earthmark-shops-info__font-size: 16px !default;
$earthmark-shops-info__line-height: 23px !default;
$earthmark-shops-visual-demo__grid-template-areas:
  "scoring-average scoring-high"
  "leafs-average leafs-high"
  "arrow arrow"
  "impact-average impact-high" !default;
$earthmark-shops-visual-demo__grid-template-columns: 1fr 1fr !default;
$earthmark-shops-visual-demo__max-width: 360px !default;
$earthmark-shops-visual-demo__column-gap: 50px !default;
$earthmark-shops-scoring__width: min-content !default;
$earthmark-shops-scoring__margin: 10px 0 0 !default;
$earthmark-shops-scoring__font-weight: 700 !default;
$earthmark-shops-scoring__font-size: 18px !default;
$earthmark-shops-scoring__line-height: 20px !default;
$earthmark-shops-scoring--average__color: #94C0C7 !default;
$earthmark-shops-scoring--high__color: #3C8691 !default;
$earthmark-shops-double-sided-arrow__width: calc(100% - 20px) !default;
$earthmark-shops-double-sided-arrow__height: 4px !default;
$double-side-arrow__color--left: #CCBAB0 !default;
$double-side-arrow__color--middle: #ADCBD7 !default;
$double-side-arrow__color--right: #3C8691 !default;
$earthmark-shops-double-sided-arrow__background-image:
  linear-gradient(
    90deg,
    $double-side-arrow__color--left,
    $double-side-arrow__color--middle,
    $double-side-arrow__color--right
  ) !default;
$earthmark-shops-leafs__margin: 10px 0 6px !default;
$earthmark-shops-leafs__width: 17px !default;
$earthmark-shops-leafs__count-green-element: 3 !default;
$earthmark-shops-impact__max-width: 70% !default;
$earthmark-shops-impact__margin: 20px 0 0 !default;
$earthmark-shops-impact__color: #79857F !default;

.earthmark-shops {
  $this: &;

  &__content {
    box-shadow: $earthmark-shops-content__box-shadow;

    @media #{$md} {
      margin: $earthmark-shops-content__margin--mobile;
    }
  }

  &__col {
    position: relative;
    padding: 0 15px;
  }

  &__shops {
    position: relative;
    padding: $earthmark-shops-shops__padding;
    background-color: $earthmark-shops-shops__background-color;

    @media #{$md} {
      padding: $earthmark-shops-shops__padding--mobile;
    }
  }

  &__list {
    display: flex;
    gap: $earthmark-shops-list__gap;
    align-content: flex-start;
    overflow-x: scroll;
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;
    scrollbar-color: $earthmark-shops__scrollbar-color;
    scrollbar-width: none;

    @media #{$md} {
      flex-flow: column wrap;
      gap: $earthmark-shops-list__gap--mobile;
      height: $earthmark-shops-list__height--mobile;
      padding-bottom: $earthmark-shops__scrollbar-height;
      overflow-y: hidden;
    }

    &::-webkit-scrollbar {
      height: $earthmark-shops__scrollbar-height;
      background-color: $earthmark-shops__scrollbar-indicator;
      border-radius: 6px;

      @media #{$min-md} {
        display: none;
      }
    }

    &::-webkit-scrollbar-thumb {
      height: $earthmark-shops__scrollbar-height;
      background-color: $earthmark-shops__scrollbar-tracker;
      border-radius: $earthmark-shops__scrollbar-border-radius;
    }

    &-arrow {
      position: absolute;
      top: $earthmark-shops-list-arrow__top;
      padding: $earthmark-shops-list-arrow__padding;
      background-color: $earthmark-shops-list-arrow__background-color;
      transform: translateY(-50%);
      cursor: pointer;

      &--left {
        left: 0;
        border-radius: $earthmark-shops-list-arrow-left__border-radius;
        box-shadow: $earthmark-shops-list-arrow-left__box-shadow;

        &:hover {
          box-shadow: $earthmark-shops-list-arrow-left__box-shadow--hover;
        }
      }

      &--right {
        right: 0;
        border-radius: $earthmark-shops-list-arrow-right__border-radius;
        box-shadow: $earthmark-shops-list-arrow-right__box-shadow;

        &:hover {
          box-shadow: $earthmark-shops-list-arrow-right__box-shadow--hover;
        }
      }
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    min-width: $earthmark-shops-item__min-width;
    height: $earthmark-shops-item__height;
    overflow: hidden;
    background-color: $earthmark-shops-item__background-color;
    border-radius: $earthmark-shops-item__border-radius;

    @media #{$md} {
      min-width: $earthmark-shops-item__min-width--mobile;
      height: $earthmark-shops-item__height--mobile;
    }

    &:hover {
      text-decoration: none;
    }

    &-img {
      margin: auto;

      @media #{$md} {
        width: $earthmark-shops-item-img__width--mobile;
        height: auto;
        aspect-ratio: 1/1;
      }
    }
  }

  &__score {
    display: flex;
    flex-direction: row-reverse;
    gap: 3px;
    justify-content: center;
    width: $earthmark-shops-score__width;
    height: $earthmark-shops-score__height;
    padding: $earthmark-shops-score__padding;
    text-align: center;
    border-top: $earthmark-shops-score__border-top;

    @media #{$md} {
      height: $earthmark-shops-score__height--mobile;
      padding: $earthmark-shops-score__padding--mobile;
    }
  }

  &__leaf {
    display: inline-block;
    height: 100%;
    aspect-ratio: 14 / 17;
    background: center / contain no-repeat;
    background-image: $earthmark-shops__leaf--gray;

    &--active {
      background-image: $earthmark-shops__leaf;

      & ~ span {
        background-image: $earthmark-shops__leaf;
      }
    }
  }

  &__navigation {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: $earthmark-shops-navigation__padding;
    background-color: $earthmark-shops-navigation__background-color;

    @media #{$md} {
      display: grid;
      grid-template-areas:
        "scroll-bar scroll-bar"
        "arrow-left arrow-right";
      padding: $earthmark-shops-navigation__padding--mobile;
    }

    &-arrow {
      display: inline-block;
      width: min-content;
      margin: $earthmark-shops-navigation-arrow__margin;
      pointer-events: none;

      @media #{$md} {
        margin: $earthmark-shops-navigation-arrow__margin--mobile;
      }

      svg {
        width: 19px;

        @media #{$md} {
          width: 15px;
        }

        path {
          stroke: $earthmark-shops-inactive-color;
        }
      }

      &--left {
        grid-area: arrow-left;

        @media #{$md} {
          margin: $earthmark-shops-navigation-arrow--left__margin--mobile;
        }
      }

      &--right {
        grid-area: arrow-right;

        @media #{$md} {
          margin: $earthmark-shops-navigation-arrow--right__margin--mobile;
        }
      }

      &.active {
        cursor: pointer;
        pointer-events: auto;

        svg {
          path {
            stroke: $earthmark-shops-active-color;
          }
        }
      }
    }

    &-indicator {
      position: relative;
      grid-area: scroll-bar;
      width: 99%;
      height: $earthmark-shops__scrollbar-height;
      margin: 0;
      padding: 0;
      overflow: hidden;
      line-height: 0;
      list-style-type: none;
      background-color: $earthmark-shops__scrollbar-indicator;
      border-radius: $earthmark-shops__scrollbar-border-radius;

      @media #{$md} {
        display: none;
      }
    }

    &-tracker {
      position: absolute;
      display: inline-block;
      height: $earthmark-shops__scrollbar-height;
      background-color: $earthmark-shops__scrollbar-tracker;
      border-radius: $earthmark-shops__scrollbar-border-radius;
      transition: $earthmark-shops__scrollbar-tracker-transition;
    }
  }

  &__sale {
    display: grid;
    grid-template-areas: $earthmark-shops-sale__grid-template-areas;
    grid-template-columns: $earthmark-shops-sale__grid-template-columns;
    margin: $earthmark-shops-sale__margin;
    padding: $earthmark-shops-sale__padding;

    @media #{$md} {
      grid-template-areas: $earthmark-shops-sale__grid-template-areas--mobile;
      grid-template-columns: $earthmark-shops-sale__grid-template-columns--mobile;
      padding: $earthmark-shops-sale__padding--mobile;
    }

    &-title {
      grid-area: title;
      margin: $earthmark-shops-sale-title__margin;
      font-weight: $earthmark-shops-sale-title__font-weight;
      font-size: $earthmark-shops-sale-title__font-size;
      line-height: $earthmark-shops-sale-title__line-height;
    }

    &-text {
      grid-area: text;
      font-weight: $earthmark-shops-sale-text__font-weight;
      font-size: $earthmark-shops-sale-text__font-size;
      line-height: $earthmark-shops-sale-text__line-height;

      @media #{$md} {
        margin: $earthmark-shops-sale-text__margin--mobile;
      }
    }

    &-cta {
      grid-area: cta;
      align-self: $earthmark-shops-sale-cta__align-self;
      justify-self: $earthmark-shops-sale-cta__justify-self;
      width: $earthmark-shops-sale-cta__width;
      height: $earthmark-shops-sale-cta__height;
      margin: $earthmark-shops-sale-cta__margin;
      padding: $earthmark-shops-sale-cta__padding;
      color: $earthmark-shops-sale-cta__color;
      font-weight: $earthmark-shops-sale-cta__font-weight;
      font-size: $earthmark-shops-sale-cta__font-size;
      line-height: $earthmark-shops-sale-cta__line-height;
      background-color: $earthmark-shops-sale-cta__background-color;
      border-radius: $earthmark-shops-sale-cta__border-radius;

      @media #{$md} {
        justify-self: $earthmark-shops-sale-cta__justify-self--mobile;
        margin: $earthmark-shops-sale-cta__margin--mobile;
      }
    }
  }

  &__know-more {
    display: grid;
    grid-template-areas: $earthmark-shops-know-more__grid-template-areas;
    grid-template-columns: $earthmark-shops-know-more__grid-template-columns;
    max-height: $earthmark-shops-know-more__max-height;
    padding: $earthmark-shops-know-more__padding;
    overflow: hidden;
    background-color: $earthmark-shops-know-more__background-color;
    transition: $earthmark-shops-know-more__transition;

    @media #{$md} {
      grid-template-areas: $earthmark-shops-know-more__grid-template-areas--mobile;
      grid-template-columns: $earthmark-shops-know-more__grid-template-columns--mobile;
      padding: $earthmark-shops-know-more__padding--mobile;
    }

    @media (max-width: 350px) {
      max-height: ($earthmark-shops-toggle__line-height + nth($earthmark-shops-know-more__padding--mobile, 1)) * 2;
    }

    & > *:not(#{$this}__toggle) {
      opacity: 0;
      transition: opacity 0.2s ease-in-out;
    }

    &.opened {
      max-height: $earthmark-shops-know-more--opened__max-height;

      @media #{$md} {
        max-height: $earthmark-shops-know-more--opened__max-height--mobile;
      }

      & > *:not(#{$this}__toggle) {
        opacity: 1;
      }
    }
  }

  &__toggle {
    grid-area: toggle;
    height: $earthmark-shops-toggle__height;
    margin: $earthmark-shops-toggle__margin;
    font-weight: $earthmark-shops-toggle__font-weight;
    font-size: $earthmark-shops-toggle__font-size;
    line-height: $earthmark-shops-toggle__line-height;
    text-decoration-line: unset;

    @media #{$md} {
      margin: $earthmark-shops-toggle__margin--mobile;
    }

    span {
      text-decoration-line: underline;
      cursor: pointer;
    }

    i {
      &::before {
        transform: rotate(90deg);
      }
    }
  }

  &__info {
    grid-area: info;
    font-weight: $earthmark-shops-info__font-weight;
    font-size: $earthmark-shops-info__font-size;
    line-height: $earthmark-shops-info__line-height;
  }

  &__visual-demo {
    display: grid;
    grid-area: visual-demo;
    grid-template-areas: $earthmark-shops-visual-demo__grid-template-areas;
    grid-template-columns: $earthmark-shops-visual-demo__grid-template-columns;
    align-items: center;
    justify-items: center;
    justify-self: end;
    max-width: $earthmark-shops-visual-demo__max-width;
    column-gap: $earthmark-shops-visual-demo__column-gap;

    @media #{$md} {
      justify-self: center;
    }
  }

  .scoring {
    width: $earthmark-shops-scoring__width;
    margin: $earthmark-shops-scoring__margin;
    font-weight: $earthmark-shops-scoring__font-weight;
    font-size: $earthmark-shops-scoring__font-size;
    line-height: $earthmark-shops-scoring__line-height;
    text-align: center;

    &--average {
      grid-area: scoring-average;
      color: $earthmark-shops-scoring--average__color;
    }

    &--high {
      grid-area: scoring-high;
      color: $earthmark-shops-scoring--high__color;
    }
  }

  .double-sided-arrow {
    position: relative;
    grid-area: arrow;
    width: $earthmark-shops-double-sided-arrow__width;
    height: $earthmark-shops-double-sided-arrow__height;
    background-image: $earthmark-shops-double-sided-arrow__background-image;

    &::before,
    &::after {
      position: absolute;
      top: 2px;
      border: 9px solid transparent;
      transform: translateY(-50%);
      content: "";
    }

    &::before {
      right: calc(100% - 5px);
      border-right: 15px solid $double-side-arrow__color--left;
    }

    &::after {
      left: calc(100% - 5px);
      border-left: 15px solid $double-side-arrow__color--right;
    }
  }

  .leafs {
    margin: $earthmark-shops-leafs__margin;

    #{$this}__leaf {
      width: $earthmark-shops-leafs__width;
    }

    &--average {
      grid-area: leafs-average;

      #{$this}__leaf {
        &:nth-last-of-type(#{$earthmark-shops-leafs__count-green-element}) ~ .earthmark-shops__leaf {
          background-image: $earthmark-shops__leaf--gray;
        }
      }
    }

    &--hight {
      grid-area: leafs-hight;
    }
  }

  .impact {
    max-width: $earthmark-shops-impact__max-width;
    margin: $earthmark-shops-impact__margin;
    color: $earthmark-shops-impact__color;
    text-align: center;

    &--average {
      grid-area: impact-average;
    }

    &--high {
      grid-area: impact-high;
    }
  }
}
