#sidebar {
  .author-widget {
    margin-top: 15px;
    border-color: $author-widget__border-color;
    border-style: $author-widget__border-style;
    border-width: $author-widget__border-width;
  }
}

.pannacotta.card {
  .author-widget {
    margin: 0 15px 25px;
    border-color: $author-widget__border-color;
    border-style: $author-widget__border-style;
    border-width: $author-widget__border-width;
  }
}

#sidebar.pannacotta.sidebar,
.pannacotta.card {
  .author-widget {
    $this: ".author-widget";

    display: grid;
    grid-template-areas: $author-widget__grid-template-areas;
    grid-template-columns: $author-widget__grid-template-columns;
    gap: $author-widget__gap;
    padding: $author-widget__padding;
    background-color: $author-widget__bg-color;
    border-radius: $author-widget__border-radius;

    &__header {
      grid-area: header;
      margin: $author-widget-header__margin;
      color: $author-widget-header__color;
      font-weight: $author-widget-header__font-weight;
      font-size: $author-widget-header__font-size;
      line-height: $author-widget-header__line-height;

      @media #{$sm} {
        font-size: $author-widget-header__font-size--mobile;
        line-height: $author-widget-header__line-height--mobile;
      }
    }

    &__avatar {
      grid-area: avatar;
      margin: $author-widget-avatar__margin;

      img {
        width: $author-widget-avatar-img__width;
        height: $author-widget-avatar-img__height;
        border-radius: $author-widget-avatar-img__border-radius;
      }
    }

    &__name {
      grid-area: name;
      margin: $author-widget-name__margin;
      padding: $author-widget-name__margin;
      color: $author-widget-name__color;
      font-weight: $author-widget-name__font-weight;
      font-size: $author-widget-name__font-size;
      line-height: $author-widget-name__line-height;
      text-decoration: $author-widget-name__text-decoration;

      &-link {
        grid-area: name;
      }
    }

    &__profession {
      grid-area: profession;
      margin: $author-widget-profession__margin;
      padding: $author-widget-profession__padding;
      color: $author-widget-profession__color;
      font-weight: $author-widget-profession__font-weight;
      font-size: $author-widget-profession__font-size;
      line-height: $author-widget-profession__line-height;
      text-transform: $author-widget-profession__text-transform;

      @media #{$sm} {
        font-size: $author-widget-profession__font-size--mobile;
      }
    }

    &__social-link {
      display: $author-widget-social-link__display;
      grid-area: social-link;
      width: $author-widget-social-link__width;
      height: $author-widget-social-link__height;
      margin: $author-widget-social-link__margin;
      background-image: $author-widget-social-link__bg-image;
      background-repeat: no-repeat;
    }

    &__bio {
      grid-area: bio;
      margin: $author-widget-bio__margin;
      padding: $author-widget-bio__padding;
      color: $author-widget-bio__color;
      font-weight: $author-widget-bio__font-weight;
      font-size: $author-widget-bio__font-size;
      line-height: $author-widget-bio__line-height;
      border-color: $author-widget-bio__border-color;
      border-style: $author-widget-bio__border-style;
      border-width: $author-widget-bio__border-width;

      @media #{$sm} {
        font-size: $author-widget-bio__font-size--mobile;
        line-height: $author-widget-bio__line-height--mobile;
      }

      a {
        color: $author-widget-bio-link__color;
        text-decoration: underline;

        &:hover {
          color: $author-widget-bio-link__color--hover;
        }
      }
    }

    &__additional-info {
      grid-area: additional-info;
      margin: $author-widget-additional-info__margin;
      padding: $author-widget-additional-info__padding;
      color: $author-widget-additional-info__color;
      font-weight: $author-widget-additional-info__font-weight;
      font-size: $author-widget-additional-info__font-size;
      line-height: $author-widget-additional-info__line-height;
      border-color: $author-widget-additional-info__border-color;
      border-style: $author-widget-additional-info__border-style;
      border-width: $author-widget-additional-info__border-width;

      @media #{$sm} {
        font-size: $author-widget-additional-info__font-size--mobile;
        line-height: $author-widget-additional-info__line-height--mobile;
      }
    }

    &__reviewed {
      grid-area: reviewed;
      margin: $author-widget-reviewed__margin;
      padding: $author-widget-reviewed__padding;
      color: $author-widget-reviewed__color;
      font-weight: $author-widget-reviewed__font-weight;
      font-size: $author-widget-reviewed__font-size;
      line-height: $author-widget-reviewed__line-height;
      border-color: $author-widget-reviewed__border-color;
      border-style: $author-widget-reviewed__border-style;
      border-width: $author-widget-reviewed__border-width;

      @media #{$sm} {
        font-size: $author-widget-reviewed__font-size--mobile;
        line-height: $author-widget-reviewed__line-height--mobile;
      }
    }

    &__page-links {
      grid-area: links;
      margin: $author-widget-page-links__margin;
      padding: $author-widget-page-links__padding;
      list-style-type: $author-widget-page-links__list-style-type;
    }

    &__page-link {
      display: $author-widget-page-link__display;
      margin: $author-widget-page-link__margin;
      padding: $author-widget-page-link__padding;
      color: $author-widget-page-link__color;
      font-weight: $author-widget-page-link__font-weight;
      font-size: $author-widget-page-link__font-size;
      line-height: $author-widget-page-link__line-height;

      &:last-child {
        margin: 0;
      }

      @media #{$sm} {
        font-size: $author-widget-page-link__font-size--mobile;
        line-height: $author-widget-page-link__line-height--mobile;
      }

      &:hover {
        text-decoration: underline;
      }

      &::before {
        @if $icon-link {
          font-size: $author-widget-page-link-before__font-size;
          font-family: mighty-roberto, serif;
          content: "\e824";
        } @else if $icon-circle {
          width: $author-widget-page-link-before__width;
          height: $author-widget-page-link-before__height;
          background-color: $author-widget-page-link-before__bg-color;
          border-radius: 50%;
          content: $author-widget-page-link-before__content;
        }

        display: inline-block;
        margin-right: 6px;
      }
    }
  }
}
