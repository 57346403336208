.alert {
  border-radius: 0;
}

.card-alert {
  .card-header {
    .card-title {
      color: #FFF;
      font-weight: lighter;
      font-size: 16px;
      line-height: 20px;

      b,
      strong {
        font-weight: bold;
      }

      .btn {
        padding: 1px 4px 2px !important;
        border-radius: 24px;
        cursor: auto;
      }
    }
  }
}
