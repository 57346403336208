html {
  font-size: 14px;
}

body {
  font-family: "Open Sans", "Helvetica Neue", Arial, Helvetica, sans-serif;
  background-color: $color-body-bg;
  background-repeat: $background-repeat;
  background-position: $background-position;
  background-size: $background-size;
  background-attachment: $background-attachment;
  text-rendering: optimizelegibility;
}

body.only-content {
  padding-top: 28px;
}

b,
strong {
  font-weight: bold;
}

.text-ellipsis {
  @include flat-truncate;
}

.btn-group-lg > .btn,
.btn-lg {
  border-radius: 0 !important;
}

.bold-text {
  font-weight: bold;
}

.privacy_policy {
  font-weight: normal;

  a {
    color: #FFF;
    text-decoration: underline;
  }
}

a:hover,
a:focus {
  text-decoration: underline;
}

.loader {
  width: 30px;
  height: 30px;
  border: 4px solid #F3F3F3;
  border-top: 4px solid #000;
  border-bottom: 4px solid #000;
  border-radius: 50%;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.header-no-image {
  .page-title {
    margin-top: 30px;
    font-size: 30px;
  }
}

.page-title {
  margin: 0 0 10px;
  font-weight: 700;
  font-size: 18px;
  line-height: 1.2;

  @media (min-width: 768px) {
    margin: 0 0 15px;
    font-size: 30px;
  }

  @media (min-width: 992px) {
    font-size: 32px;
  }
}

.page-subtitle {
  margin: 20px 0 10px;
  font-weight: bold;
  font-size: 14px;
}

.search-overlay,
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
  display: none;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
}

.backdrop--visible {
  display: block;
}

@media (min-width: 1025px) {
  [data-is-mobile="true"] {
    display: none !important;
  }
}
