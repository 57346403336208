.flexslider {
  position: relative;

  ul {
    list-style: none;
  }

  &__list {
    display: flex;
    padding: 10px 0;
    overflow: auto hidden;
    scroll-snap-type: x mandatory;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__item {
    display: flex;
    flex: 0 0 100%;
    justify-content: space-between;
    scroll-snap-align: center;

    @media #{$sm} {
      flex-wrap: wrap;
      row-gap: 5px;
    }

    @media #{$min-xs} and #{$sm} {
      column-gap: 10px;
    }

    a {
      flex-basis: 16%;
      max-width: 180px;
      height: auto;
      background-color: #FFF;
      border: 1px solid #D8D8D8;
      aspect-ratio: 1/1;

      @media #{$sm} {
        flex-basis: 32%;
      }

      img {
        display: inline;
        font-size: 0;
      }
    }
  }

  &__navigation-dots {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;

    li {
      margin: 0 5px;
    }

    &--item {
      display: block;
      width: 15px;
      height: 15px;
      text-indent: -9999px;
      background: #C6CFD1;
      border-radius: 20px;
      cursor: pointer;

      img {
        font-size: 0;
      }

      &.active {
        background-color: #737373;
      }
    }
  }

  &__navigation-arrow {
    display: none;

    @media #{$min-lg} {
      display: block;

      &-item {
        position: absolute;
        top: calc(50% - 34px);
        display: block;
        width: 34px;
        height: 34px;
        overflow: hidden;
        text-align: center;
        background-color: $color-white;
        border-radius: 20px;
        cursor: pointer;

        &:hover {
          text-decoration: none;
          box-shadow: 1px 2px 2px $color-inactive;
        }

        &::before {
          display: inline-block;
          color: $color-headline;
          font-size: 18px;
          font-family: mighty-roberto, serif;
          line-height: 36px;
          content: "\e81a";
        }

        &.prev {
          left: -40px;
        }

        &.next {
          right: -40px;

          &::before {
            position: relative;
            left: 3px;
            content: "\e803";

            img {
              max-width: 90px;
              height: auto;
            }
          }
        }
      }
    }
  }
}
