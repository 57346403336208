.card-categories-index {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  .h1,
  .h2,
  .h3,
  .h4,
  .h5,
  .h6 {
    color: $color-headline;
    font-weight: lighter;

    b,
    strong {
      font-weight: lighter;
    }
  }

  h1,
  h2,
  .h1,
  .h2 {
    font-size: 20px;
  }

  h3,
  .h3 {
    margin-top: 30px;
    color: $color-subheadline;
    font-size: 16px;
  }

  h4,
  h5,
  h6,
  .h4,
  .h5,
  .h6 {
    font-size: 15px;
  }

  h1 + h2,
  h2 + h3,
  h3 + h4,
  h4 + h5,
  h5 + h6,
  .h1 + .h2,
  .h2 + .h3,
  .h3 + .h4,
  .h4 + .h5,
  .h5 + .h6 {
    margin-top: 0;
    color: $color-subheadline;
  }

  hr {
    margin-right: -25px;
    margin-left: -25px;
    border-top-color: $color-cloud;

    &:last-child {
      display: none !important;
    }
  }

  .list-main-categories {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    margin-right: -18px;
    margin-bottom: 0;

    &::after {
      flex: auto;
      content: "";
    }

    li {
      box-sizing: border-box;
      width: 47%;
      margin-right: 3%;
      margin-bottom: 20px;
      font-size: 12px;
      text-align: center;
      background-color: white;
      border: 1px solid #D8D8D8;

      a {
        display: block;
        height: 100%;
        padding: 0 10px 20px;
        color: $color-text;
        cursor: pointer;

        &:hover,
        &:active,
        &:focus {
          text-decoration: none;
        }

        i {
          display: block;
          font-weight: bold;
          font-size: 45px;
          line-height: 90px;
        }
      }
    }
  }
}

.card-categories-description {
  .card-content {
    padding: 25px 25px 15px;

    p {
      color: $color-text;
      font-weight: lighter;
      font-size: 14px;
    }
  }
}

@media (min-width: 768px) {
  .card-categories-index {
    .list-main-categories {
      margin-right: -10px;

      li {
        width: 31.3%;
        margin-right: 2%;
        margin-bottom: 20px;
      }
    }
  }
}

@media (min-width: 992px) {
  .card-categories-index {
    .list-main-categories {
      li {
        width: 19%;
        margin-right: 1%;
        margin-bottom: 10px;
      }
    }
  }
}

.card-shops-index {
  .card-content {
    padding: 0 25px 15px;
  }
}

.pannacotta.categories.show {
  @media #{$sm} {
    .card-shops-index {
      .card-content {
        ol {
          li {
            padding: 5px 0;
            line-height: 44px;
          }
        }
      }
    }
  }
}
