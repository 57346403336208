$color_black_haze_approx: #F6F6F6;
$color_star_dust_approx: #9E9E9E;

#footer {
  margin-top: 24px;
  padding: 0 0 30px;
  font-weight: lighter;
  background-color: $color-text;
  border-top: 3px solid $color-ready;

  .home & {
    margin-top: 40px;
  }

  .col-xs-12 {
    padding: 0;
  }

  .widget {
    &.active {
      .widget-header {
        background-color: darken($color-text, 10%);
        border-bottom-color: darken($color-text, 10%);
      }
    }

    .widget-header {
      position: relative;
      padding: 15px 0;
      background-color: darken($color-text, 5%);
      border-bottom: 1px solid $color-text;
      cursor: pointer;
      transition: background-color 0.3s ease, border-bottom-color 0.3s ease;

      &:focus,
      &:hover {
        background-color: darken($color-text, 10%);
      }

      &::after {
        position: absolute;
        top: 50%;
        right: 10px;
        margin-top: -10px;
        color: #FFF;
        font-family: mighty-roberto, serif;
        line-height: 20px;
        transform: rotate(87deg);
        content: "\e803";
      }

      &.collapsed::after {
        position: absolute;
        top: 50%;
        right: 10px;
        margin-top: -10px;
        color: #FFF;
        font-family: mighty-roberto, serif;
        line-height: 20px;
        transform: rotate(-93deg);
        content: "\e803";
      }

      .widget-title {
        margin: 0;
        color: #FFF;
        font-weight: normal;
        font-size: 19px;
        text-align: center;
      }
    }

    .widget-content {
      padding: 0 0 10px;
      text-align: center;
      background-color: darken($color-text, 10%);
      border-bottom: 1px solid $color-text;

      ul li {
        line-height: 40px;

        a {
          color: #FFF;
          transition: 0.3s ease;

          &:focus,
          &:hover {
            text-decoration: none;
            border-bottom: 1px solid #FFF;
          }
        }

        i,
        span {
          display: none;
        }
      }

      &.opened {
        display: block;
      }

      @media (max-width: 767px) {
        display: none;
      }
    }
  }

  .copyright {
    color: #FFF;

    img {
      margin: 30px auto;
    }
  }

  .widget.footer_shops {
    color: #FFF;
    line-height: 28px;

    .widget-content {
      color: darken($color-text, 10%);
      text-align: center;

      a {
        display: block;
        color: #FFF;
        text-decoration: none;
      }
    }
  }

  .disclaimer {
    display: none;
  }
}

@media #{$min-sm} {
  #footer {
    padding: 20px 0 30px;
    border-top: none;

    .col-xs-12 {
      padding: 0 15px;
    }

    .widget {
      .widget-header {
        &::after,
        &.collapsed::after {
          display: none;
          content: "";
        }

        padding: 0;
        background-color: transparent;
        border-bottom: none;
        cursor: auto;

        &:focus,
        &:hover {
          background-color: transparent;
        }

        .widget-title {
          margin: 10px 0 20px;
          font-weight: lighter;
          font-size: 21px;
          text-align: left;
        }
      }

      .widget-content {
        margin-top: 0;
        padding: 0;
        text-align: left;
        background-color: transparent;
        border-bottom: none;

        ul li i,
        ul li span {
          display: inline-block;
        }

        ul li i {
          width: 36px;
          height: 36px;
          margin: 0 10px 0 0;
          padding: 8px 7px 4px;
          color: #FFF;
          font-size: 18px;
          line-height: 20px;
          text-align: center;
          border: 2px solid #FFF;
          border-radius: 20px;
        }
      }
    }

    .widget.footer_shops {
      margin: 30px auto;

      .widget-content {
        color: #FFF;

        a {
          display: inline;
        }
      }
    }

    .copyright {
      img {
        margin: 10px auto 0;
      }

      .disclaimer {
        display: block;
        margin-top: 30px;
        color: #FFF;
      }
    }
  }
}

#regions {
  padding: 30px 0 0;

  @media #{$min-sm} {
    margin-bottom: 30px;
  }

  span.title {
    display: none;
    margin-bottom: 20px;
    color: #FFF;
    font-weight: lighter;
    font-size: 21px;

    @media #{$min-sm} {
      display: block;
    }
  }

  ul.flags-group {
    display: flex;
    margin: 0;
    padding: 0;
    list-style: none;

    @media #{$min-sm} {
      width: 700px;
      margin: 0 auto;
    }
  }

  li.flag {
    display: inline-block;
    flex: 0 1 33.32%;
    padding: 7px;

    @media #{$min-sm} {
      flex: 0 1 16.67%;
      padding: 10px;
    }

    a {
      text-align: center;
      text-decoration: none;

      &:hover {
        text-decoration: none;
      }
    }

    img {
      display: block;
      margin: 0 auto 5px;
      border-radius: 50%;
      box-shadow: 1px 1px 1px $color_star_dust_approx;
      transition: opacity 0.3s;

      @media #{$min-sm} {
        max-width: 50px;
      }
    }

    span {
      display: none;
      color: #FFF;
      font-weight: lighter;
      font-size: 20px;

      @media #{$min-sm} {
        display: inline;
      }

      &:hover {
        text-decoration: underline;
      }
    }

    &:hover img {
      opacity: 0.5;
    }
  }
}
