.home {
  .sub-menu + .container {
    background: transparent !important;
  }

  #main-menu > ul > li > a {
    border-left-color: transparent;
  }

  .headings,
  .headings--no_hero {
    h1 {
      margin: 0 0 8px;
      font-weight: bold;
      font-size: 25px;
      line-height: 1.25;
    }

    p {
      margin-bottom: 0;
      font-weight: normal;
      font-size: 14px;
      text-transform: none;
    }
  }

  .headings--no_hero {
    h1 {
      margin-top: 50px;
    }
  }

  .hero {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-image: none;
  }

  .search-home-header {
    .search-header {
      padding: 10px;
      background-color: #FFF;
      border: 2px solid $color-ready;
      border-radius: 2px;
      transition: border-color 0.3s ease;

      &.active {
        border-color: $color-sky;
      }

      #input_search_header {
        width: 273px;
        height: 42px;
        padding: 11px 12px 11px 0;
        color: $color-text;
        font-weight: lighter;
        font-size: 14px;
        line-height: 20px;
        border: 0;
        border-radius: 4px 0 0 4px;
        box-shadow: none;
        transition: border-bottom-color 0.3s ease, color 0.3s ease;
      }

      .btn {
        padding: 11px 14px 10px;
        font-size: 14px;
      }
    }
  }

  h2 {
    display: inline-block;
    margin-top: 32px;
    margin-bottom: 12px;
    font-size: 20px;
    text-align: center;
  }

  .more-link {
    display: none;
  }
}

.form-control::placeholder {
  color: #595959;
  opacity: 1;
}

@media (max-width: 767px) {
  .home {
    .hero {
      background-image: none !important;
    }
  }
}

@media (min-width: 768px) {
  .home {
    #header {
      margin-bottom: 0;
      border-bottom: none;
    }

    .hero {
      min-height: 210px;
      background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url("../../../../images/flat_2016/hero.jpg");
      background-position: center center;
      background-size: cover;
    }

    .headings {
      h1 {
        margin: 20px 0 10px;
      }

      p {
        margin-bottom: 28px;
      }
    }

    .headings {
      color: $color-white;

      h1 {
        margin: 0 0 8px;
        font-weight: bold;
        font-size: 20px;
      }

      p {
        font-weight: 500;
        font-size: 16px;
      }
    }

    .search-home-header .search-header {
      display: inline-block;
      background: none;
      border: 0;

      form {
        display: inline-block;
        padding: 8px;
        background-color: rgba(0, 0, 0, 0.4);
        border: 0;
        border-radius: 5px;
      }

      #input_search_header {
        height: 44px;
        padding: 12px;
        border: none;
        border-radius: 4px 0;
      }
    }

    h2 {
      margin-top: 40px;
      font-size: 20px;
      text-align: left;
    }

    .more-link {
      position: absolute;
      top: 0;
      right: 0;
      display: block;
      margin: 44px 15px 0;
      color: $color-ashes;

      &:hover {
        color: $color-sky;
        text-decoration: none;
      }
    }
  }
}

.search-header-popular {
  margin-top: 10px;
  padding-left: 0;
  font-size: 14px;
  text-align: left;
  list-style-type: none;

  li {
    display: inline-block;
    padding: 0 5px;
    background: rgba(0, 0, 0, 0.4);
    border-radius: 4px;

    &.search-header-popular__text {
      color: #FFF;
      background: none;
    }
  }

  a {
    color: #FFF;

    &:hover {
      color: #FFF;
    }
  }
}

@media (min-width: 992px) {
  .home {
    .headings {
      h1 {
        font-size: 36px;
      }

      p {
        font-size: 24px;
      }
    }

    .headings--no_hero {
      h1 {
        font-size: 36px;
      }

      p {
        margin-bottom: 20px;
        font-size: 24px;
      }
    }

    .hero {
      height: 350px;
    }

    .search-home-header .search-header {
      #input_search_header {
        width: 383px;
        height: 52px;
      }
    }

    .search-result-shop img {
      width: 85px;
      height: 85px;
    }

    h2 {
      font-size: 24px;
    }
  }
}

@media (min-width: 1200px) {
  .home {
    .search-home-header .search-header #input_search_header {
      width: 483px;
    }

    .search-result-shop img {
      width: 115px;
      height: 115px;
    }
  }
}
