.featured-coupons {
  .coupon__exclusive {
    display: inline-block;
    margin: 0 0 0 23px;
    text-align: left;

    .coupon__banner {
      margin: 5px 0;
    }
  }

  &.list-coupons-as-items li.coupon-item a .content span {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    white-space: inherit;
    -webkit-box-orient: vertical;
  }
}

.exclusive {
  text-align: center;
}

.coupon__banner {
  position: relative;
  display: inline-block;
  margin: 0;
  padding: 1px 8px 1px 0;
  color: $color-white;
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  white-space: nowrap;
  background-color: $color-headline;

  &::before {
    position: absolute;
    top: 0;
    left: -8px;
    border-color: $color-headline $color-headline $color-headline transparent;
    border-style: solid;
    border-width: 10px 4px 9px;
    content: "";
  }

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    border-color: transparent;
    border-style: solid;
    border-width: 10px 4px 9px;
    content: "";
  }
}

.coupon__title {
  .coupon__exclusive {
    display: none;
  }
}

@media #{$min-sm} {
  .coupon__banner {
    margin-left: 8px;
    font-size: 9px;
  }
}

@media #{$sm} {
  .featured-coupons {
    .exclusive {
      display: block;
    }
  }
}
