.card {
  margin-bottom: 30px;
  background-color: #FFF;
  border: 1px solid $color-border;
  border-bottom-width: 2px;
  border-radius: 2px;

  &.card-transparent {
    background-color: transparent;
    border: none;
  }

  .card-header {
    padding: 25px;

    &.with-border-bottom {
      border-bottom: 1px solid $color-border;
    }

    &.bg-freedom,
    &.bg-royal {
      .card-title {
        color: #FFF;
      }
    }

    .card-title {
      margin: 0;
      color: $color-title;
      font-weight: lighter;
      font-size: 20px;

      b,
      strong {
        font-weight: bold;
      }
    }
  }

  .card-content {
    hr {
      border-top-color: $color-cloud;
    }
  }

  .card-footer {
    padding: 20px 25px;

    &.with-border-top {
      border-top: 1px solid $color-border;
    }
  }

  &.card-spacer {
    height: 120px;
    background-color: transparent;
    border: 0;
  }
}

@media (min-width: 992px) {
  .card.card-spacer {
    height: 100px;
  }
}

@media (min-width: 1200px) {
  .card.card-spacer {
    height: 120px;
  }
}
