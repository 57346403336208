$color-ready-dark: darken($color-ready, 10%);
$color-ready-light: lighten($color-ready, 10%);

.coupon__button {
  position: relative;
  right: auto;
  left: auto;
  display: inline-block;
  min-width: 175px;
  max-width: 90%;
  height: 40px;
  margin: 0 auto;
  padding: 0 10px;
  overflow: hidden;
  color: white;
  font-weight: 700;
  font-size: 11px;
  line-height: 40px;
  white-space: nowrap;
  text-align: center;
  text-transform: uppercase;
  text-overflow: ellipsis;
  background-color: $color-ready;
  border-radius: 3px;
  box-shadow: inset -3px -3px 0 rgba(0, 0, 0, 0.2);
  cursor: pointer;
  transition: background-color 0.5s;

  &:hover {
    background-color: $color-ready-dark;
  }

  &--esdo {
    &:hover {
      background-color: $esdo-color;
    }
  }

  &-code {
    span.code {
      position: absolute;
      right: 0;
      display: inline-block;
      width: 43px;
      height: 40px;
      padding-top: 2px;
      padding-left: 10px;
      color: #000;
      line-height: 40px;
      text-align: left;
      background: lighten($color-ready, 20%);
      box-shadow: inset 7px 0 9px -6px rgba(0, 0, 0, 0.4);
    }
  }

  &.coupon__button--gift-card {
    overflow: hidden;
    background-color: $color-white;
    background-image: url("data:image/svg+xml,%3Csvg width='16' height='13' viewBox='0 0 16 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16 3.373V10.5C16 11.163 15.7366 11.7989 15.2678 12.2678C14.7989 12.7366 14.163 13 13.5 13H2.5C1.83696 13 1.20107 12.7366 0.732233 12.2678C0.263392 11.7989 0 11.163 0 10.5V3.373L7.746 7.931C7.82295 7.97638 7.91066 8.00032 8 8.00032C8.08934 8.00032 8.17705 7.97638 8.254 7.931L16 3.373ZM13.5 3.09527e-08C14.1151 -9.6716e-05 14.7087 0.226607 15.1672 0.63674C15.6257 1.04687 15.9168 1.61164 15.985 2.223L8 6.92L0.0150001 2.223C0.0831583 1.61164 0.374324 1.04687 0.832795 0.63674C1.29127 0.226607 1.88485 -9.6716e-05 2.5 3.09527e-08H13.5Z' fill='%23EC1A2E'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-position: calc(100% - 10px) center;
    border: 1px solid $color-ready;
    transition: background-color 0.5s;

    &:hover {
      background-image: url("data:image/svg+xml,%3Csvg width='16' height='13' viewBox='0 0 16 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16 3.373V10.5C16 11.163 15.7366 11.7989 15.2678 12.2678C14.7989 12.7366 14.163 13 13.5 13H2.5C1.83696 13 1.20107 12.7366 0.732233 12.2678C0.263392 11.7989 0 11.163 0 10.5V3.373L7.746 7.931C7.82295 7.97638 7.91066 8.00032 8 8.00032C8.08934 8.00032 8.17705 7.97638 8.254 7.931L16 3.373ZM13.5 3.09527e-08C14.1151 -9.6716e-05 14.7087 0.226607 15.1672 0.63674C15.6257 1.04687 15.9168 1.61164 15.985 2.223L8 6.92L0.0150001 2.223C0.0831583 1.61164 0.374324 1.04687 0.832795 0.63674C1.29127 0.226607 1.88485 -9.6716e-05 2.5 3.09527e-08H13.5Z' fill='%23C31021'/%3E%3C/svg%3E%0A");
      border: 1px solid $color-ready-dark;

      .coupon__button-text {
        background-color: $color-ready-dark;
      }
    }
  }

  &-text {
    position: absolute;
    top: 0;
    left: 0;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: calc(100% - 36px);
    height: 40px;
    background-color: $color-ready;
    border-radius: 0 6px 6px 0;
    cursor: pointer;
    transition: background-color 0.5s;
  }
}

.coupons__list {
  *[class$="--highlighted"]:not(.coupons__item--gift-card) {
    .coupon {
      &__button {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        font-size: 0;

        &::before {
          position: initial;
          display: block;
          width: auto;
          font-size: 16px;
          content: var(--clicked-coupon-text);
        }

        &-code {
          @media #{$sm} {
            padding: 0;
          }

          &::after {
            position: initial;
            transform: none;
          }
        }
      }
    }
  }
}
