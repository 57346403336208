.card-contact-forms {
  .card-content {
    .form-control {
      color: $color-text;
      border-color: $color-border;
      border-radius: 2px;
      box-shadow: none;
    }

    .form-control:not(textarea) {
      height: 50px !important;
      line-height: 50px !important;
    }
  }
}

.card {
  border: $card-main-border;

  &-contact-forms {
    .card {
      &-header {
        padding: $card-header-padding;

        @media #{$md} {
          padding: $card-header-padding-mobile;
        }

        .card-title {
          color: $card-title-color;
          font-weight: $card-title-font-weight;
          font-size: $card-title-font-size;
          line-height: $card-title-line-height;

          @media #{$md} {
            font-size: $card-title-font-size-mobile;
            line-height: $card-title-line-height-mobile;
          }
        }
      }

      &-content {
        padding: $card-content-padding;
        color: $card-content-color;
        font-weight: $card-content-font-weight;
        font-size: $card-content-font-size;
        line-height: $card-content-line-height;

        .form-group {
          margin: $card-content-form-group-margin;

          @media #{$md} {
            margin: $card-content-form-group-margin-mobile;
          }
        }

        p {
          color: $card-content-paragraph-color;
          font-weight: 400;
          font-size: $card-content-paragraph-font-size;
          line-height: $card-content-paragraph-line-height;

          @media #{$md} {
            font-size: $card-content-paragraph-font-size-mobile;
            line-height: $card-content-paragraph-line-height-mobile;
          }
        }

        hr {
          margin: 30px 0 0;
          border: none;

          @media #{$md} {
            margin: 20px 0 0;
          }
        }

        label {
          margin: $card-content-label-margin;
          color: $color-black;
          font-weight: $card-content-label-font-weight;
          font-size: $card-content-label-font-size;
          line-height: $card-content-label-line-height;

          @media #{$md} {
            font-size: $card-content-label-font-size-mobile;
            line-height: $card-content-label-line-height-mobile;
          }
        }

        .form-control {
          max-width: $card-form-control-max-width;
          max-height: $card-form-control-max-height;
          border: $card-form-control-border;
        }

        #contact_form_message,
        #report_form_remark,
        #partner_form_message {
          min-height: $card-partner_form-message-min-height;
        }

        .btn {
          width: $card-partner_form-btn-width;
          height: $card-partner_form-btn-height;
          margin: $card-partner_form-btn-margin;
          line-height: 0;
          background-color: $card-partner_form-btn-background-color;
          border-radius: $card-partner_form-btn-border-radius;
        }

        .privacy-note {
          margin: $card-privacy-note-margin;
          color: $card-privacy-note-color;
          font-weight: $card-privacy-note-font-weight;
          font-size: $card-privacy-note-font-size;
          line-height: $card-privacy-note-line-height;
        }
      }
    }
  }
}
