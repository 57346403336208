.rotated-coupons.list-coupons-as-items {
  display: flex;
  flex-flow: row wrap;
  align-content: space-between;
  justify-content: space-between;
  margin-bottom: 0;

  .frame {
    min-height: 112px;
  }

  li.coupon-item {
    width: 100%;
    height: auto;

    a {
      display: block;
      width: 100%;
      max-width: 100%;
    }
  }
}

@media (min-width: 768px) {
  .rotated-coupons.list-coupons-as-items {
    li.coupon-item {
      width: 49.5%;
      margin-bottom: 0;
    }
  }
}

@media (max-width: 767px) {
  .rotated-coupons.list-coupons-as-items {
    flex-flow: column wrap;

    li.coupon-item {
      margin-bottom: 10px;
    }
  }
}

@media (min-width: 992px) {
  .rotated-coupons.list-coupons-as-items {
    li.coupon-item {
      display: flex !important;
      flex-flow: column;
      width: 32.8%;
    }
  }
}
