.read-progress-bar {
  position: sticky;
  top: 0;
  z-index: 1100;
  background-color: $color-white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
  will-change: transform;

  &__indicator {
    background-color: $color-cta;
  }
}
