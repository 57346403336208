.featured-images {
  .campaigns.show & {
    margin-bottom: 20px;
  }

  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  @media #{$min-lg} {
    gap: 10px;
  }

  @media #{$sm} {
    gap: 10px;
  }

  .group-item {
    display: flex;
    flex-grow: 0;
    align-items: center;
    justify-content: center;
    text-align: center;
    text-decoration: none;
    background-size: cover;
    transition: all 300ms ease-in 100ms;

    h3 {
      margin: 0;
      color: $color-white;
      font-weight: 600;
      font-size: 24px;
    }

    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(3),
    &:nth-child(4),
    &:nth-child(5) {
      flex: 0 1 100%;
      height: 145px;
      padding: 0;
      background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.2));

      @media #{$min-sm} and #{$lg} {
        margin-bottom: 10px;
      }
    }
  }
}

@media (max-width: 767px) {
  .featured-images-slider {
    .flexslider-featured-images {
      margin-bottom: 15px;
    }

    ul {
      li {
        a {
          display: flex;
          flex: 0 1 100%;
          flex-grow: 0;
          align-items: center;
          justify-content: center;
          height: 145px;
          padding: 0;
          text-align: center;
          text-decoration: none;
          background-color: white;
          background-size: cover;
          transition: all 300ms ease-in 100ms;

          h3 {
            margin: 0;
            color: white;
            font-weight: 600;
            font-size: 24px;
          }
        }
      }
    }
  }
}

@media (min-width: 768px) {
  .featured-images {
    .group-item {
      h3 {
        font-size: 30px;
      }

      &:nth-child(1),
      &:nth-child(2) {
        flex: 0 1 49.5%;
        height: 178px;
        padding: 0;
      }

      &:nth-child(3),
      &:nth-child(4),
      &:nth-child(5) {
        flex: 0 1 32.6666%;
        height: 116px;
        margin-bottom: 0;
        padding: 0;
      }
    }
  }
}

@media (min-width: 992px) {
  .featured-images {
    .group-item {
      background-size: 120%;

      &:hover {
        background-size: 100%;
      }

      h3 {
        font-size: 36px;
      }

      &:nth-child(1),
      &:nth-child(2) {
        height: 232px;
      }

      &:nth-child(3),
      &:nth-child(4),
      &:nth-child(5) {
        height: 154px;
      }
    }
  }
}

@media (min-width: 1200px) {
  .featured-images {
    .group-item {
      &:nth-child(1),
      &:nth-child(2) {
        height: 282px;
      }

      &:nth-child(3),
      &:nth-child(4),
      &:nth-child(5) {
        height: 186px;
      }
    }
  }
}
