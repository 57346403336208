.offer-carousel {
  &__col {
    position: relative;
    padding: 0 15px;
  }

  &__container {
    display: flex;
    scroll-snap-type: x mandatory;
    overflow-x: scroll;
    overflow-y: hidden;
    scroll-behavior: smooth;
    scrollbar-color: $offer-carousel__scrollbar #D1D1D1;

    @media #{$min-sm} {
      margin-bottom: 32px;
      scrollbar-width: none;
    }

    .col-lg-3.col-sm-4 {
      @media #{$sm} {
        margin-bottom: 26px;
        scroll-snap-align: center;
      }

      @media #{$min-sm} {
        min-width: 33.33333333%;
      }

      @media #{$min-lg} {
        min-width: 25%;
      }
    }

    &::-webkit-scrollbar {
      display: none;
    }

    &-arrow {
      position: absolute;
      top: 25%;
      padding: 40px 18px;
      background-color: #FFF;
      cursor: pointer;

      &--left {
        left: 15px;
        border-radius: 0 12px 12px 0;
        box-shadow: -2px 2px 4px 0 rgba(0, 0, 0, 0.1);

        &:hover {
          box-shadow: -2px 2px 4px 0 rgba(0, 0, 0, 0.3);
        }
      }

      &--right {
        right: 15px;
        border-radius: 12px 0 0 12px;
        box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.1);

        &:hover {
          box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.3);
        }
      }
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 380px;
    padding: 15px 0 0;
    box-shadow: 4px 4px 12px 0 rgba(0, 0, 0, 0.1);

    @media #{$sm} {
      min-width: 255px;
    }
  }

  &__img {
    max-width: 225px;
    height: auto;
    margin: 0 15px;
    object-fit: contain;
  }

  &__title {
    display: -webkit-box;
    margin: 11px 15px 0;
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
  }

  &__btn {
    display: inline-block;
    width: 100%;
    margin-top: auto;
    padding: 10px 0;
    color: $offer-carousel__btn;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    text-transform: capitalize;
    background-color: $offer-carousel__btn-background;
    cursor: pointer;

    &:hover {
      color: $offer-carousel__btn;
      text-decoration: none;
    }
  }

  &__navigation {
    display: flex;
    flex-direction: row;
    align-items: center;

    @media #{$min-sm} {
      padding: 0 15px;
    }

    @media #{$sm} {
      display: grid;
      grid-template-areas:
        "scroll-bar scroll-bar"
        "arrow-left arrow-right";
    }

    &-arrow {
      display: inline-block;
      width: min-content;
      margin: 0 27px 0 15px;
      pointer-events: none;

      svg {
        path {
          stroke: #D1D1D1;
        }
      }

      &--left {
        grid-area: arrow-left;

        @media #{$sm} {
          margin-left: auto;
        }
      }

      &--right {
        grid-area: arrow-right;

        @media #{$sm} {
          margin-right: auto;
        }
      }

      &--active {
        cursor: pointer;
        pointer-events: auto;

        svg {
          path {
            stroke: #333;
          }
        }
      }
    }

    &-indicator {
      position: relative;
      grid-area: scroll-bar;
      width: 99%;
      height: 6px;
      margin: 0;
      padding: 0;
      overflow: hidden;
      line-height: 0;
      list-style-type: none;
      background-color: #D1D1D1;
      border-radius: 6px;

      @media #{$sm} {
        width: 100%;
        margin: 0 0 30px;
      }
    }

    &-tracker {
      position: absolute;
      display: inline-block;
      height: 6px;
      background-color: $offer-carousel__scrollbar;
      border-radius: 6px;
    }
  }
}

a.offer-carousel {
  &__item {
    cursor: pointer;
  }
}
