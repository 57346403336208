.shop-list-columns {
  @for $i from 2 through 6 {
    &-#{$i} {
      columns: #{$i};
    }
  }

  padding-left: 0;
  text-align: center;
  list-style-type: none;

  &__item {
    padding: 5px;
    font-size: 15px;
  }

  @media (max-width: 768px) {
    columns: 2;
  }
}
