.list-coupons-as-items {
  .frame,
  .coupon-item {
    width: 100%;

    &:first-of-type {
      margin-bottom: 10px;
    }
  }

  .frame {
    min-height: 92px;
    background-color: $color-white;
    border: 1px solid $color-border;

    @media (max-width: 768px) {
      display: flex;
    }

    &:hover {
      box-shadow: 2px 2px 1px 0 $color-subheadline;
    }
  }

  .coupon-item {
    margin-bottom: 8px;

    &--big {
      .frame:first-of-type {
        height: 100%;
        margin-bottom: 0;
      }
    }

    a {
      &::after {
        display: table;
        clear: both;
        content: "";
      }

      > img {
        width: 90px;
        height: 90px;
      }

      .content {
        position: relative;
        display: inline-block;
        width: 85%;
        max-width: calc(100% - 110px);
        min-height: 60px;
        padding: 6px 12px 0;

        b,
        span {
          display: block;
          margin-top: 0;
          margin-bottom: 6px;
          overflow: hidden;
          font-weight: 500;
          font-size: 12px;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        b {
          word-break: break-all;
        }

        .clearfix span {
          display: inline;
        }

        .coupon-banner {
          margin-top: 4px;
          font-size: 11px;
        }

        .coupon-title {
          display: inline-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          color: $color-text;
          font-size: 12px;
        }
      }
    }
  }
}

@media (min-width: 768px) {
  .list-coupons-as-items {
    .coupon-item {
      height: auto;

      a {
        &::after {
          display: table;
          clear: both;
          content: "";
        }

        > img {
          width: 110px;
          height: 110px;
        }

        .content {
          b,
          span {
            display: block;
            margin-top: 1px;
            margin-bottom: 2px;
            font-weight: 500;
            font-size: 16px;
          }

          .coupon-title {
            font-size: 13px;
          }
        }
      }
    }
  }
}

@media (min-width: 992px) {
  .list-coupons-as-items {
    .coupon-item a .content {
      font-size: 14px;
    }
  }
}
