.pannacotta {
  &.home .seo-text-container {
    margin-top: 30px;
  }

  &.campaigns,
  &.home {
    .seo-text-container {
      margin-bottom: 30px;
      border-bottom: 1px solid #D8D8D8;

      .card {
        margin-bottom: 0;
        border-bottom: 0;

        .card-header {
          padding: 0;
        }

        .card-title {
          position: relative;
          margin: 0;
          padding: 20px 40px 20px 20px;
          color: #262626;
          font-weight: lighter;
          font-size: 20px;

          h2 {
            position: relative;
            margin: 0;
            font-weight: lighter;
            font-size: 20px;
            text-align: left;
          }
        }

        .card-content {
          border-top: 1px solid #D8D8D8;

          & > h3:first-child {
            margin-top: 0;
          }
        }
      }

      .card-text {
        transition: background-color 0.3s ease, color 0.3s ease;

        &.highlighted {
          background-color: #F9E090;
        }

        a {
          font-size: 16px;

          @media #{$sm} {
            font-size: 14px;
          }
        }
      }
    }
  }
}

#floating-anchors {
  position: absolute;
  top: -1px;
  left: -100px;
  display: none;
  width: 100px;

  // @include flat-transition(top .5s linear);

  &.fixed {
    position: fixed;
    top: 30px;
  }

  div.anchor-link {
    position: relative;

    a {
      display: block;
      padding: 10px;
      overflow: hidden;
      color: #333;
      font-size: 12px;
      text-overflow: ellipsis;
      border-bottom: 1px solid lightgrey;

      &.active,
      &:hover {
        color: #FFF;
        text-decoration: none;
        background: $color-sky;
        cursor: pointer;
      }

      &.active::after {
        position: absolute;
        top: 50%;
        right: -7px;
        display: block;
        width: 14px;
        height: 14px;
        margin-top: -7px;
        background: $color-sky;
        transform: rotate(45deg);
        content: "";
      }

      &:last-child {
        border-bottom: 0;
      }
    }
  }
}

.jump-to-links {
  padding: 25px;
  background: #FFF;
  border: 1px solid #D8D8D8;

  &__link {
    border-bottom: 1px solid transparent;
    transition: border-bottom-color 0.3s ease, color 0.3s ease;

    &:hover {
      color: #306AA1;
      text-decoration: none;
      border-bottom-color: #306AA1;
    }
  }

  &.list-unstyled {
    padding: 25px;
  }

  li {
    font-weight: lighter;
    font-size: 14px;
    line-height: 30px;
  }
}

@media (min-width: 1366px) {
  .pannacotta {
    .seo-text-container {
      #floating-anchors {
        display: block;
      }
    }
  }
}
