.premium-slider {
  margin: 26px -15px;

  .container {
    .row {
      position: relative;
    }
  }

  &__container {
    position: relative;
    display: flex;
    align-items: center;
    height: 100%;
    scroll-snap-type: x mandatory;
    overflow-x: scroll;
    overflow-y: hidden;
    scroll-behavior: smooth;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__arrow {
    position: absolute;
    top: 0;
    width: 90px;
    height: 100%;
    cursor: pointer;

    &::before {
      position: absolute;
      top: 50%;
      display: block;
      width: 32px;
      height: 32px;
      background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M20.952 16.3806C21.3426 15.9901 21.3426 15.3569 20.952 14.9664L14.5881 8.6024C14.1975 8.21188 13.5644 8.21188 13.1739 8.6024C12.7833 8.99293 12.7833 9.62609 13.1739 10.0166L18.8307 15.6735L13.1739 21.3303C12.7833 21.7208 12.7833 22.354 13.1739 22.7445C13.5644 23.1351 14.1975 23.1351 14.5881 22.7445L20.952 16.3806ZM19.9184 16.6735H20.2449V14.6735H19.9184V16.6735Z' fill='white'/%3E%3Ccircle r='15' transform='matrix(-1 0 0 1 16 16)' stroke='white' stroke-width='2'/%3E%3C/svg%3E%0A");
      background-repeat: no-repeat;
      transform: translateY(-50%);
      content: " ";
    }

    &:hover,
    &:focus {
      background: rgba(0, 0, 0, 0.4);
    }

    &:active {
      background: transparent;
    }

    @media #{$md} {
      display: none;
    }

    &--left {
      left: 0;

      &::before {
        left: 30px;
        transform: translate(0, -50%) rotate(180deg);
      }
    }

    &--right {
      right: 0;

      &::before {
        right: 30px;
      }
    }
  }

  &__indicator {
    &-list {
      position: absolute;
      bottom: -30px;
      z-index: 1;
      display: flex;
      justify-content: center;
      width: 100%;
      margin: 0;
      padding: 0;
      list-style-type: none;

      @media #{$sm} {
        bottom: -20px;
      }
    }

    &-item {
      span {
        display: block;
        width: 41px;
        height: 4px;
        margin: 8px;
        background-color: #D6D6D6;
        cursor: pointer;

        @media #{$sm} {
          width: 12px;
          height: 12px;
          margin: 0 5px;
        }
      }

      &--active span {
        background-color: #FFCAB7;
      }
    }
  }

  &__logo {
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90px;
    height: 90px;
    margin: 0 8px;
    overflow: hidden;
    text-align: center;
    background: #FAE6DF;
    border-radius: 5px;

    img {
      width: 86px;
      height: 86px;
    }

    @media #{$sm} {
      height: 80px !important;
    }
  }

  &__img {
    @media #{$sm} {
      max-width: 80px;
      max-height: 80px;
    }
  }

  &__item {
    position: relative;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    scroll-snap-align: center;
    width: 100%;
    min-width: 100%;
    height: $premium-slider-item__height;
    color: #FFF;
    background-repeat: no-repeat;
    background-size: cover;
    cursor: pointer;
    transition: none !important;

    &--curator {
      padding: 70px 0 20px;
    }

    .col-md-8 {
      display: flex;
      flex-flow: column;
      align-items: center;
      justify-content: center;
    }

    @media #{$sm} {
      width: 100%;
      height: $premium-slider-item__height--mobile;
      background-image: var(--bg-image-mobile);
    }

    @media #{$min-sm} {
      background-image: var(--bg-image-desktop);
    }

    &:hover {
      color: #FFF;
      text-decoration: none;
    }
  }

  &:hover {
    color: #FFF;
    text-decoration: none;
  }

  &__headline {
    color: #FFF;
    font-weight: 700;
    font-family: Roboto, sans-serif;

    @media #{$sm} {
      font-size: 18px;
    }

    @media #{$min-sm} {
      margin: 8px 0 0;
      font-size: 30px;
      line-height: 36px;
      text-align: left;
    }

    &--50 {
      @media #{$min-md} {
        font-size: 30px;
      }
    }
  }

  &__content {
    margin: 12px 0 0;
    color: #FFF;
    font-weight: 300;
    font-family: Roboto, sans-serif;
    text-align: left;

    @media #{$min-md} {
      font-size: 25px;
      line-height: 29px;

      &--50 {
        font-size: 24px;
      }
    }

    @media #{$sm} {
      margin-left: 0;
      font-size: 16px;
    }
  }

  &--opacity {
    &-white {
      position: relative;

      &::before {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.48);
        content: "";
      }
    }
  }

  &__links {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: 40px 0 10px;
    padding: 0;
    overflow: hidden;
    list-style-type: none;
    background-color: #FFF;

    @media #{$md} {
      flex-flow: column;
      margin-top: 28px;
    }
  }

  &__link {
    display: flex;
    flex-grow: 1;
    justify-content: space-around;
    height: 60px;
    margin: 0;
    background-color: #FFF;
    border: 1px solid rgba(0, 0, 0, 0.1);

    @media #{$md} {
      justify-content: flex-start;
      width: 100%;
    }

    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }

    &--divider {
      height: 100%;
      border-right: 1px solid $color-border;
      opacity: 0.1;
    }

    a {
      align-self: center;
      width: 100%;
      padding: 14px 16px;
      color: $color-border;
      text-decoration: none;
    }
  }

  &__button {
    margin: 18px 0 0;

    a {
      display: inline-block;
      padding: 11px 29px 12px;
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      border: 3px solid #FFF;
      border-radius: 10px;
      cursor: pointer;

      @media #{$sm} {
        padding: 7px 29px 8px;
        font-size: 16px;
        line-height: 20px;
      }

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &__curators {
    position: absolute;
    top: - $premium-slider-item__height;
    left: 0;
    display: flex;
    width: 100%;
    height: 100%;
    min-height: 67px;
    padding: 0;
    overflow: hidden;

    @media #{$sm} {
      top: - $premium-slider-item__height--mobile;
    }

    &:not(&--active) {
      pointer-events: none;
    }
  }

  &__curator {
    position: absolute;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: min-content;
    min-height: 67px;
    background-color: $premium-slider-curator__bg-color;
    border: $premium-slider-curator__border;
    border-width: $premium-slider-curator__border-width;
    border-radius: $premium-slider-curator__border-radius;
    transform: $premium-slider-curator__transform;
    transition: $premium-slider-curator__transition;
    transition-delay: $premium-slider-curator__transition-delay;

    &--active {
      z-index: 10;
      transform: $premium-slider-curator__transform--active;
      transition: $premium-slider-curator__transition--active;
      transition-delay: $premium-slider-curator__transition-delay--active;
    }
  }

  .curator-card {
    $this: &;

    display: grid;
    grid-template: $premium-slider-curator-card__grid-template;
    gap: $premium-slider-curator-card__gap;
    justify-items: $premium-slider-curator-card__justify-items;
    max-height: 100%;
    padding: $premium-slider-curator-card__padding;

    @media #{$sm} {
      grid-template: $premium-slider-curator-card__grid-template--mobile;
      justify-items: $premium-slider-curator-card__justify-items--mobile;
      padding: $premium-slider-curator-card__padding--mobile;
    }

    &__intro {
      grid-area: intro;
      color: $premium-slider-curator-card-intro__color;
      font-weight: $premium-slider-curator-card-intro__font-weight;
      font-size: $premium-slider-curator-card-intro__font-size;
      line-height: $premium-slider-curator-card-intro__line-height;

      @media #{$sm} {
        margin: $premium-slider-curator-card-intro__margin--mobile;
      }

      @media #{$min-sm} {
        margin: $premium-slider-curator-card-intro__margin;
      }
    }

    &__avatar {
      display: flex;
      grid-area: avatar;
      width: auto;
      height: $premium-slider-curator-card-avatar__height;
      max-height: 100%;
      aspect-ratio: 1/1;
      border-radius: 50%;

      @media #{$sm} {
        height: $premium-slider-curator-card-avatar__height--mobile;
      }

      img {
        margin: auto;
        padding: 3px;
        border: 1px solid #D3D3D3;
        border-radius: 50%;
      }
    }

    &__name {
      grid-area: name;
      margin: $premium-slider-curator-card-name__margin;
      color: $premium-slider-curator-card-name__color;
      font-weight: $premium-slider-curator-card-name__font-weight;
      font-size: $premium-slider-curator-card-name__font-size;
      line-height: $premium-slider-curator-card-name__line-height;

      &:hover {
        text-decoration: underline;
      }
    }

    &__profession {
      grid-area: profession;
      margin: $premium-slider-curator-card-profession__margin;
      color: $premium-slider-curator-card-profession__color;
      font-weight: $premium-slider-curator-card-profession__font-weight;
      font-size: $premium-slider-curator-card-profession__font-size;
      line-height: $premium-slider-curator-card-profession__line-height;
    }
  }
}

.pannacotta {
  .premium-slider {
    a {
      color: #FFF;
    }

    &__link {
      a {
        color: #000;
      }
    }
  }
}
