.top-x-coupons {
  .list-coupons-as-items {
    margin-bottom: 0;

    .coupon-item {
      font-weight: lighter;
      background: #FFF;
      border: 1px solid #D8D8D8;
    }

    &:nth-child(2) {
      display: none;
      transform: scaleY(0);
      visibility: hidden;
    }

    &--show:nth-child(2) {
      display: block;
      transform: scaleY(1);
      transform-origin: top;
      visibility: visible;
      transition: all ease-in-out 0.1s;
    }
  }

  #show-all {
    display: block;
    text-align: center;
    text-decoration: underline;
    cursor: pointer;
  }
}
