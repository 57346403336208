.authors.show {
  .author {
    &-container {
      padding: $author-container__padding;
      background-color: $author-container__background-color;

      @media #{$sm} {
        padding: $author-container__padding--mobile;
      }
    }

    &-content {
      padding: $author-content__padding;

      @media #{$sm} {
        padding: $author-content__padding--mobile;
      }
    }

    &-supervision {
      padding: $author-supervision__padding;

      @media #{$sm} {
        padding: $author-supervision__padding--mobile;
      }

      &__section {
        padding: $author-supervision-section__padding;
        border: $author-supervision-section__border;
        border-radius: $author-supervision-section__border-radius;

        @media #{$sm} {
          padding: $author-supervision-section__padding--mobile;
        }
      }

      &__grid {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
      }

      &__item {
        padding: $author-supervision-item__padding;
      }

      &__logo {
        border: $author-supervision-logo__border;
        border-radius: $author-supervision-logo__border-radius;

        &:hover {
          box-shadow: $author-supervision-logo--hover__box-shadow;
        }
      }
    }

    &__section {
      display: grid;
      grid-column-gap: $author-section__gap;
      grid-template-areas: $author-section__areas;
      grid-template-columns: $author-section__columns;

      @media #{$sm} {
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      &__section {
        padding: $author-content-section__padding;
        border: $author-content-section__border;
        border-radius: $author-content-section__border-radius;

        @media #{$sm} {
          padding: $author-content-section__padding--mobile;
        }
      }
    }

    &-additional {
      padding: $author-additional__padding;
      text-align: $author-additional__text-align;

      @media #{$sm} {
        padding: $author-additional__padding--mobile;
      }

      a {
        color: $author-additional-link__color;
        font-size: $author-additional-link__font-size;
        line-height: $author-additional-link__line-height;
        text-transform: $author-additional-link__text-transform;
      }
    }

    &-footer {
      padding: $author-footer__padding;

      @media #{$sm} {
        padding: $author-footer__padding--mobile;
      }
    }

    &__image {
      grid-area: img;
      border: $author-image__border;
      border-radius: $author-image__border-radius;
      box-shadow: $author-image__box-shadow;
    }

    &__name {
      grid-area: name;
      margin: $author-name__margin;
      font-size: $author-name__font-size;
      line-height: $author-name__line-height;

      @media #{$sm} {
        font-size: $author-name__font-size--mobile;
        line-height: $author-name__line-height--mobile;
      }
    }

    &__job {
      grid-area: job;
      margin: $author-job__margin;
      font-size: $author-job__font-size;
      line-height: $author-job__line-height;

      @media #{$sm} {
        font-size: $author-job__font-size--mobile;
        line-height: $author-job__line-height--mobile;
      }
    }

    &__description {
      grid-area: desc;
    }

    &-info__description {
      display: block;
      grid-area: info-desc;
    }
  }
}

.author {
  &-supervision__title,
  &-content__title {
    display: flex;
    align-items: center;
    margin: $author-titles__margin;
    color: $author-titles__color;
    font-weight: $author-titles__font-weight;
    font-size: $author-titles__font-size;
    line-height: $author-titles__line-height;

    @media #{$sm} {
      font-size: $author-titles__font-size--mobile;
      line-height: $author-titles__line-height--mobile;
    }

    &::before {
      display: inline-block;
      width: $author-titles--before__width;
      height: $author-titles--before__height;
      margin: $author-titles--before__margin;
      background-color: $author-titles--before__bg-color;
      border-radius: $author-titles--before__border;
      content: $author-titles--before__content;
    }
  }

  &__description,
  &-content__description,
  &-content__section p {
    font-size: $author-descriptions__font-size;
    font-family: $author-descriptions__font-family;
    line-height: $author-descriptions__line-height;

    @media #{$sm} {
      font-size: $author-descriptions__font-size--mobile;
      line-height: $author-descriptions__line-height--mobile;
    }
  }
}
