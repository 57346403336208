.premium-campaign-slider {
  position: relative;
  color: #FFF;

  &__container {
    display: flex;
    width: 100%;
    scroll-snap-type: x mandatory;
    overflow-x: scroll;
    overflow-y: hidden;
    scroll-behavior: smooth;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__arrow {
    position: absolute;
    top: 0;
    right: 0;
    width: 90px;
    height: calc(100% - 27px);
    cursor: pointer;

    @media #{$md} {
      display: none;
    }

    &::before {
      position: absolute;
      top: 50%;
      left: 29px;
      display: block;
      width: 32px;
      height: 32px;
      background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M20.952 16.3806C21.3426 15.9901 21.3426 15.3569 20.952 14.9664L14.5881 8.6024C14.1975 8.21188 13.5644 8.21188 13.1739 8.6024C12.7833 8.99293 12.7833 9.62609 13.1739 10.0166L18.8307 15.6735L13.1739 21.3303C12.7833 21.7208 12.7833 22.354 13.1739 22.7445C13.5644 23.1351 14.1975 23.1351 14.5881 22.7445L20.952 16.3806ZM19.9184 16.6735H20.2449V14.6735H19.9184V16.6735Z' fill='white'/%3E%3Ccircle r='15' transform='matrix(-1 0 0 1 16 16)' stroke='white' stroke-width='2'/%3E%3C/svg%3E%0A");
      background-repeat: no-repeat;
      transform: translateY(-50%);
      content: " ";
    }

    &--left {
      left: 40px;
      transform: translate(-50%) rotate(180deg);
    }

    &:hover,
    &:focus {
      background: rgba(0, 0, 0, 0.48);
    }

    &:active {
      background: transparent;
    }
  }

  &__item {
    position: relative;
    width: 100%;
    min-width: 100%;
    padding-bottom: 36px;
    text-align: center;
    background-repeat: no-repeat;
    background-size: cover;
    scroll-snap-align: center;

    & > * {
      position: relative;
    }

    &::before {
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.3);
      content: "";
    }
  }

  &__headline {
    margin: 15px 3px 10px;
    font-weight: 700;
    font-size: 22px;
    font-style: normal;
    line-height: 27px;

    @media #{$sm} {
      font-size: 18px;
      line-height: 22px;
    }
  }

  &__description {
    margin: 0 3px;
    font-weight: 400;
    font-size: 16px;
    font-style: normal;
    line-height: 19px;
  }

  &__logo {
    display: inline-block;
    width: 125px;
    height: 67px;
    margin-top: 23px;
    background-color: #FFF;
    border-radius: 8px;

    @media #{$sm} {
      width: 46px;
      height: 46px;
    }
  }

  &__img {
    max-height: 100%;
    margin: auto;
  }

  &__button {
    display: inline-block;
    margin-top: 23px;
    padding: 6px 14px;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-decoration: none;
    background-color: $slider-btn-bg;
    border-radius: 50px;

    span {
      color: #FFF;
    }

    &:hover {
      text-decoration: none;
      background-color: $slider-btn-bg;
    }
  }

  &__indicator-list {
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 0;
  }

  &__indicator-item {
    justify-content: center;
    width: 40px;
    height: 3px;
    margin: 12px 7px;
    list-style-type: none;
    background-color: #D9D9D9;
    scroll-snap-align: center;
    cursor: pointer;

    &--active {
      background-color: #1A3649;
    }
  }
}
