.multipurpose-seo {
  $this: &;

  margin: 20px 0;
  padding: 0 15px;

  &__main {
    &-title,
    &-subtitle {
      z-index: 1;
      color: $color-black;
    }

    &-title {
      font-weight: 500;
      font-size: 32px;
      line-height: 1.2;
    }

    &-subtitle {
      font-weight: 400;
      font-size: 18px;
      line-height: 1.2;
    }
  }

  &__title {
    width: 100%;
    margin: 15px 0 12px;
    padding: 0 15px;
    font-weight: 700;
    font-size: 20px;
    line-height: 1.2;
  }

  &__headline {
    margin: 11px 0 8px;
    padding: 0 10px;
    font-weight: 300;
    font-size: 18px;
    line-height: 1;
    text-transform: uppercase;
  }

  &__text {
    margin-bottom: 15px;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.2;

    a {
      color: $multipurpose-text-a__color;
      font-weight: $multipurpose-text-a__font-weight;
      font-size: $multipurpose-text-a__font-size;
      font-family: $multipurpose-text-a__font-family;
      text-decoration: $multipurpose-text-a__text-decoration;
      border-bottom: $multipurpose-text-a__border-bottom;

      &:hover {
        color: $multipurpose-text-a--hover__color;
        text-decoration: $multipurpose-text-a--hover__text-decoration;
        border-bottom: $multipurpose-text-a--hover__border-bottom;
      }

      @media #{$sm} {
        font-size: $multipurpose-text-a__font-size--mobile;
      }
    }

    strong,
    b {
      color: $multipurpose-text-b__color;
      font-weight: $multipurpose-text-b__font-weight;
      font-family: $multipurpose-text-b__font-family;
    }

    u {
      text-decoration: $multipurpose-text-u__text-decoration;
      border-bottom: $multipurpose-text-u__border-bottom;
    }
  }

  &__widget {
    margin-bottom: 10px;

    &--33 {
      @media #{$sm} {
        width: 100%;
      }

      #{$this}__wrap {
        border: 1px solid $color-black;
        border-radius: 7px;
      }

      #{$this}__img {
        height: 186px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        border-width: 0 0 1px;
        border-radius: 7px 7px 0 0;

        @media #{$sm} {
          height: 264px;
        }
      }

      #{$this}__text {
        display: -webkit-box;
        padding: 0 10px;
        overflow: hidden;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 5;
      }
    }

    &--100-main-hero {
      display: grid;
      align-content: center;
      justify-items: center;
      height: 182px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      border-bottom: 3px solid $color-white;
    }

    &--50-img,
    &--25-img {
      height: 224px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;

      @media #{$sm} {
        height: 212px;
      }
    }

    &--opacity {
      &-black,
      &-white {
        position: relative;

        &::before {
          position: absolute;
          top: 0;
          left: 0;
          display: block;
          width: 100%;
          height: 100%;
          content: "";
        }
      }

      &-black {
        &::before {
          background: rgba(0, 0, 0, 0.3);
        }

        #{$this}__main {
          &-title,
          &-subtitle {
            color: $color-white;
          }
        }
      }

      &-white {
        &::before {
          background: rgba(255, 255, 255, 0.3);
        }

        #{$this}__main {
          &-title,
          &-subtitle {
            color: $color-black;
          }
        }
      }
    }
  }

  div[data-coupon-url] {
    cursor: pointer;
  }
}
