.pannacotta {
  &.card-back-to {
    border: none;

    .card-content {
      a {
        display: block;
        padding: 25px;
        color: #FFF;
        font-weight: bold;
        font-size: 14px;
        text-align: center;
        text-transform: uppercase;
        background-color: $color-sky;
        border: 1px solid $color-sky;
        border-radius: 2px;
        transition: background-color 0.3s ease, border-color 0.3s ease;

        &:focus,
        &:hover {
          color: #FFF;
          text-decoration: none;
          background-color: lighten($color-sky, 10%);
          border-color: lighten($color-sky, 10%);
        }
      }
    }
  }
}
