.sem-header__logo {
  max-width: 142px;
  margin: 40px 0 0 -2px;
}

.sem-header {
  height: 100px;
}

.sem-campaign {
  margin-top: 0;
  text-align: center;

  &__logo {
    max-width: 240px;

    @media #{$sm} {
      max-width: 100px;
    }
  }

  &__header {
    margin: 23px 0 0;
    color: #5C6469;
    font-weight: 700;
    font-size: 48px;
  }

  &__subheader {
    margin: 8px 0 0;
    color: #FFF;
    font-weight: 300;
    font-size: 32px;
  }
}

.sem-coupons {
  margin: 0 0 60px;

  .amount {
    display: block;
    margin: 0;
    font-size: 30px;
  }

  .type {
    display: block;
    margin: 0;
    margin-bottom: 15px;
    font-size: 30px;
  }

  .coupon__description,
  .coupon__toggle {
    display: none;
  }
}

.coupon-tag {
  position: absolute;
  top: 0;
  right: 0;
}

.card-content {
  .fa {
    display: none;
  }

  .show-more {
    position: relative;
    padding: 0 10px;
    text-transform: uppercase;
    background-color: #E60000;
    border-radius: 3px;
    box-shadow: inset -3px -3px 0 rgba(0, 0, 0, 0.2);
    cursor: pointer;
    transition: background-color 0.5s;

    &:hover,
    &:active {
      background-color: #B30000;

      span {
        text-decoration: none;
      }
    }

    span {
      color: #FFF;
      font-weight: 700;
      font-size: 11px;
      line-height: 40px;
      white-space: nowrap;
    }
  }
}

@media #{$sm} {
  .sem-campaign {
    margin-top: 0;

    &__logo {
      margin-top: 20px;
    }
  }

  .sem-coupons {
    margin: 30px 0;
  }
}
