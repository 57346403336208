.pannacotta {
  .border-ashes {
    border: 1px solid $color-ashes;
  }

  .border-berry {
    border: 1px solid $color-berry;
  }

  .border-cloud {
    border: 1px solid $color-cloud;
  }

  .border-cherry {
    border: 1px solid $color-cherry;
  }

  .border-freedom {
    border: 1px solid $color-freedom;
  }

  .border-ready {
    border: 1px solid $color-ready;
  }

  .border-royal {
    border: 1px solid $color-royal;
  }

  .border-sky {
    border: 1px solid $color-sky;
  }

  .border-top-ashes {
    border-top: 1px solid $color-ashes;
  }

  .border-top-berry {
    border-top: 1px solid $color-berry;
  }

  .border-top-cloud {
    border-top: 1px solid $color-cloud;
  }

  .border-top-cherry {
    border-top: 1px solid $color-cherry;
  }

  .border-top-freedom {
    border-top: 1px solid $color-freedom;
  }

  .border-top-ready {
    border-top: 1px solid $color-ready;
  }

  .border-top-royal {
    border-top: 1px solid $color-royal;
  }

  .border-top-sky {
    border-top: 1px solid $color-sky;
  }

  .border-right-ashes {
    border-right: 1px solid $color-ashes;
  }

  .border-right-berry {
    border-right: 1px solid $color-berry;
  }

  .border-right-cloud {
    border-right: 1px solid $color-cloud;
  }

  .border-right-cherry {
    border-right: 1px solid $color-cherry;
  }

  .border-right-freedom {
    border-right: 1px solid $color-freedom;
  }

  .border-right-ready {
    border-right: 1px solid $color-ready;
  }

  .border-right-royal {
    border-right: 1px solid $color-royal;
  }

  .border-right-sky {
    border-right: 1px solid $color-sky;
  }

  .border-bottom-ashes {
    border-bottom: 1px solid $color-ashes;
  }

  .border-bottom-berry {
    border-bottom: 1px solid $color-berry;
  }

  .border-bottom-cloud {
    border-bottom: 1px solid $color-cloud;
  }

  .border-bottom-cherry {
    border-bottom: 1px solid $color-cherry;
  }

  .border-bottom-freedom {
    border-bottom: 1px solid $color-freedom;
  }

  .border-bottom-ready {
    border-bottom: 1px solid $color-ready;
  }

  .border-bottom-royal {
    border-bottom: 1px solid $color-royal;
  }

  .border-bottom-sky {
    border-bottom: 1px solid $color-sky;
  }

  .border-left-ashes {
    border-left: 1px solid $color-ashes;
  }

  .border-left-berry {
    border-left: 1px solid $color-berry;
  }

  .border-left-cloud {
    border-left: 1px solid $color-cloud;
  }

  .border-left-cherry {
    border-left: 1px solid $color-cherry;
  }

  .border-left-freedom {
    border-left: 1px solid $color-freedom;
  }

  .border-left-ready {
    border-left: 1px solid $color-ready;
  }

  .border-left-royal {
    border-left: 1px solid $color-royal;
  }

  .border-left-sky {
    border-left: 1px solid $color-sky;
  }
}
