.btn {
  &.active,
  &:active {
    box-shadow: none;
  }

  &:focus,
  &:active:focus,
  &.active:focus {
    outline: none;
  }
}

.pannacotta {
  .btn-ashes,
  .btn-berry,
  .btn-cloud,
  .btn-cherry,
  .btn-freedom,
  .btn-ready,
  .btn-royal,
  .btn-sky,
  .btn-transparent,
  .btn-white {
    padding: 13px 24px;
    font-weight: 600;
    font-size: 14px;
    border-radius: 0;
    transition: background-color 0.3s ease;

    &:focus,
    &:hover {
      text-decoration: none;
    }

    &:focus {
      outline: none;
    }
  }

  .btn-ashes {
    @include flat-button($color-ashes);
  }

  .btn-berry {
    @include flat-button($color-berry);
  }

  .btn-cloud {
    @include flat-button($color-cloud, $color-text);
  }

  .btn-cherry {
    @include flat-button($color-cherry);
  }

  .btn-freedom {
    @include flat-button($color-freedom);
  }

  .btn-ready {
    @include flat-button($color-ready, #FFF, 30%);
  }

  .btn-royal {
    @include flat-button($color-royal);
  }

  .btn-sky {
    @include flat-button($color-sky);
  }

  .btn-transparent {
    @include flat-button(rgba(255, 255, 255, 0), $color-border);
  }

  .btn-white {
    @include flat-button(#fff, $color-text);
  }

  .btn-no-border {
    padding: 12px 24px;
    border-bottom: none;
  }

  .btn-no-padding {
    padding: 0 !important;
  }

  .btn-squared {
    padding: 10px !important;
  }

  .btn-small {
    padding: 9px 16px;
  }

  .btn-seamless {
    margin-left: -2px;
    border: 0;
    border-radius: 0 4px 4px 0;
  }
}

@media (min-width: 992px) {
  .pannacotta {
    .btn-no-border {
      padding: 16px 24px;
    }
  }
}
