.sidebar {
  h2,
  h3 {
    margin-top: 24px;
    margin-bottom: 8px;
    font-size: 16px;
  }

  p,
  li {
    margin-bottom: 8px;
  }

  p {
    font-size: 12px;
  }

  .row > .col-xs-4 {
    padding-right: 5px;
    padding-left: 5px;
  }

  p.sidebar__labels {
    font-size: 85%;
    line-height: 2;
    word-spacing: 2px;

    span:not(:last-of-type)::after {
      content: ", ";
    }
  }
}

.sidebar--image-container {
  margin-bottom: 30px;

  div,
  img,
  iframe {
    width: 100%;
    max-width: 100%;
  }
}

.sidebar--image-tag {
  width: 100%;
  max-width: 100%;
}

@media (min-width: 768px) {
  .sidebar {
    h2,
    h3 {
      margin-top: 32px;
      margin-bottom: 12px;
      font-size: 20px;
    }

    p {
      font-size: 14px;
    }
  }
}

.rss-widget {
  &__main-title {
    color: $rss-main-title__color;
    font-size: $rss-main-title__font-size;
    line-height: $rss-main-title__line-height;
  }

  &__item {
    position: relative;
    margin: $rss-item__margin;
    padding: $rss-item__padding;
    background: $rss-item__background;
    border: $rss-item__border;

    &::before {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: 12px;
      height: 100%;
      background: $rss-item-before__background;
      content: "";
    }
  }

  &__title {
    display: $rss-title__display;
    color: $rss-title__color;
    font-size: $rss-title__font-size;
    line-height: $rss-title__line-height;
  }

  &__category {
    display: $rss-category__display;
    color: $rss-category__color;
    font-size: $rss-category__font-size;
    line-height: $rss-category__line-height;
  }

  &__pubdate {
    display: $rss-pubdate__display;
    color: $rss-pubdate__color;
    font-size: $rss-pubdate__font-size;
    line-height: $rss-pubdate__line-height;
  }

  &__author {
    display: $rss-author__display;
    color: $rss-author__color;
    font-size: $rss-author__font-size;
    line-height: $rss-author__line-height;

    a {
      font-weight: $rss-author-a__font-weight;
    }
  }

  &__description {
    display: $rss-description__display;
    overflow: hidden;
    color: $rss-description__color;
    font-size: $rss-description__font-size;
    line-height: $rss-description__line-height;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: $rss-description__webkit-line-clamp;
  }
}

.badge__banner,
.tested__banner {
  float: left;
  width: 100%;
  padding: 15px 15px 0;

  &--image-tag {
    width: 100%;
  }
}
