#header_image {
  &.hero {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;

    @media (max-width: 767px) {
      height: auto;
    }

    h1 {
      margin-top: 0;
      font-weight: 700;
      font-size: 2.25rem;
    }

    h2,
    .countdown-time {
      font-weight: 500;
      font-size: 2rem;
    }

    #countdown {
      margin-top: 20px;

      &.countdown {
        &--position {
          &-left {
            margin-right: auto;
          }

          &-right {
            margin-left: auto;
          }

          &-center {
            margin: 0 auto;
          }
        }
      }

      td {
        padding-right: 0.5rem;
        padding-left: 0.5rem;
      }
    }

    .countdown-labels {
      opacity: 0.5;
    }

    @media (min-width: 768px) {
      h1 {
        font-size: 3.5rem;
      }

      h2,
      .countdown-time {
        font-size: 2.75rem;
      }
    }
  }
}
