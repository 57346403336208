.pannacotta.related-items {
  margin-bottom: 0;

  #sidebar & {
    margin-bottom: 30px;
  }

  .label.bg-ashes {
    display: inline-block;
    margin-right: 3px;
    margin-bottom: 5px;
    line-height: 18px;
    transition: background-color 0.3s ease;

    &.active {
      color: $color-ashes;
      background: white !important;

      &:hover,
      &:active {
        color: $color-ashes;
        background: white !important;
      }
    }

    a {
      color: #FFF;

      &:hover {
        text-decoration: none;
        cursor: pointer;
      }
    }

    &:hover,
    &:active {
      text-decoration: none;
      background-color: lighten($color-ashes, 5%);
      cursor: pointer;
    }
  }
}
