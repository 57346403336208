* {
  box-sizing: border-box;
}

.list-circle {
  position: relative;
  top: -2px;
  display: inline-block;
  width: 6px;
  height: 6px;
  margin: 0 10px 0 0;
  color: transparent;
  border: 2px solid $color-ready;
  border-radius: 3px;
}

.text-lighter {
  font-weight: lighter;
}

a {
  color: $color-url;

  &:focus,
  &:hover {
    color: darken($color-url, 20%);
  }
}

/*** Flexslider ***/

html[xmlns] .slides {
  display: block;
}

* html .slides {
  height: 1%;
}

@media (min-width: 768px) {
  .list-three-cols {
    > li {
      display: inline-block;
      width: 49%;
    }
  }

  .list-two-cols {
    > li {
      display: inline-block;
      width: 49%;
    }
  }
}

@media (min-width: 992px) {
  .list-three-cols {
    > li {
      width: 32%;
    }
  }
}
