.pannacotta {
  &.more-from-this-shop,
  &.guarantee,
  &.blog-posts {
    ul li a {
      @include flat-anchor($color-sky);
    }
  }

  &.more-from-this-shop,
  &.guarantee {
    @media (max-width: 991px) {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 10px;
    }
  }

  &.guarantee {
    ul {
      @media (max-width: 991px) {
        width: fit-content;
        margin-inline: auto;
      }
    }
  }
}
