$logo-green-base: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='none'%3E%3Ccircle cx='50' cy='50' r='50' fill='%236A7256'/%3E%3Cpath fill='%23DAD5CF' fill-rule='evenodd' d='M65.385 80.974C76.76 75.313 84.577 63.569 84.577 50c0-19.096-15.48-34.577-34.577-34.577-19.096 0-34.577 15.48-34.577 34.577 0 13.76 8.037 25.642 19.673 31.209v1.105C22.892 76.676 14.423 64.327 14.423 50c0-19.649 15.928-35.577 35.577-35.577 19.649 0 35.577 15.928 35.577 35.577 0 14.138-8.247 26.35-20.192 32.087v-1.113Z' clip-rule='evenodd'/%3E%3Cpath fill='%23DAD5CF' fill-rule='evenodd' d='M52.367 44.617c5.782-3.133 14.766-13.816 11.447-36.01-15.688 15.203-15.115 29.224-12.4 35.67-1.094 5.864-3.14 13.46-6.387 20.915a77.056 77.056 0 0 1-3.53 7.115c2.178-5.83 2.53-17.145-8.097-31.015-4.74 19.26 2.081 29.778 6.787 33.233-3.672 5.943-8.277 11.185-13.958 14.593l.515.857c8.791-5.273 15.003-14.748 19.2-24.384a94.708 94.708 0 0 0 3.097-8.144c5.033 2.3 17.821 3.056 32.164-11.087-19.483-2.283-29.076 5.589-32.035 10.695a101.567 101.567 0 0 0 3.197-12.438Z' clip-rule='evenodd'/%3E%3C/svg%3E");

.shops.show {
  .coupon--editors-pick {
    .coupon__exclusive {
      top: $coupon-exclusive-editors-pick__top;

      @media #{$md} {
        top: $coupon-exclusive-editors-pick__top--mobile;
      }
    }
  }
}

.coupon {
  &__description {
    grid-area: desc;
    margin-top: 8px;
    padding: 0 12px;
    color: $color-subheadline;
    font-weight: lighter;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    cursor: default;

    &--small {
      display: -webkit-box;
      max-height: 32px;
      -webkit-line-clamp: 2;
      overflow: hidden;
      -webkit-box-orient: vertical;

      table {
        display: none;
      }
    }

    ul {
      padding: 0;
    }
  }

  &__table {
    display: grid;
    grid-template-columns: 2fr 4fr;
    margin: 36px 0 0;
    text-align: center;

    @media #{$min-sm} {
      text-align: left;
    }

    @media #{$xs} {
      grid-template-columns: 1fr 2fr;
      margin: 16px 0;
    }

    .key {
      margin-right: 3px;
      font-weight: 700;
    }
  }
}

.coupon-list--item {
  display: none;
}

.coupons {
  $this: ".coupon";

  &__list {
    &--filtered {
      display: grid;
    }
  }

  &__item { // ordinary coupons; EP coupons unaffected
    position: relative;
    display: grid;
    grid-template: $coupons-item__grid-template;
    width: 100%;
    max-width: 100%;
    margin-bottom: 6px;
    padding: 0 12px;
    overflow: hidden;
    background-color: $color-white;
    border-radius: $coupons-item__border-radius;
    box-shadow: inset 0 0 0 1px $color-border;

    @media #{$sm} {
      grid-template: $coupons-item__grid-template--small;
    }

    &::after {
      position: absolute;
      bottom: -9px;
      left: 0;
      z-index: 0;
      grid-area: dots;
      width: 100%;
      height: 11px;
      /* stylelint-disable-next-line max-line-length */
      background: radial-gradient(ellipse at center, $color-body-bg 31%, $color-border 32%, $color-border 40%, $color-body-bg 40%, transparent 41%, transparent 41%);
      background-size: 13px 13px;
      content: $coupons-item-after__content;
    }

    &--highlighted {
      box-shadow: 0 0 10px 4px transparentize($color-ready, 0.6);
    }

    &--esdo {
      .coupon__banner {
        &::after {
          border-color: transparent $esdo-color transparent transparent;
        }
      }
    }

    &--info-widget {
      background: $color-info-widget;

      #{$this}__description {
        color: darken($color-subheadline, 20%);
      }
    }

    &--gift-card {
      .coupon {
        &__logo {
          img {
            width: 43px;
            height: 45px;
            min-height: auto;
            margin: 6px auto;
          }

          .amount,
          .type {
            font-weight: 400;
            font-size: 20px;
            line-height: 24px;
            letter-spacing: normal;
            text-transform: capitalize;

            @media #{$sm} {
              font-size: 12px;
              line-height: 14px;
            }
          }
        }

        &__table {
          &--gift-card {
            grid-template-columns: auto;
            padding-left: 20px;

            ul {
              list-style-type: disc;
            }
          }
        }
      }
    }
  }
}

.coupon {
  $this: &;

  &__special-label {
    display: none;
  }

  &__sdo {
    display: flex;
    grid-area: logo;
    align-items: center;
    justify-content: center;
    min-width: 136px;
    padding: 12px;
    text-align: center;
  }

  &__logo {
    display: grid;
    grid-area: logo;
    align-content: center;
    min-width: 136px;
    margin: 10px 0;
    color: inherit;
    text-align: center;

    &--green {
      display: block;
      width: 80px;
      height: 80px;
      margin: auto;
      background-image: $logo-green-base;
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      content: "";

      @media #{$sm} {
        width: 65px;
        height: 65px;
        margin: 10px auto;
      }
    }

    &--esdo {
      margin: 0 -3px 0 0;
      padding: 10px 0;
      color: $color-white;
      background: $esdo-color;
    }

    &:focus,
    &:hover {
      text-decoration: none;
    }

    &--for-shops {
      & + div {
        margin-top: -20px;
        margin-bottom: 12px;
      }

      img {
        border: none;
      }
    }

    img {
      margin: 10px auto;
      border: 1px solid $color-subheadline;
    }

    span {
      display: block;
      font-size: 24px;

      &.type {
        letter-spacing: 2px;
        text-transform: uppercase;
      }
    }
  }

  &__dots {
    grid-area: dots;

    &::before {
      position: absolute;
      bottom: -16px;
      left: -23px;
      z-index: 1;
      display: block;
      grid-area: dots;
      width: 24px;
      height: 24px;
      background-color: $color-body-bg;
      border: 1px solid $color-border;
      border-radius: 100%;
      content: "";
    }

    &::after {
      position: absolute;
      right: -23px;
      bottom: -16px;
      z-index: 1;
      display: block;
      grid-area: dots;
      width: 24px;
      height: 24px;
      background-color: $color-body-bg;
      border: 1px solid $color-border;
      border-radius: 100%;
      content: "";
    }
  }

  &__title {
    display: -webkit-box;
    grid-area: title;
    margin: 15px 0 8px;
    padding: 0 12px;
    overflow: hidden;
    color: $color-title;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    cursor: pointer;
  }

  &__toggle {
    grid-area: toggle;
    margin: 8px 0 10px;
    padding: 0 12px;
    color: $color-url;
    text-align: center;
    cursor: pointer;

    i,
    span {
      pointer-events: none;
    }

    &:focus {
      text-decoration: none;
    }

    &:hover,
    &:active {
      text-decoration: none;

      span {
        text-decoration: underline;
      }
    }
  }

  &__exclusive {
    grid-area: exclusive;
    margin: 0 auto 20px;

    @media #{$sm} {
      text-align: center;
    }
  }

  &__expires,
  &__clicks,
  &__label-code,
  &__editors-pick {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 5px;
    margin-bottom: 10px;
    font-size: 12px;
  }

  &__clicks {
    grid-area: clicks;
  }

  &__verified {
    grid-area: verified;
    padding: $coupon-verified__padding;
    color: $coupon-verified__color;
    font-weight: $coupon-verified__font-weight;
    font-size: $coupon-verified__font-size;
    line-height: $coupon-verified__line-height;
    background-color: $coupon-verified__bg-color;

    &::before {
      position: $coupon-verified-before__position;
      top: $coupon-verified-before__top;
      margin: $coupon-verified-before__margin;
      content: $coupon-verified-before__content;
    }
  }

  &__expires {
    display: flex;
    grid-area: expire;
    align-items: center;

    &-text {
      font-size: 12px;
      line-height: 18px;
    }
  }

  &__editors-pick {
    grid-area: editors-pick;
    white-space: nowrap;
    text-align: right;
  }

  &__label-code {
    display: none;
    grid-area: label-code;
  }

  &__more-details {
    grid-area: toggle;
    margin-bottom: 20px;
    color: #07C;
    font-size: 12px;
    text-align: center;
    cursor: pointer;

    span {
      margin-right: 20px;
      margin-bottom: 20px;
    }
  }

  &__footer {
    display: grid;
    grid-area: footer;
    align-items: center;
    justify-content: center;
    padding: 20px 0;
    text-align: center;
  }

  &__info {
    margin: 20px auto;
    text-align: left;

    tr {
      td {
        vertical-align: top;
      }

      td:first-child {
        padding-right: 10px;
      }
    }
  }

  .label-code,
  .label-exclusive {
    display: none;
  }

  &__esdo {
    &-line1 {
      width: 100%;
      margin-top: 0;
      text-align: center;
    }

    &-nhs {
      width: 100%;
      margin-bottom: 0;
      font-weight: 600;
      font-size: 36px;
      line-height: 32px;
      text-align: center;
    }
  }

  &__sdo {
    @media (max-width: 768px) {
      text-align: center;
    }

    p {
      margin: 5px 0 0;
    }
  }
}

.coupon {
  $this: ".coupon";

  &--editors-pick {
    position: relative;
    display: grid;
    grid-template: $coupon-editors-pick__grid-template-areas;
    grid-template-rows: $coupon-editors-pick__grid-template-rows;
    grid-template-columns: $coupon-editors-pick__grid-template-columns;
    min-height: 170px;
    margin-bottom: 15px;
    overflow: hidden;
    background-color: $coupon-editors-pick-box-background-color;
    border: 1px solid $color-ep-label;
    border-radius: $coupons-item__border-radius;
    box-shadow: $coupon-editors-pick-box-shadow;

    @media #{$sm} {
      grid-template: $coupon-editors-pick__grid-template-areas--mobile;
      grid-template-rows: $coupon-editors-pick__grid-template-rows--mobile;
      grid-template-columns: $coupon-editors-pick__grid-template-columns--mobile;
    }

    &#{$this}--exclusive {
      .coupon__tag {
        @media (max-width: 390px) {
          margin: auto 8px auto auto;
        }
      }
    }

    #{$this}__description,
    #{$this}__footer,
    #{$this}__logo,
    #{$this}__logo * {
      margin: 0;
      padding: 0;
    }

    #{$this}__expires,
    #{$this}__clicks {
      min-width: 100px;
      height: min-content;
      white-space: wrap;
    }

    #{$this}__expires {
      grid-area: expire;
      margin: 8px 5px 8px 0;

      &-counts {
        margin: 0;
      }
    }

    #{$this}__clicks {
      grid-area: clicks;
      margin: 8px 5px 8px 0;
    }

    #{$this}__label--ep {
      display: flex;
      grid-area: label;
      justify-content: center;
      justify-self: center;
      width: 100%;
      height: $coupon-editors-pick-label-ep__height;
      padding: 0;
      background: $color-ep-label;

      #{$this}__tag {
        height: fit-content;
        color: $color-white;
        line-height: $coupon-editors-pick-label-ep__height;
        text-align: center;
        text-transform: uppercase;
        vertical-align: middle;

        &::before,
        &::after {
          @include editors-pick-icon;
        }
      }
    }

    #{$this}__title {
      grid-area: title;
      align-self: start;
      height: fit-content;
      min-height: fit-content;
      margin: 15px 0;
      text-align: start;

      @media #{$sm} {
        padding-right: 10px;
      }
    }

    #{$this}__description {
      grid-area: desc;
      padding: 15px 10px;

      @media #{$sm} {
        padding: 10px;
      }

      &--small {
        display: none;
      }

      &-content p {
        margin: 0;
      }
    }

    #{$this}__logo {
      display: flex;
      flex-direction: column;
      grid-area: logo;
      align-self: start;
      justify-content: center;
      width: $coupon-editors-pick-logo__width;
      min-width: unset;
      max-width: $coupon-editors-pick-logo__width;
      height: 100%;
      padding-bottom: 10px;
      overflow: hidden;
      color: $color-ep-label;
      font-weight: bold;

      @media #{$min-md} {
        height: calc(100% + 35px);
        padding-bottom: 35px;
        background-color: rgba($color-cta, 0.1);
      }

      @media #{$sm} {
        width: $coupon-editors-pick-logo__width--mobile;
        max-width: $coupon-editors-pick-logo__width--mobile;
      }

      .amount {
        font-size: $coupon-editors-pick-logo-large-text__font-size;
        line-height: normal;

        @media #{$sm} {
          font-size: $coupon-editors-pick-logo-large-text__font-size--mobile;
          line-height: 1;
        }
      }

      .type {
        font-size: $coupon-editors-pick-logo-subtext__font-size;
        line-height: 1.2;

        @media #{$sm} {
          font-size: $coupon-editors-pick-logo-subtext__font-size--mobile;
        }
      }

      &--for-shops {
        & + div {
          top: 0;
          margin-top: 0;
        }

        .coupon__banner {
          margin: auto 5px;

          @media #{$sm} {
            margin: auto 5px auto 10px;
          }
        }
      }

      > * {
        width: $coupon-editors-pick-logo__width;
        padding-inline: 5px;

        @media #{$sm} {
          width: $coupon-editors-pick-logo__width--mobile;
        }
      }
    }

    #{$this}__label-code {
      display: none;
      grid-area: label-code;
      margin: 0 auto;
      line-height: 1;
      text-align: center;
    }

    #{$this}__footer {
      display: inline-block;
      grid-area: footer;
      margin: auto 0;
      padding-right: 30px;

      @media #{$sm} {
        align-self: end;
        width: 100%;
        max-width: 100%;
        margin: 0 0 15px;
        padding-right: 20px;
      }

      #{$this}__button {
        grid-area: footer;
        margin: 0;

        @media #{$sm} {
          width: 100%;
          max-width: 100%;
        }
      }
    }

    #{$this}__toggle {
      grid-area: toggle;
      height: fit-content;
      margin-top: 0;
      padding-bottom: 5px;

      @media #{$sm} {
        padding: $coupon-editors-pick-toggle__padding--mobile;
      }
    }

    #{$this}__dots {
      display: none;
      grid-area: dots;
    }

    #{$this}__verified {
      padding-left: 5px;
    }
  }
}

@media #{$min-sm} {
  .coupons {
    &__item {
      min-height: 160px;
      padding: 0;

      &::after {
        position: absolute;
        right: 19px;
        bottom: auto;
        left: auto;
        z-index: 0;
        grid-area: dots;
        width: 11px;
        height: 100%;

        /* stylelint-disable-next-line max-line-length */
        background: radial-gradient(ellipse at center, $color-body-bg 31%, $color-border 32%, $color-border 40%, $color-body-bg 40%, transparent 41%, transparent 41%);
        background-size: 13px 13px;
        content: " ";
      }
    }
  }

  .coupon {
    $this: &;

    &__logo {
      &--for-shops + div {
        margin-top: 4px;
        margin-bottom: 0;
      }

      .amount {
        display: block;
        font-size: 30px;
      }

      .type {
        margin: 0;
        font-size: 14px;
      }

      .mount,
      .type {
        width: 100%;
      }

      &--for-shops {
        img {
          width: 110px;
          height: auto;
          border: none;
        }
      }
    }

    &__sdo {
      padding: 0 12px;
    }

    &__header {
      &--esdo {
        margin: 0 23px 0 0;
      }
    }

    &__dots {
      margin-right: 26px;

      &::before {
        top: -12px;
        right: 11px;
        left: auto;
        grid-area: dots;
        content: " ";
      }

      &::after {
        right: 11px;
        bottom: -12px;
        grid-area: dots;
        content: " ";
      }
    }

    &__title {
      margin: 12px 0 0;
      overflow: hidden;
      font-size: 18px;
      text-align: left;

      #{$this}__exclusive {
        margin: 0;
      }
    }

    &__description {
      margin: 10px 0 0;
      font-size: 13px;
      line-height: 18px;
      text-align: left;

      &--small {
        max-height: 36px;
      }
    }

    &__table {
      text-align: left;
    }

    &__toggle {
      margin-bottom: 0;
      padding: 0;
      text-align: left;
    }

    &__title,
    &__description,
    &__additional-info {
      padding-right: 8px;
      padding-left: 0;
    }

    &__footer {
      margin: 0 15px 0 auto;
      padding-top: 15px;
    }

    &__more-details {
      margin-bottom: 10px;
      font-size: 12px;
      text-align: left;
    }

    &__editors-pick,
    &__expires,
    &__clicks,
    &__label-code {
      margin: 8px 5px 8px 0;
      white-space: nowrap;
    }
  }
}

@media #{$sm} {
  .coupons__item--esdo {
    &::after {
      bottom: -4px;
    }

    .coupon {
      &__logo {
        margin: 0 -15px;
      }

      &__exclusive {
        margin: -1px -15px 0;
        padding: 10px 0;
        background-color: $esdo-color;
      }

      &__dots {
        &::before,
        &::after {
          bottom: -12px;
        }
      }
    }
  }

  .coupon {
    &__expires,
    &__clicks,
    &__label-code,
    &__editors-pick {
      height: min-content;
    }
  }
}

@media #{$min-md} and #{$lg} {
  .coupon {
    &__header {
      min-width: 110px;
    }
  }
}

@media #{$min-lg} {
  .coupon {
    &__title {
      min-height: 48px;
      font-size: 20px;
      line-height: 24px;
    }

    &__description {
      font-size: 14px;
      line-height: 18px;
    }
  }
}

.curated {
  $this: &;

  &__coupon-list {
    margin: $curated__margin;
    padding: $curated__padding;
  }

  &__section {
    position: relative;
    display: grid;
    grid-template:
      "logo title"
      "logo subtitle";
    grid-template-columns: $curated-section__grid-template-column--left 1fr;
    align-items: center;
    margin: $curated-section__margin;
    padding: $curated-section__padding;
    color: $curated-section__color;
    background-color: $curated-section__bg-color;
    border: $curated-section__border;
    border-width: $curated-section__border-width;
    border-radius: $curated-section__border-radius;
  }

  &__heading {
    position: absolute;
    top: -$curated-heading__height;
    left: 0;
    display: flex;
    align-items: center;
    width: $curated-heading__width;
    height: $curated-heading__height;
    margin: $curated-heading__margin;
    padding: $curated-heading__padding;
    color: $curated-heading__color;
    font-weight: $curated-heading__font-weight;
    font-size: $curated-heading__font-size;
    line-height: $curated-heading__line-height;
    background-color: $curated-heading__bg-color;
    border-radius: $curated-heading__border-radius;

    @media #{$sm} {
      margin: $curated-heading__margin--mobile;
      font-size: $curated-heading__font-size--mobile;
      line-height: $curated-heading__line-height--mobile;
    }
  }

  &__logo {
    margin: $curated-logo__margin;
    padding: $curated-logo__padding;
    border: $curated-logo__border;
    border-radius: $curated-logo__border-radius;
  }

  &__logo-link {
    grid-area: logo;
  }

  &__title {
    grid-area: title;
    margin: $curated-title__margin;
    color: $curated-title__color;
    font-weight: $curated-title__font-weight;
    font-size: $curated-title__font-size;
    line-height: $curated-title__line-height;

    @media #{$sm} {
      margin: $curated-title__margin--mobile;
      font-size: $curated-title__font-size--mobile;
      line-height: $curated-title__line-height--mobile;
    }

    &-name {
      color: $curated-title-name__color;
      font-weight: $curated-title-name__font-weight;

      &:hover {
        color: $curated-title-name__color;
      }
    }
  }

  &__subtitle {
    grid-area: subtitle;
    width: fit-content;
    color: $curated-subtitle__color;
    font-size: $curated-subtitle__font-size;
    font-family: $curated-subtitle__font-family;
    line-height: $curated-subtitle__line-height;

    @media #{$sm} {
      font-size: $curated-subtitle__font-size--mobile;
      line-height: $curated-subtitle__line-height--mobile;
    }

    &:not(.opened) {
      position: relative;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-box-orient: vertical;

      @media #{$sm} {
        -webkit-line-clamp: 2;
      }
    }

    a {
      color: $curated-subtitle-link__color;
      text-decoration: underline;

      &:hover {
        color: $curated-subtitle-link__color--hover;
      }
    }
  }

  &__read-more {
    display: block;
    background-color: $curated-read-more__bg-color;
    cursor: pointer;

    &::after {
      color: $curated-read-more__color;
      text-decoration: underline;
      content: attr(data-less-text);
    }

    &:not(.show) {
      display: none;
    }

    &:not(.opened) {
      position: absolute;
      right: 0;

      @media #{$min-sm} {
        top: 0;
      }

      @media #{$sm} {
        bottom: 0;
      }

      &::before {
        content: "... ";
      }

      &::after {
        content: attr(data-more-text);
      }
    }
  }
}
