.flyout {
  $this: &;

  position: fixed;
  right: 0;
  bottom: 175px;
  z-index: 100;
  display: grid;
  grid-template-areas: "logo text btn";
  grid-template-columns: 80px auto;
  align-items: center;
  width: 515px;
  height: auto;
  min-height: 150px;
  padding: 15px 21px 20px 48px;
  color: $flyout__color;
  font-family: $flyout__font;
  background-color: $flyout__background;
  border-radius: 15px 0 0 15px;
  box-shadow: -5px 7px 17px rgba(0, 0, 0, 0.34);
  transform: translateX(150%);
  transition: transform ease-in-out 0.3s;

  @media #{$xs} {
    grid-template-areas:
      "logo text"
      "btn btn";
    justify-items: center;
    width: 90%;
    height: 165px;
    padding: 16px 10px 15px 47px;
  }

  &::before {
    position: absolute;
    left: 0;
    width: 30px;
    height: 100%;
    background-color: $flyout-before__color;
    background-image: url("data:image/svg+xml,%3Csvg width='18' height='20' viewBox='0 0 18 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M18 16.2602L17.7946 16.0751C17.212 15.5448 16.702 14.9368 16.2785 14.2674C15.816 13.3435 15.5387 12.3345 15.4631 11.2997V8.25193C15.4671 6.6266 14.8899 5.05572 13.8399 3.83443C12.7899 2.61314 11.3395 1.82552 9.76107 1.61954V0.82365C9.76107 0.605205 9.67612 0.395706 9.52489 0.241242C9.37367 0.0867773 9.16856 0 8.9547 0C8.74083 0 8.53573 0.0867773 8.3845 0.241242C8.23328 0.395706 8.14832 0.605205 8.14832 0.82365V1.63188C6.58405 1.85271 5.15112 2.64509 4.11495 3.86228C3.07878 5.07946 2.50957 6.63896 2.51275 8.25193V11.2997C2.4371 12.3345 2.15987 13.3435 1.69732 14.2674C1.28107 14.9351 0.779312 15.5431 0.205369 16.0751L0 16.2602V18H18V16.2602Z' fill='white'/%3E%3Cpath d='M7 19C7.06681 19.2772 7.30613 19.5311 7.67374 19.7149C8.04136 19.8987 8.51245 20 9 20C9.48755 20 9.95864 19.8987 10.3263 19.7149C10.6939 19.5311 10.9332 19.2772 11 19H7Z' fill='white'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 15px 0 0 15px;
    cursor: pointer;
    content: "";

    @media #{$xs} {
      height: 165px;
    }
  }

  &__close-btn {
    position: absolute;
    top: -20px;
    right: 16px;
    width: 40px;
    height: 40px;
    background-color: $flyout__close-button;
    background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M14.6666 14.6667L1.33325 1.33334M14.6666 1.33334L1.33325 14.6667' stroke='black' stroke-width='2' stroke-linecap='round'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 50%;
    cursor: pointer;
    content: "";

    &:hover {
      background-color: $flyout__close-button-hover;
    }
  }

  &--default {
    padding: 11px 15px 11px 47px;
  }

  &__logo {
    display: flex;
    flex-direction: column;
    grid-area: logo;
    justify-content: center;
    width: 80px;
    height: 80px;
    color: $flyout__logo-color;

    &--default {
      flex: 1 0 83px;
      height: 60px;
      border-right: 2px solid white;
    }

    &--first {
      background-color: white;
    }
  }

  &__amount {
    font-weight: 700;
    line-height: 1.5em;
  }

  &__type {
    line-height: 1.5em;
  }

  &__text {
    grid-area: text;
    padding: 0 20px;
    font-weight: 300;
    font-size: 20px;
    line-height: 1.4;
    text-align: center;

    @media #{$xs} {
      padding: 0 10px;
      font-size: 16px;
      text-align: left;
    }

    &::first-line {
      font-weight: 700;
    }
  }

  &__clickout {
    display: flex;
    grid-area: btn;
    align-items: center;
    justify-content: center;
    width: 115px;
    height: 40px;
    background-color: $flyout__clickout;
    border-radius: 5px;
    cursor: pointer;

    &--default {
      padding: 8px 16px;
      font-weight: 700;
      font-size: 11px;
      line-height: 1;
      white-space: nowrap;
      text-align: center;
      border: 1px solid $color-white;
      border-radius: 30px;

      @media #{$xs} {
        padding: 8px;
        font-size: 10px;
      }

      &:hover {
        background-color: $color-white;
      }
    }
  }

  &__button {
    color: $flyout__button-color;
    font-size: 16px;

    &:hover {
      color: $flyout__button-color;
      font-weight: 700;
      text-decoration: none;
    }
  }

  &--visible {
    transform: translateX(0);
  }

  &--hide {
    transform: (translateX(calc(100% - 30px)));
  }

  &--sustainable {
    gap: 0 20px;
    background-color: $flyout__background--sustainable;
    border-top: 2px solid $flyout__border-color--top;
    border-bottom: 2px solid $flyout__border-color--bottom;

    @media #{$xs} {
      grid-template-areas:
        "logo text"
        "logo btn";
      gap: 0 10px;
      justify-items: start;
    }

    &::before {
      $icon-width: 22px;
      $icon-height: 22px;

      background-image:
        url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' width='#{$icon-width}' height='#{$icon-height}' viewBox='0 0 590 733'%3E%3Cpath fill='%23FFF' d='M0 706.124C0 299.456 149.77 81.334 589.835 0c5.547 512.033-127.582 750.488-552.855 732.003 29.584-83.186 321.728-443.634 327.275-454.727C369.802 266.183 244.07 321.638 0 706.132'/%3E%3C/svg%3E"),
        linear-gradient(180deg, $flyout__border-color--top 0%, $flyout__border-color--bottom 100%);
      border-radius: 12px 0 0 12px;
    }

    #{$this}__close-btn {
      display: none;
    }

    #{$this}__logo {
      background-color: transparent;
    }

    #{$this}__text {
      padding: 0;
    }

    #{$this}__clickout {
      color: $flyout__clickout-text-color--sustainable;
      font-size: 16px;
      line-height: 1;
      background-color: $flyout__clickout--sustainable;

      &:hover {
        color: $flyout__button-color;
        font-weight: 700;
        text-decoration: none;
      }
    }

    #{$this}__button {
      color: inherit;
      font-size: inherit;
    }
  }
}
