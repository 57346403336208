.summary-widget {
  h2 {
    font-size: 20px;
  }

  a {
    display: block;
    color: inherit;
  }

  th {
    margin: 0;
    font-weight: bold;
    font-size: 14px;
    vertical-align: middle;
  }

  .tr-hover:hover {
    background: #D6D6D6;
  }
}

.additional-info {
  .summary-widget {
    .table {
      table-layout: fixed;
    }

    tr > td:first-child,
    th:first-child {
      width: 190px;
      padding-left: 10px;

      @media #{$sm} {
        width: 50%;
      }
    }

    h2 {
      margin-top: 32px;
      margin-bottom: 12px;
      font-size: 20px;
    }

    a {
      color: inherit;
    }

    th {
      font-size: 14px;
    }
  }

  .card-content {
    p {
      color: $color-text;
      font-weight: lighter;
      font-size: 14px;
    }
  }

  .shop-header-logo {
    margin-bottom: 12px;
    background-color: #FFF;
    border-radius: 2px;
    box-shadow: inset 0 0 0 1px $color-border;

    a {
      display: block;
      padding: 20px 80px;
      text-align: center;

      img {
        width: 100%;
        min-width: 133px;
        height: auto;
        min-height: 133px;
      }
    }
  }

  span.label {
    color: #333;
    font-weight: normal;
    line-height: 22px;
    background: #FFF;
  }

  .fallback_link {
    color: $color-sky;
    cursor: pointer;

    &:hover {
      text-decoration: none;
    }

    > span {
      &:hover,
      &:active {
        text-decoration: underline;
      }
    }
  }

  .star-rating {
    &--voted {
      > ul {
        opacity: 0.6;
        pointer-events: none;
      }
    }

    > ul {
      display: inline-flex;
      flex-direction: row-reverse;
      padding: 0 8px;
      background-color: $color-border;
      border-radius: 20px;
    }

    &__item {
      margin: 0 -4px;
      padding: 8px 12px 7px;
      color: $color-ready;
      font-size: $star-rating-item__font-size;
      line-height: 1;
      box-shadow: none;

      &:focus,
      &:active {
        margin-top: 0;
        box-shadow: none;
      }

      &--active,
      &--active ~ li,
      &:hover,
      &:hover ~ li {
        color: $color-ready;

        .roberto-star-empty::before {
          content: "\e820";
        }
      }

      &--active .roberto-star-empty::before {
        content: "\e820";
      }
    }

    &__count::after {
      content: attr(data-reviewCount);
    }

    &__value::after {
      content: attr(data-ratingValue);
    }
  }
}

.top-similar-shops {
  & > .container {
    width: auto;
    padding: 0;
  }
}
