@mixin flat-see-details($hover: $color-see-details-hover, $color: $color-see-details) {
  &__see-details {
    color: $color;
    font-size: 16px;
    text-shadow: none;

    svg {
      display: none;
    }

    &::after {
      display: inline-block;
      width: 6px;
      height: 10px;
      margin-left: 6px;
      background-image: url("data:image/svg+xml,%3Csvg width='8' height='13' viewBox='0 0 8 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.33325 1.25406L6.66659 6.58739L1.33325 11.9207' stroke='%23222222' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
      background-size: cover;
      content: "";
    }

    &:hover {
      color: $hover;

      &::after {
        background-image: url("data:image/svg+xml,%3Csvg width='8' height='13' viewBox='0 0 8 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.33325 1.25406L6.66659 6.58739L1.33325 11.9207' stroke='%230066CC' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
      }
    }
  }
}

@mixin flat-anchor($color: $color-sky, $color-hover: $color) {
  @if variable-exists(color-wave-blue) {
    $color: $color-wave-blue;
    $color-hover: $color-wave-blue;
  } @else {
    $color: $color-sky;
    $color-hover: $color;
  }

  color: $color;
  font-size: 14px;
  border-bottom: 1px solid transparent;

  &:focus,
  &:hover {
    color: $color-hover;
    text-decoration: none;
    border-bottom-color: $color-hover;
  }
}

@mixin flat-button($color, $text: #fff, $percent: 15%) {
  color: $text;
  background-color: $color;
  opacity: 1;
  transition: 0.3s ease;

  &:focus,
  &:hover {
    color: $text;
    background-color: darken($color, 15%);
  }

  &:active {
    opacity: 0.9;
    transition: opacity 0.3s ease;
  }

  &:focus,
  &:active {
    margin-top: 2px;
    border-bottom-width: 0;
  }
}

@mixin flat-truncate {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

/**
 * just a regular recursive sub-string replace
 */
@function str-replace(
  $string,
  $substring-to-search,
  $replacement: ""
) {
  $index: str-index($string, $substring-to-search);

  @if $index {
    @return str-slice($string, 1, $index - 1) + $replacement + str-replace(
      str-slice($string, $index + str-length($substring-to-search)),
      $substring-to-search,
      $replacement
    );
  }

  @return $string;
}

/* stylelint-disable max-line-length */

/**
 * this function can help you ease change color for SVG
 * helpful urls:
 * svg optimizer - https://jakearchibald.github.io/svgomg/
 * svg to inline url - https://yoksel.github.io/url-encoder/
 * original SVG
 * <svg xmlns="http://www.w3.org/2000/svg"><circle cx="17" cy="17" r="15" fill="#d8d8d8" stroke="#000"/></svg>
 *
 * use case
 *
 * input
 * url-with-icon(url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='17' cy='17' r='15' fill='placeholder-for-color' stroke='%23000'/%3E%3C/svg%3E"), $color-black)
 * result
 * url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='17' cy='17' r='15' fill='%23000' stroke='%23000'/%3E%3C/svg%3E")
 *
 * input
 * url-with-icon("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='17' cy='17' r='15' fill='%23d8d8d8' stroke='placeholder-for-color'/%3E%3C/svg%3E", red)
 * result
 * url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='17' cy='17' r='15' fill='%23d8d8d8' stroke='red'/%3E%3C/svg%3E")
 */
/* stylelint-enable max-line-length */

@function url-with-icon(
  $svg,
  $color
) {
  /* convert lattice sign  if color given in hex */
  @if str-index(#{$color}, "#") {
    $color: "%23" + str-slice("#{$color}", 2);
  }

  /* add url() if given only string with svg */
  @if not str-index($svg, "url") {
    $svg: url($svg);
  }

  @return str-replace($svg, "placeholder-for-color", $color);
}

@mixin arrow($color: $color-white, $size: 2px, $deg: 45deg) {
  padding: $size;
  border: solid $color;
  border-width: 0 1px 1px 0;
  transform: rotate($deg);
}

@mixin editors-pick-icon($icon: $coupon-editors-pick-tag--icon) {
  @if $icon != false {
    position: relative;
    top: 3px;
    display: inline-block;
    width: 18px;
    height: 18px;
    background-image: url-with-icon($icon, $color-cta);
    background-repeat: no-repeat;
    background-size: contain;
    content: " ";
  }
}

@mixin line-clamp($number-of-lines: 2) {
  display: -webkit-box;
  -webkit-line-clamp: $number-of-lines;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
}
