.scroll-to-top {
  position: fixed;
  right: 30px;
  bottom: 30px;
  z-index: 900;
  display: none;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background-color: #FFF;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  box-shadow: 0 0 0 rgba(0, 0, 0, 0.3);
  visibility: hidden;
  cursor: pointer;
  opacity: 0;
  transition: all 0.1s ease-in-out;

  @media (max-width: 768px) {
    right: 15px;
    bottom: 15px;
  }

  &:hover {
    box-shadow: 1px 1px 0 1px rgba(0, 0, 0, 0.3);
  }

  &#{&}--active {
    display: flex;
    visibility: visible;
    opacity: 1;
  }

  i {
    font-size: 19px;
    transform: rotate(-93deg);
  }
}
