.pannacotta {
  &.card-pagination {
    .card-content {
      .pagination {
        margin: 10px 25px 6px;

        > li {
          &.active > a {
            color: #FFF;
            background-color: $color-sky;
          }

          &.disabled {
            display: none !important;
          }

          > a,
          > span {
            margin: 0 5px;
            padding: 7px 13px;
            color: $color-sky;
            font-weight: bold;
            font-size: 14px;
            border: none;
            border-radius: 0;
            transition: background-color 0.3s ease, color 0.3s ease;
          }

          > a {
            &:focus,
            &:hover {
              color: #FFF;
              background-color: $color-sky;
            }
          }
        }
      }
    }
  }
}
