.card-shops-index {
  .card-content {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    .h1,
    .h2,
    .h3,
    .h4,
    .h5,
    .h6 {
      color: $color-text;
      font-weight: lighter;
      font-size: 20px;
    }

    hr {
      margin-right: -25px;
      margin-left: -25px;

      @media (max-width: 767px) {
        margin-right: 0;
        margin-left: 0;
      }
    }

    ul li {
      display: inline-block;
      width: 32%;
    }

    ol li a {
      overflow: hidden;
      text-overflow: ellipsis;

      @include flat-anchor($color-sky);
    }
  }
}

@media (min-width: 768px) {
  .card-shops-index .card-content {
    ul {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;

      li {
        width: 19%;
      }
    }

    ol li a {
      display: inline-block;
      max-width: 190px;
      white-space: nowrap;
    }
  }
}

@media (min-width: 992px) {
  .card-shops-index .card-content ol li a {
    max-width: 170px;
  }
}

@media (min-width: 1200px) {
  .card-shops-index .card-content ol li a {
    max-width: 210px;
  }
}

@media (max-width: 767px) {
  .card-shops-index .card-content {
    ul li:nth-child(n+7) {
      display: none;
    }

    ol li {
      margin-bottom: 10px;

      a {
        line-height: 44px;
      }
    }
  }
}
