.card-coupons-grid {
  ul.list-unstyled {
    display: flex;
    flex-wrap: wrap;

    .clickout {
      position: absolute;
      right: 0;
      bottom: 25px;
      left: 0;
      width: 100%;
      max-width: unset;
      height: auto;
      text-align: center;
    }

    > li {
      position: relative;
      flex: 0 1 100%;
      margin: 0;
      padding: 10px;
      text-align: center;

      @media #{$min-sm} {
        flex: 0 1 50%;
        max-width: 50%;
      }

      @media #{$min-md} {
        flex: 0 1 33%;
        max-width: 33%;
      }

      &.item {
        @media #{$min-lg} {
          flex: 0 1 25%;
          max-width: 25%;
        }

        .padding {
          width: 100%;
          height: 100%;
          padding: 40px 20px 80px;
          text-align: center;
          background-color: $color-white;
        }

        img {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          width: auto;
          max-width: 100%;
          height: auto;
          max-height: 100%;
          margin: auto;
          object-fit: cover;
        }

        .coupon-img {
          position: relative;
          width: 120px;
          height: 120px;
          margin: 0 auto;
        }

        .coupon-title {
          margin: 8px 0;
          padding: 0 12px;
          font-weight: 200;
          font-size: 16px;
          line-height: 22px;
          text-align: center;

          @media #{$min-sm} {
            margin-top: 12px;
            padding: 0;
            overflow: hidden;
            font-size: 16px;
          }

          @media #{$min-lg} {
            font-size: 16px;
            line-height: 24px;
          }
        }

        .coupon-exclusive {
          position: absolute;
          top: 14px;
          left: 20px;
          color: $color-text;
          font-size: 12px;
          white-space: nowrap;
        }

        .coupon-logo {
          display: inline-block;
        }

        .coupon-tag {
          position: absolute;
          top: unset;
          right: 22px;
          margin: 0;
        }
      }
    }
  }
}

.card-coupons-grid {
  .item {
    .paddding {
      background: $color-white;
      border-radius: 2px;
      box-shadow: inset 0 0 0 1px #DEDED4;
    }

    .coupon-title a {
      color: inherit;
    }

    .coupon-exclusive {
      color: $color-text;
    }
  }
}

.show-more {
  display: block;
  width: 300px;
  max-width: 80%;
  margin: 0 auto;
  font-size: 16px;
  text-decoration: none;

  &:hover,
  &:active {
    text-decoration: none;

    span {
      text-decoration: underline;
    }
  }
}

#campaign-featured-images {
  margin-top: 30px;

  .featured-images {
    margin-top: 10px;
  }
}

#campaign-related-shops {
  margin-top: 50px;

  h2 {
    margin-top: 0;
  }

  ul {
    display: flex;
    flex-flow: row wrap;
    margin-right: -10px;
    margin-left: -10px;
    font-size: 0;

    > li {
      flex: 0 1 43%;
      margin: 10px 3%;
      text-align: center;

      @media (min-width: 400px) {
        flex: 0 1 30%;
        margin: 10px 1%;
      }

      @media #{$min-sm} {
        flex: 0 1 30.6%;
      }

      @media #{$min-md} {
        flex: 0 1 22.9%;
      }

      @media #{$min-lg} {
        flex: 0 1 14.64%;
      }

      img {
        margin: 0 auto;

        @media #{$min-lg} {
          width: 100%;
        }

        &:hover {
          opacity: 0.8;
          transition: opacity 0.3s ease, right 0.33s ease;
        }
      }
    }
  }
}

.widget-newsletter-full {
  padding: 0;

  @media #{$min-sm} {
    padding: 42px 20px 24px;
  }

  .btn-small {
    width: 100%;
    padding: 0 3px !important;
    line-height: 38px;

    @media #{$min-sm} {
      padding: 0 10px !important;
    }

    @media #{$min-md} {
      width: auto;
    }
  }

  .form-group input {
    margin-bottom: 5px;
  }

  h2 {
    margin-top: 0;
    color: $color-white;
  }

  p {
    margin-right: 0;
    padding-right: 0;
  }

  input[type="email"] {
    width: 100%;
  }

  .privacy-note {
    width: 100%;
    text-align: left;

    input {
      min-width: 0;
      height: auto;
    }
  }
}
