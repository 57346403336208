.filter-by-category {
  .card-content {
    padding: 25px 25px 0;

    p {
      color: $color-title;
      font-weight: lighter;
      font-size: 14px;
    }

    ul li {
      margin-bottom: 15px;

      a {
        display: block;
        height: 39px;
        padding: 9px 8px;
        color: $color-sky;
        font-weight: lighter;
        font-size: 14px;
        text-align: center;
        text-transform: uppercase;
        border: 1px solid $color-sky;
        transition: background-color 0.3s ease, color 0.3s ease;

        &:focus,
        &:hover {
          color: #FFF;
          text-decoration: none;
          background-color: $color-sky;
        }
      }
    }
  }
}
