.coupon-filter {
  display: none;
  margin: 12px 0 0;
  padding: 0;
  list-style-type: none;

  &--mobile {
    @media (max-width: 1023px) {
      display: inline-block;
    }
  }

  &--desktop {
    @media (min-width: 1024px) {
      display: inline-block;
    }
  }

  &--show-count {
    display: inline-block;
  }

  &__item {
    float: left;
    margin-right: 4px;
    margin-left: 0;
    padding: 4px 14px;
    color: $color-white;
    font-size: 12px;
    background-color: lighten($color-sky, 15%);
    border: 1px solid lighten($color-sky, 15%);
    cursor: pointer;

    &:hover {
      text-decoration: underline;
      background-color: $color-sky;
      border: 1px solid $color-sky;
    }

    &.active {
      text-decoration: none;
      background-color: $color-sky;
      border: 1px solid $color-sky;

      &:hover {
        text-decoration: none;
        cursor: default;
      }
    }

    &.hidden {
      display: none;
    }
  }
}
