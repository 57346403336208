#header {
  margin-bottom: 28px;
  background-color: $color-white;

  .topbar {
    height: 26px;
    font-size: 11px;
    line-height: 26px;
    text-align: center;
    background-color: $color-headline;

    ul {
      display: inline-block;
      font-size: 12px;

      &.nav-pills > li + li {
        margin-left: 0;
      }

      li a,
      li span {
        padding: 0 12px;
        color: $color-white;
        border-radius: 0;
        cursor: pointer;

        &:hover {
          color: $color-ready;
        }

        &.signup:hover {
          color: $color-cherry;
          background-color: $color-inactive;
        }

        &.reverse {
          color: #FFF;
          background-color: $color-ready;

          &:hover {
            color: $color-ready;
            background-color: #EEE;
          }
        }
      }
    }
  }

  .navbar-toggle {
    width: 100%;
    margin: 0;
    padding: 14px 0;
    font-size: 24px;
  }

  .site-logo {
    margin: 16px 0;
    text-align: center;

    img {
      display: inherit;
      width: auto;
      max-height: 40px;
      margin: -8px auto 0 0;
    }

    picture {
      display: inherit;
    }
  }
}

// mobile nav
.flat-navmenu-default {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  width: 240px;
  height: 100%;
  overflow-y: auto;
  background-color: $color-text;
  border: none;
  transform: translateX(-100%);
  transition: transform ease-in-out 0.2s;
  will-change: transform;

  &--active {
    transform: translateX(0%);
  }

  .search-navigation-mobile .form-group {
    margin: 0;
    overflow: hidden;
    background-color: #FFF;

    label i {
      height: 94px;
      margin: 0;
      padding: 37px 0 37px 25px;
      color: $color-inactive;
      font-size: 14px;
      background-color: #FFF;
    }
  }

  #input_search_navigation_mobile {
    display: inline-block;
    width: 197px;
    height: 94px;
    margin: 0;
    padding: 37px 25px 37px 0;
    color: $color-text;
    font-weight: lighter;
    font-size: 16px;
    border: 0;
    border-radius: 0;
    box-shadow: none;
  }

  .nav > li > a {
    display: block;
    padding: 25px;
    color: #FFF;
    font-weight: lighter;
    font-size: 14px;
    border-bottom: 1px solid darken($color-text, 10%);
    transition: background-color 0.3s ease;

    &:focus,
    &:hover {
      background-color: darken($color-text, 10%);
    }
  }

  .nav > li.logo-row a {
    border: none;

    img {
      max-width: 100% !important;
      margin: 0 auto;
    }
  }
}

.sub-menu {
  &__shops {
    display: none;

    &.opened {
      display: block;
    }
  }
}

@media #{$min-sm} {
  .nav.navigation {
    display: flex;
    justify-content: space-between;
  }

  #main-menu > ul > li {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    height: 72px;
    white-space: nowrap;

    &.active > a {
      color: $color-ready;
      background-color: transparent;
    }

    > a {
      padding: 0 4px;
      color: $color-text;
      font-weight: 600;
      font-size: 14px;

      &:focus,
      &:hover {
        color: $color-ready;
        background-color: transparent;
      }
    }

    &.open > a {
      color: $color-ready;
      background-color: transparent;
    }
  }

  #header {
    .topbar {
      height: auto;
      line-height: 31px;

      ul {
        &.primary {
          float: right;
        }

        &.secondary {
          float: left;
        }

        li a {
          &.signup {
            background-color: $color-ready;
          }
        }
      }
    }

    .site-logo {
      a {
        display: table-cell;
        height: 40px;
        vertical-align: middle;
      }

      img {
        max-height: 44px;
      }
    }

    // hidden on mobile
    .search-header {
      margin: 14px 0;
      padding: 4px;
      border: 1px solid $color-body-bg;
      border-radius: 2px;

      input {
        width: 170px;
      }

      #input_search_header {
        padding: 0 4px;
        color: $color-text;
        font-weight: lighter;
        font-size: 12px;
        border: none;
        box-shadow: none;
      }

      .btn {
        padding: 6px 9px 5px;
        font-size: 14px;
      }
    }
  }

  .sub-menu {
    position: absolute;
    top: 103px;
    right: 0;
    left: 0;
    z-index: 998;
    padding-top: 25px;
    background: rgba(255, 255, 255, 0.95);
    border-top: 2px solid $color-ready;
    border-bottom: 1px solid $color-border;

    .no-topbar & {
      top: 72px;
    }

    a {
      color: $color-headline;
      font-weight: lighter;
      font-size: 14px;

      &:hover {
        color: $color-ready;
        text-decoration: none;
      }
    }

    p.text-center {
      margin-top: 15px;
      padding-top: 10px;
      border-top: 1px solid $color-border;

      a {
        font-weight: bold;
      }
    }
  }

  .sub-menu__specials,
  .sub-menu__categories {
    position: absolute;
    top: 72px;
    right: 50%;
    z-index: 998;
    background: rgba(255, 255, 255, 0.95);
    border-top: 2px solid $color-ready;
    transform: translateX(50%);

    a {
      color: $color-headline;
      font-weight: lighter;
      font-size: 14px;
      text-align: center;

      &:hover {
        color: $color-ready;
      }
    }

    li.nav-see-more {
      border-top: 1px solid $color-border;
      border-bottom: 1px solid $color-border;

      a {
        font-weight: bold;
      }
    }
  }

  .has-sub-menu {
    &__specials,
    &__categories {
      .sub-menu {
        &__categories,
        &__specials {
          display: none;
        }
      }

      &:hover {
        .sub-menu {
          &__categories,
          &__specials {
            display: block;
          }
        }
      }
    }
  }
}

@media #{$min-md} {
  #header {
    .topbar {
      font-size: 12px;
      line-height: 36px;
    }

    .site-logo {
      margin: 25px 0;
    }

    .search-header {
      margin: 23px 0;

      input {
        width: 243px;
      }
    }

    #main-menu > ul > li {
      height: 90px;

      > a {
        font-size: 16px;
      }
    }
  }

  .sub-menu {
    top: 126px;

    .no-topbar & {
      top: 90px;
    }
  }

  .sub-menu__specials,
  .sub-menu__categories {
    top: 90px;
  }
}

@media #{$min-lg} {
  #header {
    .search-header input {
      width: 310px;
    }

    .search-result-shop img {
      width: 85px;
      height: 85px;
    }
  }
}

.head-disclaimer {
  position: relative;
  top: 0;
  width: 100%;
  padding: 3px;
  color: #FFF;
  font-weight: 400;
  font-size: 12px;
  text-align: center;
  background: #000;
}

.head-disclaimer-text {
  margin: 0;

  a {
    color: $color-bright-azure;
  }
}
