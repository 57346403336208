$colors: (
  "color-white": #FFF,
  "color-black": #000,
  "topbar-bg": #FFF,
  "topbar-font-color": #000,
  "header-bg-image": url("../../../../images/flat_2016/xmas-bg.jpg"),
  "color-primary": #E70E39,
  "color-secondary": #323538,
  "color-bg": #F4F4F1,
  "color-light-bg": #E5E5E5,
  "color-font": #000,
  "color-1": #27AE60,
  "color-2": #5DBCFF,
  "color-3": #8A8A8A,
  "color-4": #151515,
  "color-5": #627A89,
  "color-6": #605E5E,
  "color-7": #F6F6F6,
  "color-8": #5C6469,
  "telegraph-green": #285E6A,
  "independent-red": #D13030,
  "social-blue": #4395DA,
  "gold-premium": #C3851A,
  "yellow-paste": #EECA02,
  "pastel-purple": #8A44FF,
  "bubblegum-pink": #FD7AFF,
  "grey": #959595,
  "border-radius": 3px
);

@mixin colorize($colors) {
  /* Top Links Start */
  .top-links {
    &__btn {
      border: 1px solid transparentize(map_get($colors, "topbar-bg"), 0.7);
      border-radius: map_get($colors, "border-radius");

      &:hover {
        background: transparentize(map_get($colors, "topbar-bg"), 0.95);
      }
    }
  }

  /* Top Links End */

  /* Hot Offers Start */
  .offer {
    &--bg {
      background-color: map_get($colors, "color-7");
    }

    &__discount {
      color: map_get($colors, "color-white");
    }

    &--no-bg {
      border: 1px solid darken(map_get($colors, "color-bg"), 9%);
    }

    &__cta.btn {
      color: map_get($colors, "color-white");
      background: map_get($colors, "color-primary");
    }

    &--green-label {
      .offer {
        &__discount-tag #a {
          fill: map_get($colors, "color-1");
        }
      }
    }

    &--yellow-label {
      .offer {
        &__discount-tag #a {
          fill: map_get($colors, "color-2");
        }
      }
    }
  }

  /* Hot Offers End */

  /* Subpage Teaser Start */
  .subpage-teaser {
    background: map_get($colors, "color-font");
    background-repeat: no-repeat;
    background-position: 50% 0;
    background-size: cover;

    &__text {
      color: map_get($colors, "color-4");
      background: map_get($colors, "color-white");
    }
  }

  /* Subpage Teaser End */

  /* Premium Offers Start */
  .premium-offers {
    background-color: map_get($colors, "color-white");
  }

  .premium-offer {
    $this: &;

    &.premium-offer {
      &__category {
        &--blue {
          color: map_get($colors, "color-2");

          &::before {
            background-color: map_get($colors, "color-2");
          }
        }

        &--green {
          color: map_get($colors, "color-1");

          &::before {
            background-color: map_get($colors, "color-1");
          }
        }

        &--purple {
          color: map_get($colors, "color-10");

          &::before {
            background-color: map_get($colors, "color-10");
          }
        }

        &--red {
          color: map_get($colors, "color-11");

          &::before {
            background-color: map_get($colors, "color-11");
          }
        }

        &--telegraph {
          color: map_get($colors, "telegraph-green");

          &::before {
            background-color: map_get($colors, "telegraph-green");
          }
        }

        &--independent {
          color: map_get($colors, "independent-red");

          &::before {
            background-color: map_get($colors, "independent-red");
          }
        }

        &--social {
          color: map_get($colors, "social-blue");

          &::before {
            background-color: map_get($colors, "social-blue");
          }
        }

        &--gold {
          color: map_get($colors, "gold-premium");

          &::before {
            background-color: map_get($colors, "gold-premium");
          }
        }

        &--yellow {
          color: map_get($colors, "yellow-paste");

          &::before {
            background-color: map_get($colors, "yellow-paste");
          }
        }

        &--bubblegum {
          color: map_get($colors, "bubblegum-pink");

          &::before {
            background-color: map_get($colors, "bubblegum-pink");
          }
        }

        &--pastel-purple {
          color: map_get($colors, "pastel-purple");

          &::before {
            background-color: map_get($colors, "pastel-purple");
          }
        }

        &--grey {
          color: map_get($colors, "grey");

          &::before {
            background-color: map_get($colors, "grey");
          }
        }
      }

      &__see-details {
        color: map_get($colors, "color-8");

        svg {
          fill: map_get($colors, "color-8");
        }
      }

      &--orange-bg {
        background-color: map_get($colors, "color-9");
      }

      &--green-bg {
        background-color: map_get($colors, "color-1");
      }

      &--blue-bg {
        background-color: map_get($colors, "color-2");
      }

      &--telegraph-bg {
        background-color: map_get($colors, "telegraph-green");
      }

      &--independent-bg {
        background-color: map_get($colors, "independent-red");
      }

      &--social-blue-bg {
        background-color: map_get($colors, "social-blue");
      }

      &--gold-bg {
        background-color: map_get($colors, "gold-premium");
      }

      &--yellow-paste-bg {
        background-color: map_get($colors, "yellow-paste");
      }

      &--pastel-purple-bg {
        background-color: map_get($colors, "pastel-purple");
      }

      &--bubblegum-bg {
        background-color: map_get($colors, "bubblegum-pink");
      }

      &--grey-bg {
        background-color: map_get($colors, "grey");
      }
    }
  }

  /* Premium Offers End */
}

@include colorize($colors);

/*  Common elements Start */
.section {
  &-header {
    margin-bottom: 4px;
    font-weight: 300;
    font-size: 48px;
    text-align: center;
    text-transform: uppercase;

    @media (max-width: 767px) {
      margin-bottom: 9px;
      font-size: 25px;
    }
  }

  &-subheader {
    margin: 0 0 7px;
    font-size: 16px;
    font-style: italic;
    line-height: 22px;
    text-align: center;
    text-transform: uppercase;
  }
}

/*  Common elements End */

/* Shop Bubbles Start */
.shop-bubbles {
  padding: 20px 0 60px;

  @media #{$sm} {
    padding-bottom: 30px;
  }

  &__header {
    text-align: center;
  }

  .section {
    &-header {
      color: map_get($colors, "color-black");
    }

    &-subheader {
      color: map_get($colors, "color-black");
    }
  }

  &__list {
    width: 100%;
    max-width: 1050px;
    margin: 0 auto;
    padding-top: 20px;
  }

  &__row {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 0;
    padding-left: 0;

    &:nth-child(2) {
      padding: 0 66px;
    }
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 120px;
    height: 120px;
    margin: 15px;
    overflow: hidden;
    text-align: center;
    border-radius: 4px;

    @media (max-width: 768px) {
      width: 100px;
      height: 100px;
      margin: 10px;
      box-shadow: 0 3px 12px rgba(122, 122, 122, 0.2);
    }

    &:hover {
      box-shadow: 0 3px 20px rgba(61, 61, 61, 0.2);
    }
  }

  &__img {
    width: 100px;
    height: 100px;

    @media (max-width: 768px) {
      width: 80px;
      height: 80px;
    }
  }

  .themed.themed--default & {
    .section {
      &-header {
        color: map_get($colors, "color-black");
      }

      &-subheader {
        color: map_get($colors, "color-black");
      }
    }
  }
}

/* Shop Bubbles End */

/* Top Links Start */
.top-links {
  padding: 34px 0;

  @media (max-width: 767px) {
    padding: 23px 0 14px;
  }

  &__inner {
    text-align: center;
  }

  &__btn {
    display: inline-block;
    margin: 0 3px;
    padding: 6px 24px;
    font-size: 14px;
    text-transform: uppercase;
    background: transparent;

    @media (max-width: 767px) {
      width: 223px;
      margin: 0 auto 10px;
    }
  }
}

/* Top Links End */

/* Hot Offers Start */
.hot-offers {
  padding: 20px 0 77px;

  @media (max-width: 767px) {
    padding-top: 25px;
  }

  &__header {
    text-align: center;
  }

  &__offers {
    padding-top: 43px;

    @media (max-width: 767px) {
      padding-top: 40px;
    }

    .offer__img {
      background-position: center center;
      background-size: cover;
    }
  }

  @media (min-width: 1024px) {
    div[class^="col-md-"] {
      &:nth-child(1n) {
        padding-right: 5px;
      }

      &:nth-child(2n) {
        padding-left: 5px;
      }
    }
  }
}

.offer {
  position: relative;
  height: 332px;
  margin-bottom: 10px;
  cursor: pointer;

  @media (max-width: 767px) {
    height: 299px;
  }

  &__img {
    position: absolute;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: contain;
  }

  &__inner {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    padding: 20px;

    @media (max-width: 768px) {
      padding: 10px;
    }
  }

  &__head,
  &__body {
    display: flex;
    justify-content: space-between;
  }

  &__body {
    flex-direction: column;
    align-items: flex-start;
    align-self: flex-start;
  }

  &__vendor {
    max-width: 110px;
    max-height: 110px;

    &--transparent {
      background: rgba(255, 255, 255, 0.6);
    }
  }

  &__discount {
    position: relative;
    width: 102px;
    height: 28px;
    font-size: 22px;
    line-height: 27px;
    text-align: center;
  }

  &__discount-tag {
    position: absolute;
    left: 0;
  }

  &__discount-value {
    position: relative;
  }

  &__information {
    max-width: 60%;

    @media (max-width: 767px) {
      max-width: 80%;
    }
  }

  &__backing {
    background: rgba(246, 246, 246, 0.85);
    box-shadow: -3px 0 0 rgba(246, 246, 246, 0.85);
  }

  &__name {
    font-weight: 700;
  }

  &__description {
    font-size: 12px;
    line-height: 1.5;
  }

  &__cta.btn {
    margin: 16px 0 0 -4px;
    padding: 6px 30px;
  }

  /* modifiers start */
  &--left-align {
    .offer {
      &__img {
        background-position: 100% 70%;
      }

      &__body {
        @media (max-width: 768px) {
          flex-direction: row;
          align-items: flex-end;
        }
      }
    }
  }

  &--no-bg {
    background: none;
  }

  &--small {
    .offer {
      &__img {
        background-position: right center;
      }

      &__information {
        max-width: 80%;
      }
    }
  }

  &--right-align {
    .offer {
      &__img {
        background-position: bottom left;
      }

      &__body {
        align-items: flex-end;
        align-self: flex-end;

        @media (max-width: 768px) {
          flex-direction: row;
          align-items: flex-end;
        }
      }
    }
  }

  &--full-bg {
    .offer {
      &__img {
        background-size: cover;
      }
    }
  }

  /* Modifiers stop */
}

/* Hot Offers End */

/* Top Similar Shops Start */
.top-similar-shops {
  margin: 30px 0;
  padding-bottom: 20px;

  @media #{$sm} {
    li {
      line-height: 44px;
    }
  }
}

/* Top Similar Shops End */

/* Subpage Teaser Start */
.subpage-teaser {
  margin: 25px 0;
  background: map_get($colors, "color-font");

  @media (max-width: 767px) {
    padding: 0 0 28px;
  }

  &__header {
    position: relative;
    padding: 20px 15px 0;
    text-align: left;
  }

  &__label {
    position: absolute;
    top: 0;
    right: 159px;

    @media (max-width: 1199px) {
      right: 10px;
    }

    @media (max-width: 767px) {
      right: 0;
      max-height: 88px;
    }
  }

  &__wrapper {
    padding-top: 90px;

    &::after {
      display: table;
      clear: both;
      content: "";
    }
  }

  &__item {
    display: block;
    width: 100%;
    max-width: 100%;
    margin: 0 auto 23px;

    &:hover {
      text-decoration: none;
    }
  }

  &__picture {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    height: 233px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  &__overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(58, 58, 58, 0.8);
  }

  &__icon {
    position: absolute;
    top: 50%;
    left: 50%;
    width: auto;
    height: auto;
    max-height: 100px;
    transform: translate(-50%, -50%);
  }

  &__text {
    padding: 10px 0;
    font-weight: 300;
    font-size: 20px;
    text-align: center;
  }
}

/* Subpage Teaser End */

/* Deals Start */
.deals {
  padding: 71px 0 43px;

  @media (max-width: 767px) {
    padding: 15px 0;
  }

  &__header {
    text-align: center;
  }

  &__wrapper {
    padding-top: 41px;
  }
}

/* Deals End */

/* Premium Offers widget */

.premium-offers__offers {
  margin: 41px -15px;

  @media (max-width: 768px) {
    margin: 21px 0;
  }
}

.premium-offer {
  $this: &;

  height: 350px;
  margin-bottom: 30px;
  background-color: #FFF;
  background-image: var(--bg-image-desktop);
  background-repeat: no-repeat;
  background-position: right center;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.15);
  cursor: pointer;

  @media #{$sm} {
    background-image: var(--bg-image-mobile);
  }

  &__link-wrp {
    color: black;
    text-decoration: none;

    &:hover {
      color: black;
      text-decoration: none;
    }
  }

  &__body {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 63%;
    height: 100%;
    padding: 12px 28px;

    @media (max-width: 1199px) {
      padding: 12px 18px;
    }

    @media (max-width: 767px) {
      width: 85%;
    }
  }

  &__category {
    position: relative;
    display: inline-block;
    padding-left: 20px;
    font-weight: 700;
    font-size: 12px;
    text-transform: uppercase;

    &::before {
      position: absolute;
      top: 4px;
      left: 0;
      display: inline-block;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      content: "";
    }
  }

  &__description {
    max-height: 155px;
    margin-top: 45px;
    overflow: hidden;
    font-weight: 300;
    font-size: 14px;
    line-height: 19px;
  }

  &__footer {
    margin-top: auto;
  }

  &__hr {
    margin-top: 17px;
    margin-bottom: 0;
    border: 0;
    border-top: 1px solid #CACACA;
  }

  &__cta {
    display: inline-block;
    margin-top: 10px;
    padding: 7px 13px;
    border: 1px solid;
    border-radius: 3px;
  }

  &__see-details {
    display: inline-block;
    margin-top: 15px;
    font-size: 12px;

    svg {
      margin-left: 3px;
      vertical-align: middle;
    }
  }

  /* Premium offer types Start */
  &--25 {
    background: #FFF !important;

    .offer__discount {
      position: absolute;
      top: 15px;
      right: 32px;
    }

    .premium-offer {
      &__body {
        width: 100%;
        padding: 12px 20px;
      }

      &__image-wrp {
        height: 210px;
        margin-top: 34px;
        text-align: center;
      }

      &__image {
        max-width: 100%;
        height: auto;
        max-height: 210px;
      }

      &__headline {
        display: -webkit-box;
        max-height: 34px;
        margin-top: 3px;
        overflow: hidden;
        font-weight: 700;
        font-size: 14px;
        line-height: 17px;
        text-overflow: ellipsis;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }

      &__description {
        margin-top: 0;
      }

      &__see-details {
        margin: 0 0 0 7px;
      }
    }
  }

  &--25-2019 {
    overflow: hidden;

    &.premium-offer {
      position: relative;
      height: 150px;
      background: #FFF;
    }

    .offer__discount {
      position: absolute;
      top: 0;
      left: 0;
      width: auto;
      height: 27px;
      padding: 0 15px 0 10px;
      background-color: #D73A00;
      border-bottom-right-radius: 40px;

      svg {
        display: none;
      }
    }

    .premium-offer {
      &__body {
        justify-content: center;
        width: 100%;
        padding: 12px;
      }

      &__image-wrp {
        position: absolute;
        top: -20px;
        right: -82px;
        width: 209px;
        height: 201px;
        overflow: hidden;
        border-radius: 85px 100px;
        transform: rotate(27deg);
      }

      &__image {
        transform: rotate(-27deg);
      }

      &__shop-logo {
        max-width: 80px;
        max-height: 80px;
      }
    }
  }

  &--50-small {
    height: 120px;
    color: #FFF;

    .premium-offer {
      &__headline {
        margin-top: 13px;
        font-size: 20px;
        text-shadow: none;
      }

      &__description {
        max-height: 57px;
        margin-top: 0;
        text-shadow: none;
      }

      &__body {
        width: 50%;

        @media (max-width: 767px) {
          width: 85%;
        }
      }
    }
  }

  &--50 {
    .premium-offer {
      &__headline {
        font-weight: bold;
      }
    }
  }

  &--100 {
    height: 350px;
    overflow: hidden;
    background-position: left center;
    background-size: 50%;

    @media (max-width: 1199px) {
      background-size: auto 100%;
    }

    @media (max-width: 767px) {
      height: auto;
      padding-top: 300px;
      background-position: top center;
      background-size: 100% auto;
    }

    .premium-offer {
      &__body {
        float: right;
        width: 50%;
        padding: 15px 32px;

        @media (max-width: 1199px) {
          padding: 12px 18px;
          background: #FFF;
        }

        @media (max-width: 767px) {
          width: 100%;
        }
      }

      &__headline {
        margin-top: 55px;

        @media (max-width: 992px) {
          margin-top: 10px;
        }
      }

      &__description {
        margin-top: 15px;
      }
    }

    .offer__discount {
      position: absolute;
      top: 20px;
      left: 33px;
    }
  }

  &--100 {
    @media (max-width: 767px) {
      padding-top: 200px;
      background-size: auto;
    }

    @media (max-width: 575px) {
      padding-top: 165px;
      background-size: 100%;
    }

    .premium-offer {
      &--100-img {
        display: none;
      }

      &__description {
        display: -webkit-box;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 5;
      }
    }
  }

  &--25-full-bg {
    color: #FFF;
    text-align: center;
    background-size: cover;

    .premium-offer {
      &__body {
        justify-content: center;
        width: 100%;

        @media (max-width: 767px) {
          padding: 25px 18px;
        }
      }

      &__headline {
        margin-top: 0;
        font-size: 30px;
        line-height: 1.1;
        text-shadow: none;

        @media (max-width: 992px) {
          margin-top: 25px;
        }

        @media (max-width: 767px) {
          margin-top: 0;
        }
      }

      &__description {
        margin-top: 9px;
        font-weight: 400;
        text-shadow: none;
      }
    }
  }

  &--33-colored-full-bg {
    .premium-offer {
      &__image-wrp {
        display: none;
      }
    }
  }

  /* Modifiers Start */

  &--bg-left {
    &.premium-offer {
      text-align: right;
      background-position: left center;

      &.premium-offer--50 {
        @media (max-width: 767px) {
          background-position: calc(0% - 100px) center;
        }
      }

      &.premium-offer--100 {
        text-align: right;

        @media (max-width: 767px) {
          background-position: top;

          .premium-offer__category {
            align-self: flex-start;
          }
        }
      }
    }

    .premium-offer__body {
      float: right;
    }

    .premium-offer__category {
      align-self: flex-end;
    }
  }

  &--bg-right {
    &.premium-offer--100 {
      background-position: right center;

      @media (max-width: 767px) {
        background-position: top;
      }

      .offer__discount {
        right: 33px;
        left: auto;
      }

      .premium-offer__body {
        float: left;
      }
    }

    @media (max-width: 767px) {
      &.premium-offer--50 {
        background-position: calc(100% + 100px) center;
      }
    }
  }

  &--opacity {
    &-white,
    &-black {
      position: relative;

      &::before {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 100%;
        background: linear-gradient(rgba(0, 0, 0, 0.48), rgba(0, 0, 0, 0.48));
        content: "";
      }

      .premium-offer__body {
        position: relative;
      }
    }

    &-black {
      color: #FFF;

      #{$this}__headline {
        text-shadow: 1px 1px 1px #000;
      }
    }

    &-white {
      color: #000;

      &::before {
        background: linear-gradient(rgba(255, 255, 255, 0.48), rgba(255, 255, 255, 0.48));
      }
    }
  }

  /* Modifiers End */

  /* Premium offer types End */
}

/* Premium Offers widget */

/* Discount bubbles Start */

.discount-bubbles {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 55px 0 50px;

  @media (max-width: 1199px) {
    width: 1000px;
    margin: 0 auto;
  }

  &__wrapper {
    max-width: 1170px;
    margin: 0 auto;

    @media (max-width: 992px) {
      margin: 0 -15px;
      overflow-x: scroll;
    }
  }
}

.discount-bubble {
  $size: 190px;
  $padding: 7px;

  position: relative;
  width: $size;
  height: $size;
  margin: 5px;
  padding-top: 43px;
  text-align: center;
  background: linear-gradient(36.75deg, #CD042C 14.65%, #FF4D70 77.35%);
  border-radius: 50%;
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.2);

  &::before {
    position: absolute;
    top: $padding;
    left: $padding;
    display: block;
    box-sizing: border-box;
    width: $size - 2 * $padding;
    height: $size - 2 * $padding;
    border: 2px solid white;
    border-radius: 50%;
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.15), inset 2px 2px 2px rgba(0, 0, 0, 0.15);
    content: "";
  }

  &__value {
    display: inline-block;
    width: 63%;
    padding-bottom: 29px;
    color: #FFF;
    font-weight: 600;
    font-size: 56px;
    line-height: 0.7;
    white-space: nowrap;
    text-shadow: 1px 0 1px rgba(105, 81, 85, 0.5);
    word-spacing: -5px;
    border-bottom: 1px solid #C01837;
    box-shadow: 0 1px 0 rgba(255, 255, 255, 0.2);
  }

  &__percent {
    font-size: 28px;
    vertical-align: top;
  }

  &__logo {
    display: block;
    width: auto;
    max-width: 80px;
    height: auto;
    max-height: 36px;
    margin: 13px auto 0;
    filter: brightness(0) invert(1);
  }
}

/* Discount bubbles Stop */

/* Fallback styles Start */
.home,
.sem {
  .premium-offers {
    background-color: transparent;

    .row {
      width: auto;
      margin: 0;
      padding: 0;
    }
  }
}

/* Fallback styles End */
