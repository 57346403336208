.featured-coupons.list-coupons-as-items {
  display: flex;
  flex-flow: row wrap;
  align-content: space-between;
  align-items: stretch;
  justify-content: space-between;
  margin-bottom: 0;

  .cta-arrow {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 15%;
    min-height: 110px;
    padding-right: 15px;
    font-size: 28px;
    text-align: right;
  }

  &.last-chance-widget {
    li.coupon-item {
      a {
        .content {
          span.hours,
          span.minutes,
          span.seconds {
            display: inline-block;
            margin-bottom: 5px;
            font-weight: bold;
          }
        }
      }
    }
  }

  li.coupon-item {
    width: 100%;
    height: auto;

    a {
      display: block;
      width: 100%;
      max-width: 100%;
      min-height: 92px;

      span {
        vertical-align: middle;
      }

      .content {
        &-desc {
          .coupon__exclusive {
            display: none;
          }
        }

        span.hours,
        span.minutes,
        span.seconds {
          display: inline-block;
          margin-bottom: 5px;
          font-weight: bold;
        }
      }
    }

    .coupon-hero {
      display: none;
    }

    &--big {
      height: auto;

      &.coupon-ads {
        background-color: transparent;
        border: none;
        box-shadow: none;

        img {
          width: 100%;
          height: 230px;
          object-fit: contain;
        }
      }

      img {
        object-position: center;

        &.coupon-hero {
          display: block;
          width: 100%;
          height: 122px;
          background-color: $color-bg-hero;
          background-size: cover;
        }
      }
    }
  }
}

@media (min-width: 768px) {
  .featured-coupons.list-coupons-as-items {
    li.coupon-item {
      flex-basis: auto;
      width: 49.5%;
      margin-bottom: 10px;
    }

    &.hide-first-featured {
      li:first-child {
        display: none;
      }
    }

    &.hide-second-featured {
      li:nth-child(2) {
        display: none;
      }
    }

    &.hide-third-featured {
      li:nth-child(3) {
        display: none;
      }
    }

    &.hide-fourth-featured {
      li:nth-child(4) {
        display: none;
      }
    }

    &.hide-fifth-featured {
      li:nth-child(5) {
        display: none;
      }
    }

    &.hide-last-featured {
      li:last-child {
        display: none;
      }
    }
  }
}

@media (min-width: 992px) {
  .featured-coupons.list-coupons-as-items {
    &.hide-first-featured,
    &.hide-second-featured,
    &.hide-third-featured,
    &.hide-fourth-featured,
    &.hide-fifth-featured,
    &.hide-last-featured {
      li {
        display: block !important;
      }
    }

    li.coupon-item {
      display: flex !important;
      flex-flow: column;
      width: 32.8%;
    }
  }
}

img.coupon-hero + span.content {
  width: 100%;
  max-width: 100% !important;

  @media (max-width: 767px) {
    max-width: calc(100% - 19px) !important;
  }
}

@media (max-width: 767px) {
  .featured-coupons.list-coupons-as-items {
    flex-flow: column wrap;

    li.coupon-item {
      flex-direction: column;
      margin-bottom: 10px;

      &--big img.coupon-hero {
        max-width: 100%;
        height: auto;
      }
    }

    .cta-arrow {
      min-height: 90px;
    }
  }
}

.featured-coupons {
  &__author {
    display: inline-block;
    padding: $featured-coupons-author__padding;
    color: $featured-coupons-author__color;
    font-weight: $featured-coupons-author__font-weight;
    font-size: $featured-coupons-author__font-size;
    line-height: $featured-coupons-author__line-height;
    text-decoration: $featured-coupons-author__text-decoration;

    &:hover {
      color: $featured-coupons-author__color--hover;
      text-decoration: $featured-coupons-author__text-decoration--hover;
    }
  }
}
