.pannacotta {
  &.card-pages-show {
    .card-content {
      padding: 0 25px 25px;
      color: $color-text;
      font-weight: lighter;
      font-size: 14px;
      line-height: 30px;

      a {
        @include flat-anchor($color-sky);
      }

      b,
      strong {
        font-weight: bold;
      }

      h1,
      h2,
      .h1,
      .h2 {
        font-weight: bold;
        font-size: 18px;
      }

      h3,
      .h3 {
        margin-top: 30px;
        font-weight: bold;
        font-size: 16px;
      }

      h4,
      h5,
      h6,
      .h4,
      .h5,
      .h6 {
        font-weight: lighter;
        font-size: 16px;
      }

      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      .h1,
      .h2,
      .h3,
      .h4,
      .h5,
      .h6 {
        color: $color-headline;
        font-weight: bold;

        b,
        strong {
          font-weight: lighter;
        }
      }

      br + br {
        display: block;
      }

      h1 + h2,
      h2 + h3,
      h3 + h4,
      h4 + h5,
      h5 + h6,
      .h1 + .h2,
      .h2 + .h3,
      .h3 + .h4,
      .h4 + .h5,
      .h5 + .h6 {
        margin-top: 0;
        color: $color-text;
        font-weight: bold;
      }

      hr {
        border-top-color: $color-cloud;
      }

      li {
        font-weight: lighter;
        font-size: 14px;
      }

      p {
        font-weight: lighter;
        font-size: 14px;
        line-height: 30px;
      }

      ul {
        padding-left: 0;
        list-style: none;

        li a {
          @include flat-anchor($color-sky);
        }
      }
    }

    .seo-text-container {
      .card-header {
        .card-title {
          padding: 12px 30px 12px 20px;
          font-size: 16px;

          h2 {
            font-size: 19px;
          }
        }
      }

      .card-content {
        padding: 10px 20px;

        p {
          line-height: 26px;
        }
      }
    }
  }
}
