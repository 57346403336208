.searches.index {
  .pannacotta.card-shop-header .page-subtitle {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

@media (min-width: 768px) {
  #custom-search-header-results,
  #search-header-results {
    overflow: hidden;

    ul.shop-autocomplete {
      position: absolute;
      right: 15px;
      left: 15px;
      z-index: 999;
      width: 376px;
      margin: 0 auto;
      padding: 0;
      text-align: left;
      background-color: rgba(255, 255, 255, 0.97);
      border: 1px solid $color-border;
      border-top: none;

      li {
        div.clear {
          clear: both;
        }

        > a {
          display: block;
          padding: 20px 16px;
          color: $color-text;
          font-weight: lighter;
          font-size: 14px;
          transition: background-color 0.3s ease;

          &:focus,
          &:hover {
            color: $color-ready;
            text-decoration: none;
            background-color: $color-header-bg;

            span {
              color: $color-ready;
            }

            small {
              color: $color-headline;
            }
          }

          b,
          strong {
            font-weight: bold;
          }

          img {
            width: 60px;
            height: 60px;
            margin-right: 12px;
            border: 1px solid $color-border;
          }

          small {
            color: $color-inactive;
            font-weight: lighter;
            font-size: 12px;
          }
        }

        > hr {
          margin: 0;
        }

        &.active > a {
          text-decoration: none;
          background-color: $color-header-bg;
        }

        &.result-shop {
          a {
            display: block;
            float: left;
            width: 100%;
          }

          .result-media {
            float: left;

            img {
              width: 60px;
              height: 60px;
              border: 1px solid $color-border;
            }
          }

          .result-body {
            float: left;
            overflow: hidden;
            color: $color-text;
            font-size: 18px;
            white-space: nowrap;
            text-overflow: ellipsis;

            .first-line {
              display: block;
              padding-top: 0;
              line-height: 36px;

              &:only-child {
                line-height: 60px;
              }
            }

            .second-line {
              display: block;
              font-weight: lighter;
              line-height: 20px;
            }
          }
        }

        &.result-coupon a {
          padding-right: 4px;
          padding-left: 4px;
          overflow: hidden;
          line-height: 24px;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        &.search-result-shop {
          border-bottom: 1px solid $color-border;

          table {
            max-width: 100%;
          }

          td {
            max-width: 25%;
            padding: 10px;
            text-align: left;
            vertical-align: top;

            a {
              color: $color-text;
              text-align: center;

              img {
                display: inline-block;
                margin-bottom: 5px;
              }

              &:hover,
              &:active {
                text-decoration: none;
              }
            }
          }
        }
      }
    }
  }
}

@media (min-width: 992px) {
  #search-header-results ul.shop-autocomplete {
    width: 487px;
  }
}

@media (min-width: 1200px) {
  #search-header-results ul.shop-autocomplete {
    width: 586px;
  }
}
