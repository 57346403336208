.climate-banner {
  $this: &;

  margin-right: 0;
  margin-left: 0;
  background-color: rgba(68, 151, 132, 0.05);
  border-color: #449784;
  border-style: solid;
  border-width: 1px 0;

  &__link {
    &:hover {
      text-decoration: none;
    }
  }

  &__wrapper {
    display: grid;
    grid-template-areas: "logo heading description button";
    grid-template-columns: 1fr 3fr 7fr 155px;
    align-items: center;
    min-height: 34px;
    padding: 0 15px;
    font-size: 13px;
    line-height: 15px;

    @media (max-width: 767px) {
      display: block;
      padding: 0;
      text-align: center;
    }

    img {
      grid-area: logo;
      width: 35px;
      height: auto;
      margin: 4px auto;

      @media (max-width: 767px) {
        display: inline-block;
        margin: 4px 0;
      }
    }

    #{$this}__heading {
      display: inline-block;
      grid-area: heading;
      padding: 0 10px;
      color: #000;
      font-weight: 500;

      @media (max-width: 767px) {
        text-align: left;
        text-decoration: underline;
      }
    }

    #{$this}__body {
      display: inline-block;
      grid-area: description;
      padding: 0 10px;
      color: #555;
      font-weight: 300;

      @media (max-width: 767px) {
        display: none;
      }
    }

    #{$this}__cta {
      grid-area: button;
      padding: 10px 11px 8px;
      color: #FFF;
      font-weight: 600;
      font-size: 10px;
      line-height: 13px;
      text-align: center;
      background-color: #449784;
      border-radius: 100px;

      @media (max-width: 767px) {
        display: none;
      }
    }
  }
}

.card-shop-header .climate-banner {
  grid-area: climate-banner;

  @media (min-width: 992px) {
    display: none;
  }

  &__wrapper {
    @media (max-width: 991px) {
      display: block;
      padding: 0;
      text-align: center;

      img {
        display: inline-block;
        margin: 4px 0;
      }
    }
  }

  &__heading {
    @media (max-width: 991px) {
      text-align: left;
      text-decoration: underline;
    }
  }

  &__body {
    @media (max-width: 991px) {
      display: none;
    }
  }
}

.additional-info .climate-banner {
  float: left;
  width: 100%;
  background-color: transparent;
  border: none;

  @media (max-width: 991px) {
    display: none;
  }

  &__wrapper {
    grid-template-areas: "heading logo" "description logo";
    grid-template-columns: 5fr 3fr;
    background-color: rgba(68, 151, 132, 0.05);
    border: 1px solid #449784;
    border-radius: 7px;

    img {
      width: 50px;
      margin: 10px auto;
    }
  }

  &__heading {
    margin: 15px 0 6px;
    padding: 0;
    font-size: 12px;
    line-height: 15px;
  }

  &__body {
    margin: 0 0 15px;
    padding: 0;
    font-size: 10px;
    line-height: 12px;
  }
}
