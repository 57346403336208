.authors-page {
  &__container {
    @media #{$min-sm} {
      margin-bottom: 33px;
      padding: 0;
    }
  }

  &__row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: $authors-page-row__margin;

    @media #{$sm} {
      margin: $authors-page-row__margin--mobile;
    }
  }

  &__hero {
    height: auto;
    min-height: $authors-page-hero__min-height;
    background-color: $authors-page-hero__bg;

    @media #{$sm} {
      min-height: $authors-page-hero__min-height--mobile;
    }
  }

  &__sector {
    position: relative;
    padding: $authors-page-sector__padding;

    @media #{$sm} {
      width: 100%;
      margin: $authors-page-sector__margin--mobile;
      padding: $authors-page-sector__padding--mobile;
    }
  }

  &__content {
    width: 100%;
    margin: $authors-page-content__margin;
    padding: $authors-page-content__padding;
    border: $authors-page-content__border;
    border-radius: 10px;

    @media #{$sm} {
      margin: $authors-page-content__margin--mobile;
    }

    p {
      margin: $authors-page-content-p__margin;
      color: $authors-page-content-p__color;
      font-size: $authors-page-content-p__font-size;
      font-family: $authors-page-content-p__font-family;
      line-height: $authors-page-content-p__line-height;

      @media #{$sm} {
        font-size: $authors-page-content-p__font-size--mobile;
        line-height: $authors-page-content-p__line-height--mobile;
      }
    }
  }
}

.authors-hero {
  &__container {
    min-height: $authors-hero-container__min-height;
    padding: $authors-hero-container__padding;

    @media #{$sm} {
      min-height: $authors-hero-container__min-height--mobile;
      padding: $authors-hero-container__padding--mobile;
    }
  }

  &__title {
    margin: $authors-hero-title__margin;
    color: $authors-hero-title__color;
    font-size: $authors-hero-title__font-size;
    line-height: $authors-hero-title__line-height;
    letter-spacing: -0.5px;
    text-align: $authors-hero-title__text-align;

    @media #{$sm} {
      font-size: $authors-hero-title__font-size--mobile;
      line-height: $authors-hero-title__line-height--mobile;
      text-align: $authors-hero-title__text-align--mobile;
    }
  }

  &__subtitle {
    margin: $authors-hero-subtitle__margin;
    color: $authors-hero-subtitle__color;
    font-weight: 400;
    font-size: $authors-hero-subtitle__font-size;
    line-height: $authors-hero-subtitle__line-height;
    letter-spacing: -0.5px;
    text-align: $authors-hero-subtitle__text-align;

    @media #{$sm} {
      font-size: $authors-hero-subtitle__font-size--mobile;
      line-height: $authors-hero-subtitle__line-height--mobile;
      text-align: $authors-hero-subtitle__text-align--mobile;
    }
  }

  &__description {
    color: $authors-hero-description__color;
    font-size: $authors-hero-description__font-size;
    line-height: $authors-hero-description__line-height;
    letter-spacing: -0.5px;

    @media #{$sm} {
      display: none;
    }
  }
}

.authors-sector {
  &__author {
    display: grid;
    justify-items: center;
    width: $authors-sector-author__width;
    min-height: $authors-sector-author__height;
    border: $authors-sector-author__border;
    border-radius: 10px;

    @media #{$sm} {
      width: 100%;
      min-height: $authors-sector-author__height--mobile;
    }

    &:hover {
      text-decoration: none;
      border: $authors-sector-author__border--hover;
      box-shadow: $authors-sector-author__box-shadow--hover;

      .authors-sector__image-wrapper {
        outline-color: $author-image__outline-color--hover;
      }

      .author-info__name {
        color: $author-info-name__color--hover;
        text-decoration: underline;
        text-decoration-thickness: 1px;
      }
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    padding: $author-info__padding;
    text-align: center;
  }

  &__image-wrapper {
    position: absolute;
    top: $author-image__top;
    width: $author-image__width;
    height: $author-image__height;
    border-radius: $author-image__border-radius;
    outline: 1px solid $author-image__outline-color;
  }

  &__image {
    overflow: hidden;
    border-radius: $author-image__border-radius;
  }
}

.author-info {
  &__name {
    margin: $author-info-name__margin;
    color: $author-info-name__color;
    font-weight: $author-info-name__font-weight;
    font-size: $author-info-name__font-size;
    line-height: $author-info-name__line-height;

    @media #{$sm} {
      margin: $author-info-name__margin--mobile;
      font-size: $author-info-name__font-size--mobile;
      line-height: $author-info-name__line-height--mobile;
    }
  }

  &__job {
    margin: $author-info-job__margin;
    color: $author-info-job__color;
    font-size: $author-info-job__font-size;
    line-height: $author-info-job__line-height;

    @media #{$sm} {
      font-size: $author-info-job__font-size--mobile;
      line-height: $author-info-job__line-height--mobile;
    }
  }

  &__description {
    position: relative;
    display: -webkit-box;
    margin-top: auto;
    overflow: hidden;
    color: $author-info-description__color;
    font-size: $author-info-description__font-size;
    font-family: $author-info-description__font-family;
    line-height: $author-info-description__line-height;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;

    &--full {
      display: block;
    }

    @media #{$sm} {
      font-size: $author-info-description__font-size--mobile;
      line-height: $author-info-description__line-height--mobile;
    }

    a {
      color: $author-info-description-link__color;
      text-decoration: underline;

      &:hover {
        color: $author-info-description-link__color--hover;
      }
    }
  }

  &__read-more {
    position: absolute;
    right: 0;
    bottom: 0;
    display: block;
    color: $author-info-read-more__color;
    background-color: $author-info-read-more__bg-color;
    cursor: pointer;

    &::before {
      text-decoration: none;
      content: "...";
    }
  }
}

.authors-content {
  &__title {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: $authors-content-title__padding;
    color: $authors-content-title__color;
    font-weight: $authors-content-title__font-weight;
    font-size: $authors-content-title__font-size;
    line-height: $authors-content-title__line-height;
    border-bottom: $authors-content-title__border;

    @media #{$sm} {
      font-size: $authors-content-title__font-size--mobile;
    }

    &::before {
      width: $authors-content-title--before__width;
      height: $authors-content-title--before__height;
      margin: $authors-content-title--before__margin;
      background-image: $authors-content-title--before__bg;
      background-size: cover;
      content: "";

      @media #{$sm} {
        width: $authors-content-title--before__width--mobile;
        height: $authors-content-title--before__height--mobile;
        margin: $authors-content-title--before__margin--mobile;
      }
    }
  }
}
