$themes: (
  "default": (
    "color-white": #FFF,
    "topbar-bg": #FFF,
    "topbar-font-color": #000,
    "header-bg-image": "",
    "color-primary": #F12850,
    "color-secondary": #323538,
    "color-bg": #F4F4F1,
    "color-light-bg": #E5E5E5,
    "color-font": #000,
    "color-1": #27AE60,
    "color-2": #F2994A,
    "color-3": #8A8A8A,
    "color-4": #151515,
    "color-5": #627A89,
    "color-6": #605E5E,
    "color-7": #F6F6F6,
    "color-8": #5C6469,
    "color-12": #5DBCFF,
    "telegraph-green": #285E6A,
    "independent-red": #D13030,
    "social-blue": #4395DA,
    "gold-premium": #C3851A,
    "yellow-paste": #EECA02,
    "pastel-purple": #8A44FF,
    "bubblegum-pink": #FD7AFF,
    "grey": #959595,
    "border-radius": 3px,
    "see-details-hover": #06C
  )
) !default;

@mixin colorize($theme, $vars) {
  .themed.themed--#{$theme} {
    /*  Common elements Start */

    .section {
      &-header {
        color: map_get($vars, "color-secondary");

        &--inverse {
          color: map_get($vars, "color-white");
        }
      }

      &-subheader {
        color: map_get($vars, "color-primary");
      }
    }

    /*  Common elements End */

    /* Header Start */
    .topbar {
      color: map_get($vars, "topbar-font-color");
      background: map_get($vars, "topbar-bg");
    }

    .nav.primary {
      & > li > a {
        color: map_get($vars, "topbar-font-color");

        &:hover {
          color: map_get($vars, "color-primary");
        }
      }
    }

    .navigation {
      .open > a {
        color: map_get($vars, "color-primary");
      }

      & > li {
        & > a {
          @if $theme == default {
            color: map_get($vars, "color-8");
          } @else {
            color: map_get($vars, "color-white");
          }

          &:hover {
            color: map_get($vars, "color-primary");
          }
        }
      }
    }

    .pannacotta.sub-menu {
      @if $theme == default {
        top: 93px;
      }
    }

    /* Header End */

    /* Hero Start */
    .hero {
      color: map_get($vars, "color-white");

      @if $theme == default {
        padding: 101px 0 120px;

        @media (max-width: 992px) {
          padding: 30px 0;
        }

        &__h1 {
          color: map_get($vars, "color-8");
          font-weight: 900;
          font-size: 62px;
          font-family: "Open Sans", "Helvetica Neue", Arial, Helvetica, sans-serif;
          line-height: 84px;
          text-transform: none;

          @media (max-width: 992px) {
            font-size: 50px;
            line-height: 1.5;
          }

          @media #{$sm} {
            font-size: 32px;
            line-height: 1.1;
          }
        }

        &__h2 {
          margin-top: 32px;
          color: map_get($vars, "color-primary");
          font-size: 26px;
          text-transform: none;

          @media #{$sm} {
            margin-top: 19px;
            font-size: 18px;
          }
        }

        &__cta {
          margin-top: 30px;
          padding: 8px 32px;
          color: map_get($vars, "color-white");
          font-size: 18px;
          background: map_get($vars, "color-primary");
        }
      }

      &__cta {
        color: map_get($vars, "color-white");
        background: map_get($vars, "color-primary");

        &:hover {
          background: darken(map_get($vars, "color-primary"), 5%);
        }
      }
    }

    /* Hero End */

    /* Top Links Start */

    .top-links {
      &__btn {
        border: 1px solid transparentize(map_get($vars, "topbar-bg"), 0.7);
        border-radius: map_get($vars, "border-radius");

        @if $theme == default {
          border: 1px solid transparentize(map_get($vars, "topbar-font-color"), 0.7);
        }

        &:hover {
          background: transparentize(map_get($vars, "topbar-bg"), 0.95);
        }
      }
    }

    /* Top Links End */

    /* Hot Offers Start */
    .offer {
      &--bg {
        background-color: map_get($vars, "color-7");
      }

      &__discount {
        color: map_get($vars, "color-white");
      }

      &--no-bg {
        border: 1px solid darken(map_get($vars, "color-bg"), 9%);
      }

      &__cta.btn {
        color: map_get($vars, "color-white");
        background: map_get($vars, "color-primary");
      }

      &--green-label {
        .offer {
          &__discount-tag #a {
            fill: map_get($vars, "color-1");
          }
        }
      }

      &--yellow-label {
        .offer {
          &__discount-tag #a {
            fill: map_get($vars, "color-2");
          }
        }
      }
    }

    /* Hot Offers End */

    /* Subpage Teaser Start */
    .subpage-teaser {
      background: map_get($vars, "topbar-bg");

      @if $theme == "default" {
        background: map_get($vars, "color-font");
      }

      &__text {
        color: map_get($vars, "color-4");
        background: map_get($vars, "color-white");
      }
    }

    /* Subpage Teaser End */

    /* Deals Start */
    .deals {
      background: map_get($vars, "color-light-bg");

      @if $theme == "default" {
        background: map_get($vars, "color-bg");
      }
    }

    /* Deals End */

    /* Premium Offers Start */
    .premium-offers {
      background-color: map_get($vars, "color-white");
    }

    .premium-offer {
      &__category {
        &--blue {
          color: map_get($vars, "color-2");

          &::before {
            background-color: map_get($vars, "color-2");
          }
        }

        &--green {
          color: map_get($vars, "color-1");

          &::before {
            background-color: map_get($vars, "color-1");
          }
        }

        &--purple {
          color: map_get($vars, "color-10");

          &::before {
            background-color: map_get($vars, "color-10");
          }
        }

        &--red {
          color: map_get($vars, "color-11");

          &::before {
            background-color: map_get($vars, "color-11");
          }
        }

        &--telegraph {
          color: map_get($vars, "telegraph-green");

          &::before {
            background-color: map_get($vars, "telegraph-green");
          }
        }

        &--independent {
          color: map_get($vars, "independent-red");

          &::before {
            background-color: map_get($vars, "independent-red");
          }
        }

        &--social {
          color: map_get($vars, "social-blue");

          &::before {
            background-color: map_get($vars, "social-blue");
          }
        }

        &--gold {
          color: map_get($vars, "gold-premium");

          &::before {
            background-color: map_get($vars, "gold-premium");
          }
        }

        &--yellow {
          color: map_get($vars, "yellow-paste");

          &::before {
            background-color: map_get($vars, "yellow-paste");
          }
        }

        &--bubblegum {
          color: map_get($vars, "bubblegum-pink");

          &::before {
            background-color: map_get($vars, "bubblegum-pink");
          }
        }

        &--pastel-purple {
          color: map_get($vars, "pastel-purple");

          &::before {
            background-color: map_get($vars, "pastel-purple");
          }
        }

        &--grey {
          color: map_get($vars, "grey");

          &::before {
            background-color: map_get($vars, "grey");
          }
        }
      }

      &__see-details {
        color: map_get($vars, "color-8");

        svg {
          fill: map_get($vars, "color-8");
        }

        &:hover {
          color: map_get($vars, "see-details-hover");
        }
      }

      &--orange-bg {
        background-color: map_get($vars, "color-2");
      }

      &--green-bg {
        background-color: map_get($vars, "color-1");
      }

      &--blue-bg {
        background-color: map_get($vars, "color-12");
      }

      &--telegraph-bg {
        background-color: map_get($vars, "telegraph-green");
      }

      &--independent-bg {
        background-color: map_get($vars, "independent-red");
      }

      &--social-blue-bg {
        background-color: map_get($vars, "social-blue");
      }

      &--gold-bg {
        background-color: map_get($vars, "gold-premium");
      }

      &--yellow-paste-bg {
        background-color: map_get($vars, "yellow-paste");
      }

      &--pastel-purple-bg {
        background-color: map_get($vars, "pastel-purple");
      }

      &--bubblegum-bg {
        background-color: map_get($vars, "bubblegum-pink");
      }

      &--grey-bg {
        background-color: map_get($vars, "grey");
      }

      @if $theme == "default" {
        @media #{$sm} {
          &--50.premium-offer--bg-right {
            .premium-offer__description {
              padding-right: 50px;
            }
          }

          &--50.premium-offer--bg-left {
            .premium-offer__description {
              padding-left: 50px;
            }
          }
        }
      }
    }

    /* Premium Offers End */

    /* Coupon Start */
    .item .padding {
      background: map_get($vars, "color-bg");
      box-shadow: inset 0 0 0 1px darken(map_get($vars, "color-bg"), 10%);
    }

    .coupon {
      &-body {
        .divider {
          li {
            background-color: map_get($vars, "color-light-bg");
          }
        }

        .coupon-description__toggle {
          color: $color-url;
        }

        &::before,
        &::after {
          background-color: map_get($vars, "color-light-bg");
        }
      }

      button {
        color: map_get($vars, "color-primary");

        // background: map_get($vars, 'color-bg');
      }

      &-additional-info {
        color: map_get($vars, "color-primary");
      }
    }

    .clickout {
      .o-button {
        color: map_get($vars, "color-white");
        background: map_get($vars, "color-ready");
      }
    }

    .pannacotta {
      .btn-ready {
        background-color: map_get($vars, "color-primary");
      }
    }

    .show-more {
      margin-bottom: 10px;
      color: map_get($vars, "color-5");
      border-color: map_get($vars, "color-5");
    }

    /* Coupon End */
  }
}

@mixin images($theme, $vars) {
  .themed.themed--#{$theme} {
    .themed__header {
      background-image: map_get($vars, "header-bg-image");
    }
  }
}

@each $theme, $vars in $themes {
  @include colorize($theme, $vars);
  @include images($theme, $vars);
}

.themed {
  background: #FFF;

  #accordion {
    margin-top: 50px;
  }

  /* Header Start */
  .topbar {
    font-size: 10px;

    @media (max-width: 768px) {
      text-align: center;
    }
  }

  &.custom {
    margin-top: -28px;

    .themed__header {
      padding-top: 30px;
      padding-bottom: 30px;
      background: none;

      #header_image {
        height: auto;
        min-height: 240px;
        margin-top: 0;
        padding-top: 50px;
      }
    }
  }

  .attach-newsletter-popup {
    display: block;
    padding: 3px 8px 6px 10px;
    font-size: 10px;
    cursor: pointer;
  }

  .roberto-paper-plane {
    margin-right: 4px;
    font-size: 16px;
  }

  .nav.primary {
    float: right;

    @media (max-width: 768px) {
      display: inline-block;
      float: none;
      line-height: 1;
    }

    & > li > a {
      padding: 8px 14px;
      border-radius: 0;
    }
  }

  .themed__navigation {
    @media (max-width: 768px) {
      padding: 21px 0 0 3px;
    }

    .sub-menu__categories {
      top: 95px;

      @media (max-width: 992px) {
        top: 72px;
      }

      a {
        font-size: 14px;
      }
    }

    .navbar-toggle {
      width: 100%;
      margin: 0;
      padding: 14px 0 0 !important;
      font-size: 24px;

      @media #{$sm} {
        text-align: right;
      }
    }
  }

  .themed__header {
    background-position: center center;
    background-size: cover;
  }

  .site-logo {
    margin: 20px 0;
    padding-right: 8px;
    text-align: right;

    @media (max-width: 768px) {
      margin: 9px 0 0;
      text-align: left;
    }

    a {
      display: inline-block;
      height: 44px;

      img {
        width: auto;
        max-height: 100%;
      }
    }
  }

  #main-menu {
    margin-right: -40px;
  }

  .navigation {
    white-space: nowrap;
    text-align: right;

    .open > a {
      text-decoration: underline;
      background: none;
    }

    & > li {
      display: inline-block;
      width: auto;
      padding: 28px 17px;

      & > a {
        font-weight: 700;

        &:hover {
          text-decoration: underline;
          background: none;
        }
      }
    }

    a {
      font-size: 16px;
    }

    & > li + li {
      padding-right: 0;
    }
  }

  /* Header End */

  /* Hero Start */
  .hero {
    padding-top: 19px;
    padding-bottom: 91px;
    text-align: center;
    background-repeat: no-repeat;
    background-position: right center;

    @media #{$lg} {
      padding-bottom: 103px;
    }

    @media (max-width: 768px) {
      padding-bottom: 29px;
    }

    &__h1 {
      margin-top: -11px;
      font-size: 62px;
      font-family: serif;
      text-transform: uppercase;

      @media #{$lg} {
        margin-top: -13px;
        font-size: 53px;
      }

      @media (max-width: 768px) {
        margin-top: -11px;
        font-size: 35px;
      }
    }

    &__h2 {
      font-weight: 300;
      font-size: 24px;
      text-transform: uppercase;

      @media #{$lg} {
        font-size: 21px;
      }

      @media (max-width: 768px) {
        margin-top: 17px;
        font-size: 14px;
      }
    }

    &__sale {
      margin-top: -34px;

      @media #{$lg} {
        max-height: 116px;
      }

      @media (max-width: 768px) {
        max-height: 76px;
        margin-top: -25px;
      }
    }

    #counter {
      margin-top: 30px;

      @media #{$lg} {
        margin-top: 0;
      }
    }

    .countdown {
      &--position {
        &-left {
          margin-right: auto;
        }

        &-right {
          margin-left: auto;
        }

        &-center {
          margin: 0 auto;
        }
      }

      &-time {
        font-weight: 700;
        font-size: 63px;
        line-height: 1;

        @media #{$lg} {
          font-size: 53px;
        }
      }

      &-labels {
        font-size: 12px;
        text-transform: uppercase;
        opacity: 0.5;
      }
    }

    &__cta {
      padding: 6px 30px;
      font-size: 21px;
    }
  }

  /* Hero end */

  /* Coupon Start */
  .item {
    position: relative;
    display: flex;
    flex: 0 1 49%;
    min-height: 160px;
    margin-right: 5px;
    margin-bottom: 6px;
    padding: 0;
    border-radius: 2px;

    @media (max-width: 992px) {
      flex: 0 1 100%;
      flex-direction: column;
    }
  }

  .coupon {
    &-header,
    &-body,
    &-footer {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0;
      padding: 0;
    }

    &-header,
    &-body {
      height: 160px;
    }

    &-header {
      flex: 0 27%;
      max-width: 27%;
      padding: 0 5px;

      @media #{$lg} {
        flex: 0 20%;
        max-width: 20%;
      }

      @media (max-width: 992px) {
        flex: 1 100%;
        min-width: 100%;
      }
    }

    &-body {
      position: relative;
      flex: 0 0 47%;
      flex-direction: column;
      max-width: 47%;
      padding-right: 4px;
      padding-left: 24px;

      @media #{$lg} {
        flex: 0 0 53%;
        max-width: 53%;
      }

      @media (max-width: 992px) {
        flex: 1 100%;
        min-width: 100%;
      }

      .divider {
        position: absolute;
        top: 0;
        left: -4px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: auto;
        height: 100%;
        padding: 18px 0;
        list-style-type: inherit;

        @media (max-width: 992px) {
          top: -15px;
          flex-direction: row;
          width: 100%;
          height: 8px;
          padding: 0 8px;
        }

        li {
          display: block;
          width: 8px;
          height: 8px;
          border-radius: 100%;
        }
      }

      .show-coupon-details {
        cursor: pointer;
      }

      &::before,
      &::after {
        position: absolute;
        left: -12px;
        display: block;
        width: 24px;
        height: 24px;
        border-radius: 100%;
        content: "";
      }

      &::before {
        top: -12px;

        @media (max-width: 992px) {
          top: -22px;
        }
      }

      &::after {
        bottom: -12px;

        @media (max-width: 992px) {
          top: -22px;
          right: -12px;
          left: auto;
        }
      }
    }

    &-footer {
      flex: 0 0 26%;
      flex-direction: column;
      max-width: 26%;
      padding-top: 40px;

      @media #{$lg} {
        flex: 0 0 30%;
        max-width: 30%;
      }

      @media (max-width: 992px) {
        flex: 1 100%;
        min-width: 100%;
      }
    }

    &-title {
      margin-top: 12px;
      font-weight: 600;
      font-size: 14px;
    }

    &-description {
      font-weight: 300;
      font-size: 12px;
    }

    &-info {
      margin: 15px 0;
      font-size: 12px;
      text-align: left;
    }

    &-additional-info {
      width: 100%;
      font-weight: 300;
      font-size: 12px;

      @media (max-width: 992px) {
        display: none;
      }
    }
  }

  .show-more {
    max-width: 175px;
    margin-top: 38px;
    font-size: 15px;
    text-transform: uppercase;
    border: 1px solid;
    border-radius: 4px;

    .fa {
      display: none;
    }
  }

  /* Coupon stop */

  /* Fallback Header Image */
  #header_image {
    margin-top: -28px;
    margin-bottom: 0;
  }

  #campaign-coupons {
    .padding {
      .coupon__description,
      .coupon__toggle {
        display: none;
      }
    }
  }

  /* Fallback modifiers Start */

  &--grid-fallback {
    .container {
      width: 100% !important;
      padding: 0;
      background: none;
    }

    .row {
      margin-right: 0;
      margin-left: 0;
    }

    .deals {
      background: transparent !important;
    }

    .col-lg-10.col-lg-offset-1 {
      width: 100%;
      margin-left: 0;
      padding: 0;
    }

    @media #{$sm} {
      .subpage-teaser__wrapper {
        padding-right: 30px;
        padding-left: 30px;
      }
    }

    .discount-bubble {
      $size: 170px;
      $padding: 5px;

      width: $size;
      height: $size;

      &::before {
        top: $padding;
        left: $padding;
        width: $size - 2 * $padding;
        height: $size - 2 * $padding;
      }

      &__value {
        padding-bottom: 24px;
        font-size: 44px;
      }
    }

    .sponsor-bar {
      padding: 10px 30px;
    }

    .premium-offers {
      &__offers {
        margin-right: 0;
        margin-left: 0;
      }
    }
  }

  /* Fallback modifiers Stop */
}

/* grid-fallback Start */
#header_image {
  &.hero {
    justify-content: center;
    padding: 19px 26px;
    text-align: center;

    &.themed--default #countdown {
      color: #F12850;
    }

    h1 {
      display: inline-block;
      margin-bottom: 7px;
      padding: 8px 15px 4px;
      line-height: 1;
    }

    h2 {
      margin-top: 0;
      padding: 4px 7px;
    }

    &--text-bg h1,
    &--text-bg h2 {
      background: rgba(53, 64, 67, 0.8);
    }

    &--align {
      &-top {
        align-items: flex-start;
      }

      &-middle {
        align-items: center;
      }

      &-bottom {
        align-items: flex-end;
      }

      &-center {
        justify-content: center;
      }

      &-left {
        justify-content: flex-start;
        text-align: left;
      }

      &-right {
        justify-content: flex-end;
        text-align: right;
      }
    }
  }
}

@media (max-width: 768px) {
  .themed.themed--grid-fallback .shop-bubbles {
    margin-right: -15px;
    margin-left: -15px;
  }
}

/* grid-fallback Stop */
