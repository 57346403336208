.pannacotta {
  &.card-empty-shop {
    .card-header .card-title {
      color: #FFF;
      font-size: 16px;
      text-indent: 0;
    }
  }
}

.w-newsletter {
  margin: 20px 0;
  padding: 24px 21px;
  text-align: center;
  background-color: $background--light-grey;
  border: 0.5px solid $border-color-newsletter;

  .form-inline {
    .privacy-note {
      &__text {
        color: $color-black;

        a {
          color: $color-black;
        }
      }

      .show-newsletter-details {
        color: $color-black;
      }
    }
  }
}

.pannacotta {
  &.card-empty-shop {
    .card-header .card-title {
      color: #FFF;
      font-size: 16px;
      text-indent: 0;
    }

    .card-content {
      .privacy-note {
        position: relative;
        display: inline-block;
        width: 100%;
        margin-top: 5px;
        margin-bottom: 0;

        small {
          font-size: 11px;

          a {
            color: $color-white;
            text-decoration: underline;
          }
        }
      }

      .btn {
        position: relative;
        top: 1px;
        font-weight: 400;
        text-transform: uppercase;
        border-radius: 0;
      }

      input[type="email"],
      input[type="text"] {
        min-width: 238px;
        height: 40px;
        padding: 8px 12px;
        color: $color-text;
        font-weight: 400;
        font-size: 14px;
        border-color: $color-white;
        box-shadow: none;

        &:focus {
          outline: none;
          box-shadow: none;
        }
      }

      .privacy-note {
        label {
          font-weight: 100;
          cursor: pointer;
        }
      }
    }
  }
}
