.related-links {
  &__title {
    font-weight: 700;
    text-align: center;
  }

  &__list {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
    list-style-type: none;
  }
}
