$font-color: $color-white;
$font-color--accent: #0098A7;
$query-to-switch-layout: $min-sm;
$earthmark-leaf: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='19' height='23' fill='none'%3E%3Cpath fill='%23BCC8C2' d='M.8 22.2C.8 9.4 5.3 2.6 18.8 0c.1 16-4 23.5-16.9 23 .9-2.6 9.8-14 10-14.3.2-.3-3.7 1.4-11.1 13.5'/%3E%3C/svg%3E");
$earthmark-leaf--active: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='19' height='23' fill='none'%3E%3Cpath fill='url(%23a)' d='M.8 22.2C.8 9.4 5.3 2.6 18.8 0c.1 16-4 23.5-16.9 23 .9-2.6 9.8-14 10-14.3.2-.3-3.7 1.4-11.1 13.5'/%3E%3Cdefs%3E%3ClinearGradient id='a' x1='18.8' x2='.2' y1='0' y2='21.5' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%236BAB8D'/%3E%3Cstop offset='1' stop-color='%235D939A'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E");
$content-width--mobile: 345px;

.coupons__earthmark-rating {
  display: block;
  margin-bottom: 10px;

  .earthmark-rating {
    width: 100%;
    padding: 15px 15px 10px;
    color: $font-color;
    background: linear-gradient(to right, #55B185, #44908E);

    @media #{$query-to-switch-layout} {
      padding: 27px 10px 18px 72px;
    }

    @media #{$min-md} {
      padding-left: 15px;
    }

    @media #{$min-lg} {
      padding-left: 72px;
    }

    &__header {
      position: relative;
      display: flex;
      align-items: center;
      min-width: $content-width--mobile;
      padding: 5px 10px;
      background-color: $color-white;
      border-radius: 30px;

      @media #{$query-to-switch-layout} {
        width: 423px;
        margin-right: 150px;
      }
    }

    &__heading {
      position: relative;
      display: flex;
      flex-basis: 50%;
      flex-direction: column;
      margin: 0;
      padding-left: 12px;
      color: $font-color--accent;
      font-size: 10px;
      line-height: 17px;
      letter-spacing: normal;

      @media #{$query-to-switch-layout} {
        flex-basis: 48%;
        padding-left: 17px;
        font-size: 14px;
      }

      &::after {
        $offset: 5px;

        position: absolute;
        top: $offset;
        right: 0;
        display: block;
        height: calc(100% - (#{$offset} * 2));
        border-left: 1px solid #CED6D3;
        content: "";
      }
    }

    &__score-name {
      margin-top: -3px;
      padding-bottom: 2px;
      font-weight: 600;
      font-size: 18px;
      text-transform: capitalize;

      @media #{$query-to-switch-layout} {
        margin-top: 2px;
        font-size: 22px;
      }
    }

    &__list {
      position: relative;
      display: flex;
      flex-direction: row-reverse;
      justify-content: flex-end;
      margin: 0 auto;
    }

    &__item {
      width: 18px;
      height: 23px;
      background: center / contain no-repeat;
      background-image: $earthmark_leaf;

      &:not(:last-of-type) {
        margin-left: 10px;
      }

      @media #{$query-to-switch-layout} {
        width: 23px;
        height: 29px;
      }

      &--active {
        &,
        & ~ li {
          background-image: $earthmark_leaf--active;
        }
      }
    }

    &__copyright {
      position: absolute;
      bottom: -25px;
      left: 22px;
      font-size: 12px;
      line-height: 17px;
      white-space: nowrap;
      text-transform: lowercase;

      @media #{$query-to-switch-layout} {
        bottom: unset;
        left: calc(100% + 14px);
        font-size: 21px;
        line-height: 1;
      }
    }

    &__brand {
      font-weight: 600;
    }

    &__details {
      min-width: $content-width--mobile;

      @media #{$query-to-switch-layout} {
        padding: 10px 10px 0 27px;
      }
    }

    &__summary {
      position: relative;
      margin: 8px 26px 0 0;
      font-size: 14px;
      font-family: $earthmark-rating__font-family-additional;
      line-height: 16px;
      text-align: right;
      text-decoration: underline;
      cursor: pointer;

      @media #{$query-to-switch-layout} {
        margin-bottom: 22px;
        font-size: 16px;
        text-align: left;
      }
    }

    &__details {
      summary::after {
        @include arrow;

        position: absolute;
        top: 5px;
        margin-left: 5px;
        content: "";
      }

      &[open] summary::after {
        top: 7px;
        transform: rotate(-135deg);
      }
    }

    &__description {
      position: relative;
      left: 0;
      display: block;
      margin-top: 22px;
      padding-bottom: 5px;
      font-size: 14px;
      line-height: 21px;

      @media #{$query-to-switch-layout} {
        margin-top: 0;
        padding-bottom: 0;
        font-size: 16px;
        line-height: 24px;
      }
    }

    &__link {
      display: inline-block;
      color: $font-color;
      text-decoration: underline;
      vertical-align: baseline;

      &::after {
        display: inline-block;
        width: 14px;
        height: 8px;
        margin-left: 4px;
        vertical-align: baseline;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='8' fill='none'%3E%3Cpath stroke='%23fff' stroke-linecap='round' d='M1 4h12m0 0-2.5-3M13 4l-2.5 3'/%3E%3C/svg%3E");
        background-repeat: no-repeat;
        content: "";
      }
    }
  }
}
