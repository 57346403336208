.card-list-coupon-type {
  .card-content {
    ul {
      margin-bottom: 0;

      li {
        margin-bottom: 0;
        line-height: 20px;

        &:last-child a {
          border-bottom: none;
        }

        &.active a {
          background-color: $color-header-bg;
        }

        a {
          display: block;
          padding: 25px;
          color: $color-title;
          font-weight: lighter;
          font-size: 16px;
          background-color: #FFF;
          border-bottom: 1px solid $color-cloud;
          transition: background-color 0.3s ease;

          &:focus,
          &:hover {
            text-decoration: none;
            background-color: $color-header-bg;
          }

          i {
            margin-right: 25px;
            font-size: 25px;
            vertical-align: -6px;
          }
        }
      }
    }
  }
}
