.pannacotta {
  .color-ashes {
    color: $color-ashes;
  }

  .color-berry {
    color: $color-berry;
  }

  .color-cloud {
    color: $color-cloud;
  }

  .color-cherry {
    color: $color-cherry;
  }

  .color-freedom {
    color: $color-freedom;
  }

  .color-ready {
    color: $color-ready;
  }

  .color-royal {
    color: $color-royal;
  }

  .color-sky {
    color: $color-sky;
  }
}
