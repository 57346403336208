.sponsor-bar {
  $height: 100px;
  $padding: 10px;

  height: $height;
  margin: 10px 0;
  padding: $padding 45px;
  background: #F2F2F2;

  @media #{$sm} {
    height: auto;
    padding: $padding 20px;
  }

  a {
    &:hover {
      color: initial;
    }
  }

  &__container {
    display: grid;
    grid-template: "logo title button" "logo desc button";
    grid-template-columns: 1fr 4fr 1fr;
    gap: 5px;
    align-items: center;
    height: 100%;
    color: #3D3D3D;

    @media #{$sm} {
      grid-template:
        "title title logo" auto
        "desc desc logo" auto
        "button button logo" auto / 1fr 1fr 1fr;
    }
  }

  &__logo {
    grid-area: logo;
    max-height: $height - 2 * $padding;

    @media #{$sm} {
      max-height: 70px;
      margin: 0 auto;
    }
  }

  &__title {
    grid-area: title;
    color: #000;
    font-weight: bold;
    font-size: 25px;
    line-height: 30px;

    @media #{$sm} {
      font-size: 16px;
      line-height: 19px;
    }
  }

  &__text {
    display: inline-block;
    grid-area: desc;
    width: 100%;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.05em;

    @media #{$sm} {
      font-size: 16px;
      line-height: 19px;
    }
  }

  &__btn {
    grid-area: button;
    font-size: 14px;
    line-height: 18px;
    background-color: $btn--coupon;
    border-radius: 0;

    @media #{$sm} {
      width: 80%;
      min-width: auto;
      padding: 5px;
      font-size: 14px;
      line-height: 17px;

      &.o-button,
      &.coupon__button {
        margin: 0;
      }
    }
  }
}
