.pannacotta {
  &.card-as-seen-on {
    margin-bottom: 0;
    border: none;
    border-radius: 0;

    .card-header .card-title {
      font-size: 24px;
    }

    .card-content {
      padding-bottom: 30px;

      img {
        margin: 0 auto;
      }
    }
  }
}
