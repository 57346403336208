ul.hover,
ol.hover {
  li.coupon-item {
    &:hover {
      a {
        text-decoration: none;
      }
    }
  }
}
